import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { Link } from "react-router-dom";
import { getMySubjectNameList } from "../../../Services/StudentApis";
import { useSearchParams } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
const MYStudentSubjectsList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [mySubjectNameList, setMySubjectNameList] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getMySubjectNameList();
                setMySubjectNameList(response?.data || []);
            } catch (error) {
                console.error("Error fetching subject list:", error);
            }
        };
        fetchData();
    }, [searchParams]);

    return (
        <section>
            <Headingtitle title="Selected Subjects " />
            <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[80vh]">
                <p>Click on subject to view details</p>
                <table className="min-w-full bg-white rounded-lg border-collapse">
                    <thead className="rounded-lg">
                        <tr className="bg-bg_blue-25 text-[1.1rem] text-left text-text_blue-500 rounded-t-lg">
                            <th className="p-4 border-b-2">No</th>
                            <th className="p-4 border-b-2">Subject Name</th>
                            <th className="p-4 border-b-2">Action</th>


                        </tr>
                    </thead>
                    <tbody className="border-b-[1px] border-border-50 text-text_Black-75 font-poppins">
                        {mySubjectNameList?.length > 0 ? (
                            mySubjectNameList.map((subject, index) => (
                                <tr key={subject._id} className="font-semibold text-text_Black-75">
                                    <td className="p-4 border-r border-l">{index + 1}</td>
                                    <td className="p-4 border-r">
                                        <Link to={`/student/dashboard/detail/subject/${subject._id}`}>
                                            <span className="bg-green-100 text-text_Black-75 text-center py-1 px-4 rounded-full inline-block">
                                                {subject.subjectName || "N/A"}
                                            </span>
                                        </Link>
                                    </td>
                                    <td className="px-5 text-[1rem] text-blue-500">
                                        <Link to={`/student/dashboard/detail/subject/${subject._id}`}>
                                            <FaRegEye >

                                            </FaRegEye>
                                        </Link>
                                    </td>

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="p-4 text-center text-gray-500">
                                    No subjects found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table> </div>
        </section>
    );
};

export default MYStudentSubjectsList;
