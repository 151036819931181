import React, { useState } from 'react'
import { myAttendanceSummary } from '../../../Services/StudentApis';
import AttendanceComponent from '../../Common/AttendanceComponent';
const AttendancePage = () => {
    return (
        
        <AttendanceComponent
            title="My Attendance"
            fetchAttendanceData={myAttendanceSummary}
            showStudentSelector={false}
        />
    )
}

export default AttendancePage
