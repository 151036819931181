import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from "react-hot-toast";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Headingtitle from '../../Common/Headingtitle';
import { GetOnboardedTeacherList, deleteATeacher } from '../../../Services/AdminApis';

import IconButton from '@mui/material/IconButton';
import EditTeacherModal from '../../../Layout/Models/EditTeacherModal';
import { width } from '@mui/system';



const OnboardedTeacherList = () => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedteacher, setSelectedteacher] = useState(null);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null)
  const [open, setOpen] = useState(false);

  const fetchTeachers = async () => {
    try {
      const response = await GetOnboardedTeacherList();
      const teacherData = response.data.teachers;

      const formattedRows = teacherData.map((teacher, index) => ({
        sNo: index + 1,
        id: teacher._id,
        firstName: teacher.name.firstName,
        lastName: teacher.name.lastName,
        middleName: teacher.name.middleName || '',
        phone: teacher.contactInfo?.phone || '',
        address: teacher.contactInfo?.address || '',
        specializations: [...teacher.specializations] || [],
        email: teacher.email || '',
        image: teacher.avatar || 'https://via.placeholder.com/150',
      }));

      setRows(formattedRows);
      setFilteredRows(formattedRows)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  const handleEditClick = (student) => {
    setSelectedteacher(student);
    setEditModalOpen(true);
  };

  // Handle search input changes
  const searchName = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filtered = rows.filter(
      (row) =>
        row.firstName.toLowerCase().includes(searchValue) ||
        row.lastName.toLowerCase().includes(searchValue) ||
        row.email.toLowerCase().includes(searchValue) ||
        row.phone.includes(searchValue)
    );
    setFilteredRows(filtered);
  };

  const handleOpenDeleteModal = (id) => {
    setSelectedTeacherId(id);
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
    setSelectedTeacherId(null);
  };

  const handleDelete = async () => {
    try {
      // console.log("selectedTeacherId",selectedTeacherId)
      const response = await deleteATeacher(selectedTeacherId);
      if (response.status === 200) {
        toast.success("Deleted successfully");
        fetchTeachers();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to delete");
    } finally {
      handleCloseDeleteModal();
    }
  };

  const columns = [
    { field: 'sNo', headerName: 'No.', width: 70 },
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'middleName', headerName: 'Middle Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'address', headerName: 'Address', width: 300 },
    { field: 'specializations', headerName: 'specializations', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    {
      field: 'image', headerName: 'Image',
      width: 100,
      renderCell: (params) => (
        <Avatar src={params.value || 'https://via.placeholder.com/50'} alt="avatar" />
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <div>
          <IconButton color="primary"
            onClick={() => handleOpenDeleteModal(params.row.id)}>
            <DeleteIcon />
          </IconButton>
          <EditIcon
            color="primary"
            sx={{ cursor: 'pointer' }}
            onClick={() => handleEditClick(params.row)}
          />
        </div>

      ),
    },
  ];

  return (
    <div className="h-[80vh] w-full mt-5">
      <Headingtitle title="Teacher Lists" />
      <div className="bg-white p-4 shadow-md rounded-md">
        <div className="mb-5">
          <input
            type="text"
            placeholder="Search by name, email, or mobile"
            className="p-2 border border-gray-300 rounded w-full"
            onChange={searchName}
          />
        </div>
        <DataGrid
          style={{ backgroundColor: 'white' }}
          rows={filteredRows}
          columns={columns}
          loading={loading}
          pageSize={10}
          slots={{ toolbar: GridToolbar }}
          checkboxSelection
        />
      </div>
      {isEditModalOpen && (
        <EditTeacherModal
          open={isEditModalOpen}
          handleClose={() => setEditModalOpen(false)}
          teacherData={selectedteacher}
          refreshData={fetchTeachers} // Function to refresh data
        />
      )}

      <Dialog open={open} onClose={handleCloseDeleteModal}>
        <DialogTitle>Delete Teacher</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this teacher permanently? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OnboardedTeacherList;
