import React from 'react'
import Headingtitle from './Headingtitle'
import YouTubeEmbed from './YoutubeEmbedComp'
const Help = ({videoLink}) => {
    const url =videoLink || 'https://www.youtube.com/watch?v=ptxFPM25UwA'
    return (
        <div className="font-poppins">
            <Headingtitle title="Watch the Video" />
            <main className="px-4 py-0">
                <YouTubeEmbed videoLink={url} />
            </main>
        </div>
    )
}

export default Help
