import React, { useState } from 'react';
import { MdExpandMore } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { FaHouseUser } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { logout } from '../../Redux/slices/authSlice';
import { CiLogout } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ Sidebar }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(null);
    const location = useLocation();
    const currentPath = location.pathname;


    const handleToggle = (index) => {
        setExpanded(expanded === index ? null : index);
    };
    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
    };

    return (
        <div className=' px-4 mb-5 md:h-[90vh] overflow-y-scroll '
            style={{
                scrollbarWidth: "none", // For Firefox
                msOverflowStyle: "none", // For Internet Explorer and Edge
            }}
        >



            <div
                style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                }}
                className=' min-h-screen  w-[100%]  mx-0 px-0'>
                {Sidebar.map((item, index) => (
                    <div key={index} className={`mb-2 py-2 font-semibold cursor-pointer`}>
                        {/* Check if item has sub-links */}
                        {item.Links ? (
                            <div className='cursor-pointer'>
                                <div
                                    className="flex justify-between items-center cursor-pointer"
                                    onClick={() => handleToggle(index)}
                                >
                                    <div className={`${item?.className || ""} flex gap-1 items-center`}>
                                        <FaHouseUser />
                                        <Link
                                            to={`/${item.path}`}
                                            className={`font-poppins text-[12px] leading-[18px] text-left`}
                                        >
                                            {item.title}
                                        </Link>
                                    </div>
                                    <MdExpandMore
                                        className={`transform transition-transform duration-300 ${expanded === index ? 'rotate-180' : ''}`}
                                    />
                                </div>
                                {expanded === index && (
                                    <div className="mt-2 pl-4 flex flex-col gap-1">
                                        {item.Links.map((sub, subIndex) => (
                                            console.log("sub", sub.path),
                                            <div key={subIndex} className={`${sub?.className || ''} rounded flex gap-2 py-2 items-center text-[#979797] ${String(currentPath) === sub?.path ? 'bg-black px-1 text-white' : ''}`}>
                                                <FaHouseUser />
                                                <Link
                                                    to={sub.path}
                                                    className={`font-poppins text-[12px]  leading-[18px] text-left`}
                                                >
                                                    {sub.title}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className={`sidebar-item flex items-center space-x-2 rounded ${currentPath === `/${item.path}` ? 'bg-black py-2 px-1 text-white' : ''}`}>
                                {item.title === "Reset Password" ? <CiLogout /> : <FaHouseUser />}
                                <Link
                                    to={`/${item.path}`}
                                    className={`font-poppins text-[12px] leading-[18px] text-left`}
                                >
                                    {item.title}
                                </Link>
                            </div>
                        )}
                    </div>

                ))}
                <div className="cursor-pointer flex items-center space-x-2"
                    onClick={handleLogout}
                >
                    <CiLogout />
                    <p
                        className={`font-poppins text-[12px] leading-[18px] text-left font-bold }`}
                    >
                        Logout
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;