import React, { useEffect, useState } from "react";
import { getStudentLeaveApplicationList, rejectApplication, approveApplication } from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";
import { toast } from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';

const StudentLeaveList = () => {
    const [studentLeaveList, setStudentLeaveList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedLeave, setSelectedLeave] = useState(null); 
    const [actionType, setActionType] = useState(""); 
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchStudentLeaveList = async () => {
        setLoading(true);
        try {
            const response = await getStudentLeaveApplicationList();
            setStudentLeaveList(response?.data?.leaveApplications || []);
        } catch (error) {
            console.error("Error fetching student leave list:", error);
            setStudentLeaveList([]);
        } finally {
            setLoading(false);
        }
    };

    // Fetch student leave list
    useEffect(() => {

        fetchStudentLeaveList();
    }, []);

    // Open modal for Approve or Reject action
    const handleActionClick = (leave, action) => {
        setSelectedLeave(leave);
        setActionType(action);
        setIsModalOpen(true);
    };

    const handleClose = () => {
        setSelectedLeave(null);
        setIsModalOpen(false);
    }
    console.log("selectedLeave", selectedLeave);
    // Handle approve or reject action
    const handleActionSubmit = async (status) => {
        if (!selectedLeave || !actionType || !status) return;

        try {
            const updatedStatus = status === "Approved" ? "Approved" : "Rejected";

            console.log("UpdatedStatus", updatedStatus, actionType);
            // Make the respective API call
            if (updatedStatus === "Rejected") {
                await rejectApplication(selectedLeave._id); // Reject API call
            } else {
                await approveApplication(selectedLeave._id); // Approve API call
            }

            // Fetch updated leave list after the action
            await fetchStudentLeaveList();

            // Update leave status in the local state
            setStudentLeaveList((prevList) =>
                prevList.map((leave) =>
                    leave._id === selectedLeave._id
                        ? { ...leave, status: updatedStatus }
                        : leave
                )
            );

            toast.success(`Leave application ${updatedStatus.toLowerCase()} successfully!`);
            setIsModalOpen(false); // Close the modal after action
        } catch (error) {
            console.error(`Error ${actionType === "Rejected" ? "rejecting" : "approving"} leave:`, error);
            toast.error("Something went wrong, please try again.");
        }
    };

    // In the Modal buttons, no need to pass actionType explicitly, just use the state:


    return (
        <div>
            <div className="font-poppins">
                <Headingtitle title="Leaves" />
                <main className="bg-white shadow-md rounded-md p-5">
                    <p className="font-semibold pb-2 text-[1rem]">Leaves List</p>
                    <div>
                        {loading ? (
                            <p className="text-gray-500">Loading...</p>
                        ) : studentLeaveList && studentLeaveList.length > 0 ? (
                            <div className="overflow-x-auto">
                                <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                                    <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                                        <tr>
                                            <th className="px-6 py-4 text-left text-[16px] font-bold">Date</th>
                                            <th className="px-6 py-4 text-left text-[16px] font-bold">Student Name</th>
                                            <th className="px-6 py-4 text-left text-[16px] font-bold">Class</th>
                                            <th className="px-6 py-4 text-left text-[16px] font-bold">Reason</th>
                                            <th className="px-6 py-4 text-left text-[16px] font-bold">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentLeaveList.map((record, index) => (
                                            <tr
                                                key={record._id}
                                                className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                                    } hover:bg-indigo-100 transition-all`}
                                            >
                                                <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                    {new Date(record.startDate).toLocaleDateString()} -{" "}
                                                    {new Date(record.endDate).toLocaleDateString()}
                                                </td>
                                                <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                    {`${record.studentId.name.firstName} ${record.studentId.name.lastName}`}
                                                </td>
                                                <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                    {`${record.class.className}-${record.class.classSection}`}
                                                </td>
                                                <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                    {record.reason}
                                                </td>
                                                <td
                                                    onClick={() => handleActionClick(record, record.status === "Pending" ? "Approved" : "Rejected")}
                                                    className="px-4 py-2 border border-gray-300 cursor-pointer"
                                                >
                                                    <span
                                                        className={`px-2 py-2 shadow-md 
                                                    ${record.status === "Rejected" ? "rounded-md text-white bg-red-400" :
                                                                record.status === "Pending" ? "rounded-md text-white bg-yellow-500" :
                                                                    "rounded-md text-white bg-green-500"}`}
                                                    >
                                                        {record.status === "Pending" ? <>Mark <EditIcon /> </> : record.status}
                                                    </span>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="text-gray-500 mt-6">No Leave Applications Found</p>
                        )}
                    </div>
                </main>
            </div>

            {/* Custom Approval / Rejection Modal */}
            {isModalOpen && selectedLeave && (
                <div
                    onClick={handleClose}
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg w-80">
                        {actionType !== "pending" ? (
                            <h3 className="text-lg font-semibold mb-4">
                                {`Are you sure you want to ${selectedLeave.status === "Approved" ? 'Reject' : 'Approve'} this leave application?`}
                            </h3>
                        ) :
                            (
                                <h3 className="text-lg font-semibold mb-4">
                                    {`Are you sure you want to Reject Or Approve this leave application?`}
                                </h3>
                            )
                        }
                        <p>
                            Leave application of {selectedLeave?.studentId?.name?.firstName}{" "}
                            {selectedLeave?.studentId?.name?.lastName}
                        </p>
                        <section className="flex flex-row justify-evenly w-full my-5">
                            <button
                                className={`px-4 py-2 bg-green-500 text-white rounded ${selectedLeave.status === "Approved" ? "cursor-not-allowed" : ""}`}
                                onClick={() => handleActionSubmit("Approved")}
                                disabled={selectedLeave.status === "Approved"}
                            >
                                Approve
                            </button>
                            <button
                                className={`px-4 py-2 text-white bg-red-500 rounded ${selectedLeave.status === "Rejected" ? "cursor-not-allowed" : ""}`}
                                onClick={() => handleActionSubmit("Rejected")}
                                disabled={selectedLeave.status === "Rejected"}
                            >
                                Reject
                            </button>
                        </section>

                    </div>
                </div>
            )}
        </div>
    );
};

export default StudentLeaveList;
