import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import toast from "react-hot-toast";
import { GetClassCategoryList, createNewEvent } from "../../../Services/AdminApis";
import Headingtitle from "../../Common/Headingtitle";

const CreateEventForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    expiryDate: "",
    eventFee: "",
    desc: "",
    targetClasses: [], // multiple select
    targetAudience: [], // multiple select
    attachments: "",
  });

  const [loading, setLoading] = useState(false);

  const [classCategories, setClassCategories] = useState([]);
  const audienceOptions = ["Student", "Teacher", "Parent", "All"];

  const isParentSelected = formData.targetAudience.includes("Parent");
  useEffect(() => {
    const fetchAllClassList = async () => {
      try {
        const response = await GetClassCategoryList();
        setClassCategories(response?.data?.classCategories || []);

      } catch (error) {
        throw error
      }
    }
    fetchAllClassList();
  }, [])

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle multiple select for targetClasses and targetAudience
  const handleMultiSelectChange = (name, values) => {

    setFormData((prev) => ({
      ...prev,
      [name]: values,
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    const formPayload = new FormData();

    // Append form fields to the FormData object
    formPayload.append("title", formData.title);
    formPayload.append("desc", formData.desc);
    formPayload.append("expiryDate", formData.expiryDate);
    formPayload.append("eventFee", formData.eventFee || ""); // Optional field
    formPayload.append("attachments", formData.attachments); // File

    // Append array fields like `targetClasses` and `targetAudience` individually
    formData.targetClasses.forEach((item, index) => {
      formPayload.append(`targetClasses[${index}]`, item.className);
    });

    formData.targetAudience.forEach((item, index) => {
      formPayload.append(`targetAudience[${index}]`, item);
    });

    try {
      const response = await createNewEvent(formPayload);
      if (response?.status === 201 || 200) {
        toast.success("succesfully Created Event")
        setFormData({
          title: "",
          expiryDate: "",
          eventFee: "",
          desc: "",
          targetClasses: [], // multiple select
          targetAudience: [], // multiple select
          attachments: "",
        })
      }

    } catch (error) {
      toast.error(error?.response?.data?.message);
    }


  };

  return (
    <>
    <Headingtitle title="Event"/>
    <Box className="bg-white p-6 rounded-lg shadow-md font-poppins">
      <Typography variant="h5" className="mb-4 font-semibold">
      </Typography>
      <form>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Event Title */}
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700 capitalize"
            >
              Event Title
            </label>
            <TextField
              fullWidth
              variant="outlined"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Enter Event Title"
            />
          </div>

          {/* Event Expiry Date */}
          <div>
            <label
              htmlFor="expiryDate"
              className="block text-sm font-medium text-gray-700 capitalize"
            >
              Expiry Date
            </label>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              name="expiryDate"
              value={formData.expiryDate}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          {/* Event Fee */}
          <div>
            <label
              htmlFor="eventFee"
              className="block text-sm font-medium text-gray-700 capitalize"
            >
              Event Fee
            </label>
            <TextField
              fullWidth
              variant="outlined"
              name="eventFee"
              type="number"
              value={formData.eventFee}
              onChange={handleInputChange}
              placeholder="Enter Event Fee"
            />
          </div>

          <div>
            <label
              htmlFor="attachments"
              className="block text-sm font-medium text-gray-700 capitalize"
            >
              Attachments
            </label>
            <TextField
              fullWidth
              variant="outlined"
              type="file"
              name="attachments"
              onChange={handleInputChange}
            />
          </div>


          {/* Target Classes */}
          <div>
            <label
              htmlFor="targetClasses"
              className="block text-sm font-medium text-gray-700 capitalize"
            >
              Target Classes
            </label>
            <Autocomplete
              multiple
              id="targetClasses"
              options={classCategories}
              getOptionLabel={(option) => option.className} // Extracts the `className` for display
              value={formData.targetClasses}
              onChange={(event, newValue) =>
                handleMultiSelectChange("targetClasses", newValue)
              }
              disabled={!isParentSelected} // Disable if "Parent" is not selected
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={
                    isParentSelected
                      ? "Select Classes"
                      : "Disabled (Select Parent first)"
                  }
                />
              )}
            />
          </div>
          {/* Target Audience */}
          <div>
            <label
              htmlFor="targetAudience"
              className="block text-sm font-medium text-gray-700 capitalize"
            >
              Target Audience
            </label>
            <Autocomplete
              multiple
              options={audienceOptions}
              value={formData.targetAudience}
              onChange={(event, newValue) =>
                handleMultiSelectChange("targetAudience", newValue)
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Select Audience" />
              )}
            />
          </div>

          {/* Description */}
          <div>
            <label
              htmlFor="desc"
              className="block text-sm font-medium text-gray-700 capitalize"
            >
              Description
            </label>
            <TextField
              fullWidth
              variant="outlined"
              name="desc"
              multiline
              rows={4}
              value={formData.desc}
              onChange={handleInputChange}
              placeholder="Enter Event Description"
            />
          </div>


        </div>

        {/* Submit Button */}
        <div className="mt-4">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={loading || !formData.title || !formData.desc} // Disable if title or desc are empty
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Create Event"}
          </Button>

        </div>
      </form>
    </Box>
    </>

  );
};

export default CreateEventForm;
