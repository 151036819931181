import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { BiSolidDownArrow } from "react-icons/bi";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StudentAnalyticsChart = ({ analyticsData }) => {
  const labels = analyticsData.map((exam) => exam.examName);
  const percentages = analyticsData.map((exam) => exam.percentage);
  const grades = analyticsData.map((exam) => exam.grade);

  const data = {
    labels,
    datasets: [
      {
        label: "Percentage (%)",
        data: percentages,
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Student Performance Analytics",
      },
    },
  };

  return (
    <div className=" ">
      <div className="flex justify-between h-[100px] border-b-[1px] border-border-100">
        <p className="text-[1.5rem]  font-semibold h-[100px] border-b-[1px] pl-5 border-border-100">
          Result Analytics
        </p>
      </div>
      <div className="h-[300px] flex justify-center items-center">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default StudentAnalyticsChart;



