import React from 'react';
import { formatDateOfBirth, formatName } from '../../utils/utilityFunction';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const StudentTableCard = ({ data, setData,open,handleOpen ,handleClose,handleDelete}) => {
    return (
        <>
            {data && data.length > 0 ? (
                data.map((item, index) => (
                    <div key={index} className="mb-4">
                        <div className="flex flex-col border rounded-lg shadow-md w-full p-4 justify-between items-center relative">
                            <div
                                className="absolute top-2 right-2 cursor-pointer text-red-500"
                                onClick={() => handleOpen(item._id)}
                                title="Delete Record"
                            >
                                <DeleteIcon />
                            </div>

                            {/* Student Info */}
                            <div className="flex w-full items-start gap-1 flex-col h-auto">
                                <div className="flex justify-between items-center w-full px-1">
                                    <div className="flex gap-3 items-center">
                                        <div className="text-lg font-bold">{index + 1}</div>
                                        <img
                                            src="https://via.placeholder.com/50"
                                            alt="Profile"
                                            className="w-[60px] h-[60px] rounded-full"
                                        />
                                        <p className="font-poppins text-[18px] font-semibold leading-[22.5px] text-left">
                                            {formatName(item?.studentId)}
                                        </p>
                                    </div>
                                </div>

                                <div className="h-[2px] w-full bg-gray-400 my-2"></div>

                                {/* Student Details */}
                                <div className="flex w-full justify-between px-4">
                                    <div className="grid grid-cols-3 gap-4 text-gray-500 text-[0.9rem]">
                                        <div>
                                            <p className="font-semibold text-black">
                                                {`${item?.studentId?.class?.className || 'N/A'} - ${item?.studentId?.class?.classSection || 'N/A'}`}
                                            </p>
                                            <p>Class</p>
                                        </div>
                                        <div>
                                            <p className="font-semibold text-black">{item.examName}</p>
                                            <p>Exam Name</p>
                                        </div>
                                        <div>
                                            <p className="font-semibold text-black">{formatDateOfBirth(item?.studentId?.dateOfBirth) || "27th July 2006"}</p>
                                            <p>Birth Date</p>
                                        </div>
                                        <div>
                                            <p className="font-semibold text-black">{item?.studentId?.rollNumber || "N/A"}</p>
                                            <p>Roll Number</p>
                                        </div>
                                        <div>
                                            <p className="font-semibold text-black">{item.academicYear}</p>
                                            <p>Academic Year</p>
                                        </div>
                                        <div>
                                            <p className="font-semibold text-black">{item?.studentId?.gender || "N/A"}</p>
                                            <p>Gender</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p className="text-center text-gray-500 mt-4">No results available for the selected class and exam.</p>
            )}

            {/* Confirmation Dialog */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this record? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StudentTableCard;
