import React from 'react';
import { Modal, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const StudentFeeModal = ({ open, onClose, selectedStudentFeeDetails }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '600px' },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: { xs: 2, sm: 4 }, // Responsive padding
                    borderRadius: 2, // Rounded corners for better aesthetics
                }}
            >
                <h2 style={{ marginBottom: '1rem', textAlign: 'center' }}>Fee Details & Payment History</h2>
                {selectedStudentFeeDetails && (
                    <>
                        <TableContainer component={Paper} sx={{ mb: 3 }}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><strong>Student Name</strong></TableCell>
                                        <TableCell>
                                            {`${selectedStudentFeeDetails.studentName.firstName} 
                                            ${selectedStudentFeeDetails.studentName.middleName || ''} 
                                            ${selectedStudentFeeDetails.studentName.lastName}`}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Fee Type</strong></TableCell>
                                        <TableCell>{selectedStudentFeeDetails.feeType}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Month</strong></TableCell>
                                        <TableCell>{selectedStudentFeeDetails.month}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Fee Amount</strong></TableCell>
                                        <TableCell>₹{selectedStudentFeeDetails.feeAmount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Due Date</strong></TableCell>
                                        <TableCell>{new Date(selectedStudentFeeDetails.dueDate).toLocaleDateString()}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Payment Status</strong></TableCell>
                                        <TableCell>{selectedStudentFeeDetails.paymentStatus}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <h3 style={{ marginBottom: '1rem', textAlign: 'center' }}>Payment History</h3>
                        {selectedStudentFeeDetails.paymentHistory && selectedStudentFeeDetails.paymentHistory.length > 0 ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Date</strong></TableCell>
                                            <TableCell><strong>Payment Method</strong></TableCell>
                                            <TableCell><strong>Amount (₹)</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedStudentFeeDetails.paymentHistory.map((payment, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{new Date(payment.date).toLocaleDateString()}</TableCell>
                                                <TableCell>{payment.method}</TableCell>
                                                <TableCell>{payment.amount}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <p style={{ textAlign: 'center' }}>No payment history available.</p>
                        )}
                    </>
                )}
                <Box display="flex" justifyContent="center" mt={3}>
                    <Button variant="outlined" color="secondary" onClick={onClose}>
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default StudentFeeModal;
