import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Headingtitle from "../../Common/Headingtitle";
import campus from "../../../Assests/Logos/campus.png";
import { useNavigate } from "react-router-dom";
import { GetStudentSubjectOverviewbyId } from "../../../Services/StudentApis";

const StudentDetailSubjects = () => {
  const navigate = useNavigate();
  const { subjectId } = useParams();
  const [subjectDetails, setSubjectDetails] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchSubjectDetails = async () => {
      try {
        const response = await GetStudentSubjectOverviewbyId(subjectId); 
        setSubjectDetails(response?.data); 
      } catch (error) {
        console.error("Error fetching subject details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubjectDetails();
  }, [subjectId]);

  const handleNavigate = (chapter) => {
    navigate(`/student/dashboard/study-material/${subjectId}/${chapter?.chapterNumber}`)
  }

  if (loading) {
    return <p className="text-center mt-10">Loading...</p>;
  }
  if (!subjectDetails) {
    return <p className="text-center mt-10">No subject details found.</p>;
  }
  const { subjectName, chapters } = subjectDetails;
  return (
    <section>
      <Headingtitle title="Subject Details" />
      <p>Click on chapter to view details</p>

      <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[80vh]">
        <section className="min-w-full bg-white rounded-lg min-h-[80vh] font-poppins">
          <div className="w-full bg-bg_blue-25 h-[60px] rounded-lg p-3">
            <p className="text-text_blue-500 text-[1.1rem] font-semibold">
              {subjectName || "Subject Name"}
            </p>
          </div>
          <div className="grid grid-cols-12 grid-rows-2 gap-4 p-5">
            {chapters?.length > 0 ? (
              chapters.map((chapter, index) => (
                <div
                  onClick={() => handleNavigate(chapter)}
                  key={index}
                  className="col-span-4 leading-[17px] cursor-pointer p-3 gap-4 text-[#000000] text-[0.7rem] flex border-[1px] border-border-100 rounded-lg items-center"
                >
                  <img
                    src={campus}
                    alt="Chapter Logo"
                    className="w-[38px] h-[36px] mx-4"
                  />
                  <div className="flex flex-col space-y-2">
                    <div className="flex space-x-3">
                      <p className="font-semibold">Chapter Number</p>
                      <p>{chapter?.chapterNumber || "No title available"}</p>
                    </div>
                    <div>
                      <p className="font-semibold">Chapter Name</p>
                      <p>{chapter.title || "No title available"}</p>
                    </div>
                    <div>
                      <p className="font-semibold">Chapter Description</p>
                      <p>{chapter.description || "No description available"}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="col-span-12 text-center text-gray-500">
                No chapters available for this subject.
              </p>
            )}
          </div>
        </section>
      </div>
    </section>
  );
};

export default StudentDetailSubjects;
