import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { getLeaveApplication, ApplyforLeaveApplication } from "../../../Services/TeachersApis";
import toast from "react-hot-toast";
const MyLeave = () => {
    const [applicationList, setApplicationList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        leaveType: "Full Day",
        startDate: "",
        endDate: "",
        reason: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const fetchLeaveApplication = async () => {
        setLoading(true);
        try {
            const response = await getLeaveApplication();
            console.log("dnjj ikjhkjh", response)
            setApplicationList(response?.data || []);
        } catch (error) {
            console.error("Error fetching leave applications:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        fetchLeaveApplication();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Leave Application Submitted:", formData);
        setLoading(true);
        try {
            const response = await ApplyforLeaveApplication(formData);
            console.log(response?.data);

            if (response?.status === 200 || 201) {

                toast.success("Succesfully Apply for Leave")

                setFormData({
                    leaveType: "Full Day",
                    startDate: "",
                    endDate: "",
                    reason: "",
                })
                fetchLeaveApplication();


            }



        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }

        // Add your API call or logic here to submit the leave application
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Manage Leave Applications" />
            <main className="bg-white h-auto rounded-md p-5">
                {/* Leave Application Form */}
                <form
                    onSubmit={handleSubmit}
                    className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-2"
                >
                    <div className="flex flex-col col-span-2">
                        <label
                            htmlFor="leaveType"
                            className="text-gray-600 font-semibold text-[0.77rem] mb-2"
                        >
                            Leave Type
                        </label>
                        <select
                            id="leaveType"
                            name="leaveType"
                            value={formData.leaveType}
                            onChange={handleInputChange}
                            className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 hover:border-indigo-400 transition"
                            aria-label="Select leave type"
                        >
                            <option value="Full Day">Full Day</option>
                            <option value="1st Half">1st Half</option>
                            <option value="2nd Half">2nd Half</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div className="flex flex-col">
                        <label
                            htmlFor="startDate"
                            className="text-gray-600 font-semibold text-[0.77rem] mb-2"
                        >
                            Start Date
                        </label>
                        <input
                            id="startDate"
                            type="date"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleInputChange}
                            className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 hover:border-indigo-400 transition"
                            aria-label="Select start date"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label
                            htmlFor="endDate"
                            className="text-gray-600 font-semibold text-[0.77rem] mb-2"
                        >
                            End Date
                        </label>
                        <input
                            id="endDate"
                            type="date"
                            name="endDate"
                            value={formData.endDate}
                            onChange={handleInputChange}
                            className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 hover:border-indigo-400 transition"
                            aria-label="Select end date"
                        />
                    </div>
                    <div className="flex flex-col col-span-2">
                        <label
                            htmlFor="reason"
                            className="text-gray-600 font-semibold text-[0.77rem] mb-2"
                        >
                            Reason
                        </label>
                        <textarea
                            id="reason"
                            name="reason"
                            value={formData.reason}
                            onChange={handleInputChange}
                            rows="4"
                            className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 hover:border-indigo-400 transition"
                            placeholder="Enter the reason for leave..."
                            aria-label="Reason for leave"
                        ></textarea>
                    </div>
                    <div className="flex justify-center col-span-2 mt-4">
                        <button
                            type="submit"
                            className="bg-indigo-500 w-full text-white font-bold px-6 py-3 rounded-md shadow hover:bg-indigo-600 transition duration-300"
                        >
                            Submit Leave Application
                        </button>
                    </div>
                </form>
            </main>

            {/* Leave Applications Table */}
            <div className="bg-white h-auto rounded-md px-5 pb-5 mt-5 pt-2">
                <p className="text-text_blue-500 text-[1.13rem] font-semibold ">Leave Applications  List</p>
                {
                    <div class="overflow-x-auto">
                        <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-2">
                            <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                                <tr>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Teacher Name</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Leave Type</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Start Date</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">End Date</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Reason</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <p className="text-gray-500">Loading...</p>
                                ) : applicationList.length > 0 ? (

                                    applicationList.map((application, index) => (
                                        <tr
                                            key={application._id}
                                            className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                                } hover:bg-indigo-100 transition-all`}
                                        >
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {application.teacherId.name.firstName}{" "}
                                                {application.teacherId.name.lastName}
                                            </td>
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {application.leaveType}
                                            </td>
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {new Date(application.startDate).toLocaleDateString()}
                                            </td>
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {new Date(application.endDate).toLocaleDateString()}
                                            </td>
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {application.reason}
                                            </td>
                                            <td className="px-6 py-4 text-[15px]">
                                                <span
                                                    className={`px-3 py-1 rounded-md text-sm font-medium ${getStatusClass(
                                                        application.status
                                                    )}`}
                                                >
                                                    {application.status}
                                                </span>
                                            </td>

                                        </tr>
                                    ))

                                ) : (
                                    <p className="text-gray-500 mt-6">No Leave Applications Found</p>
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    );
};

const getStatusClass = (status) => {
    switch (status) {
        case "Pending":
            return "bg-gray-200 text-gray-800";
        case "Rejected":
            return "bg-red-200 text-red-800";
        case "Approved":
            return "bg-green-200 text-green-800";
        default:
            return "bg-gray-200 text-gray-800";
    }
};


export default MyLeave;
