import api from "./apiUtility";

export const getSchoolStats = async () => {
    try {
        const response = await api.get("/admin-school/school-stats");
        return response;
    } catch (error) {
        throw error
    }

}

export const updatehasTourSeen = async () => {
    try {
        const response = await api.patch("/admin-school/update-admin-school-tour");
        return response;

    } catch (error) {
        throw error
    }

}
export const getSchoolAnalytics = async () => {
    try {
        const response = await api.get("/admin-school/school-analytics");
        return response;

    } catch (error) {
        throw error
    }

}

export const OnboardNewTeacher = async (payload) => {
    console.log("da", payload)
    try {
        const response = await api.post("/admin-school/onboard-new-teacher", payload,
            {
                headers: {
                    'Content-Type': 'multipart/form-data', // Ensure this is set
                }
            }
        );
        return response;
    } catch (error) {
        throw error
    }
}

export const deleteATeacher = async (id) => {
    try {
        const response = await api.delete(`/admin-school/teacher-permanent-delete/${id}`);
        return response;
    } catch (error) {
        throw error
    }
}


export const OnboardNewStudent = async (payload) => {
    try {
        const response = await api.post("/admin-school/onboard-new-student", payload,
            {
                headers: {
                    'Content-Type': 'multipart/form-data', // Ensure this is set
                }
            }
        );
        return response;
    } catch (error) {
        throw error
    }
}
export const deleteAStudent = async (id) => {
    try {
        const response = await api.delete(`/admin-school/delete-student/${id}`);
        return response;
    } catch (error) {
        throw error
    }
}
export const getAllStudentList = async () => {
    try {
        const response = await api.get("/admin-school/get-all-student");
        return response;

    } catch (error) {
        throw error
    }

}

// Class APIs
export const getAllClassIdList = async () => {
    try {
        const response = await api.get("/class/all-list");
        return response;

    } catch (error) {
        throw error
    }

}
export const AddNewClass = async (payload) => {
    try {
        const response = await api.post("/admin-school/add-class", payload,);
        return response;
    } catch (error) {
        throw error
    }
}

export const assignNewClassTeacher = async (payload) => {
    try {
        const { className, classSection, classTeacher } = payload;
        const response = await api.patch(`/admin-school/assign-new-class-teacher/?className=${className}&classSection=${classSection}`, { classTeacher },);
        return response;
    } catch (error) {
        throw error
    }
}


export const CreateNewSubjectWithChapter = async (payload) => {
    try {
        const response = await api.post('/admin-school/create-new-subject-with-chapter-desc', payload);
        return response;
    } catch (error) {
        throw error
    }
}

export const AssignNewClassTeacher = async (payload) => {
    try {
        const response = await api.patch("/admin-school/assign-new-class-teacher", payload,);
        return response;
    } catch (error) {
        throw error
    }
}


// Subject

export const GetAllSubject = async () => {
    try {
        const response = await api.get("/admin-school/all-subject-list");
        return response;
    } catch (error) {
        throw error
    }
}

export const deleteSubjectById = async (subjectId) => {
    try {
        const response = await api.delete(`/admin-school/subject/${subjectId}`);
        return response;
    } catch (error) {
        throw error
    }
}

export const CreateNewSubject = async (payload) => {
    try {
        const response = await api.post("/admin-school/add-subject", payload,);
        return response;
    } catch (error) {
        throw error
    }
}


export const GetSubjectOverviewbyId = async (subjectId) => {
    try {
        const response = await api.get(`/subject-overview/${subjectId}`)
        return response;
    } catch (error) {
        throw error
    }
}

export const GetClassCategoryList = async () => {
    try {
        const response = await api.get(`/all-class-category-list`);
        return response;
    } catch (error) {
        throw error
    }
}

export const GetClassSectionList = async () => {
    try {
        const response = await api.get(`/section/all-list`);
        return response;
    } catch (error) {
        throw error
    }
}



export const GetOnboardedClassList = async () => {
    try {
        const response = await api.get(`/class/all-active-inactive-class-list`);
        return response;
    } catch (error) {
        throw error
    }
}

export const assignNewStudentToParent = async (studentId, parentId) => {
    try {
        const response = await api.patch("admin-school/assign-new-student-to-parent", { studentId, parentId })
        return response;
    } catch (error) {
        throw error
    }
}


export const OnBaoardNewClass = async (payload) => {
    try {
        const response = await api.post("/admin-school/add-class", payload)
        return response;
    } catch (error) {
        throw error
    }
}


export const GetOnboardedTeacherList = async () => {
    try {
        const response = await api.get(`/admin-school/get-all-teacher`);
        return response;
    } catch (error) {
        throw error
    }
}


export const GetOnboardedStudentList = async (classId) => {
    try {
        const response = await api.get(`/admin-school/get-all-student/?classId=${classId}`);
        return response;
    } catch (error) {
        throw error
    }
}


export const createNewTimetable = async (payload) => {
    try {
        const response = await api.post(`/admin-school/create-new-time-table`, payload);
        return response;
    } catch (error) {
        throw error
    }
}

export const Timetable = async (classId = "") => {
    try {
        const response = await api.get(`/admin-school/time-table-of-a-class?classId=${classId}`);
        return response;
    } catch (error) {
        throw error
    }
}

export const UpdatedTimetable = async (id, updatedPayload) => {
    try {
        const response = await api.patch(`/admin-school/update-time-table/${id}`, updatedPayload);
        return response;
    } catch (error) {
        throw error
    }
}

export const deleteTimetable = async (id) => {
    try {
        const response = await api.delete(`/admin-school/delete-time-table/${id}`);
        return response;
    } catch (error) {
        throw error
    }
}
export const getTmetable = async () => {
    try {
        const response = await api.get(`/admin-school/update-time-table-for-particular-date/`);
        return response;
    } catch (error) {
        throw error
    }
}

export const onboardNewParent = async (payload) => {
    try {
        const response = await api.post("/admin-school/onboard-new-parent", payload,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        );
        return response;
    } catch (error) {
        throw error
    }
}

export const getParentDetailbyId = async (parentId) => {
    try {
        const response = await api.get(`/admin-school/get-parent-details/${parentId}`);
        return response;
    } catch (error) {
        throw error
    }
}




export const getAllParentList = async () => {
    try {
        const response = await api.get(`/admin-school/get-all-parent`);
        return response;
    } catch (error) {
        throw error
    }
}

export const onboardNewExamTimetable = async (payload) => {
    try {
        const response = await api.post("/admin-school/create-new-exam-time-table", payload)
        return response;
    } catch (error) {
        throw error

    }
}

export const getExamTimetables = async (filters) => {
    try {
        const params = new URLSearchParams(filters).toString();
        const response = await api.get(`/admin-school/exam-timetables?${params}`)
        return response;
    } catch (error) {
        throw error

    }
}

export const deleteExamTimetableById = async (id) => {
    try {
        const response = await api.delete(`/admin-school/delete-exam-timetable/${id}`)
        return response;
    } catch (error) {
        throw error

    }
}

// export const getExamTimetables = async (filters) => {
//     const params = new URLSearchParams(filters).toString();
//     const response = await fetch(`{{planet_edu_local}}/admin-school/exam-timetables?${params}`);
//     return response.json();
// };

export const getAllSubjectNameList = async () => {
    try {
        const response = await api.get("/admin-school/all-subject-list");
        return response;
    } catch (error) {
        throw error;
    }
}


export const getClassSubjectChapterList = async (classId, className, classSection) => {
    try {
        const response = await api.get(`subject-list-of-a-class/${classId}?className=${className}&sections=${classSection}`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const getSubjectOverVeiw = async (subjectId) => {
    try {
        const response = await api.get(`/subject-overview/${subjectId}`)
        return response;
    } catch (error) {
        throw error;
    }
}

export const createNoticeBoard = async (payload) => {
    try {
        const response = await api.post("/admin-school/publish-new-notice", payload)
        return response;
    }
    catch (error) {
        throw error;
    }
}

export const getAllNoticeBoard = async () => {
    try {
        const response = await api.get("/admin-school/all-notice-board")
        return response;
    }
    catch (error) {
        throw error;
    }
}

export const deleteNoticeBoardById = async (id) => {
    try {
        const response = await api.delete(`/admin-school/delete-notice/${id}`)
        return response;
    }
    catch (error) {
        throw error;
    }
}
export const createNewRoute = async (payload) => {
    try {
        const response = await api.post("/admin-school/create-new-route", payload)
        return response;
    } catch (error) {
        throw error
    }
}

export const addStudentToATransportRoute = async (payload) => {
    try {
        const response = await api.patch("/admin-school/onboard-new-student-to-a-route", payload);
        return response;
    } catch (error) {
        throw error;
    }
}

export const removeAStudentFromATransportRoute = async (payload) => {
    try {
        const response = await api.patch("/admin-school/remove-student-from-a-route", payload);
        return response;
    } catch (error) {
        throw error;
    }
}


export const deleteATransportRoute = async (id) => {
    try {
        const response = await api.delete(`/admin-school/delete-transport-route/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}
export const getAlltranportRoute = async () => {
    try {
        const response = await api.get("/admin-school/get-all-transport-route");
        return response;
    } catch (error) {
        throw error;
    }
}


export const createStudentFees = async (payload) => {
    try {
        const response = await api.post("/admin-school/create-student-fee", payload);
        return response;
    } catch (error) {
        throw error;
    }
}


export const getAllteacherLeavesapplication = async () => {
    try {
        const response = await api.get(`/admin-school/all-teachers-leave-applications`);
        return response;
    } catch (error) {
        throw error;
    }
};



export const getReviewteacherLeavesapplication = async (leaveId, payload) => {
    try {
        console.log("", leaveId, " ", payload)
        const response = await api.patch(`/admin-school/review-leave-application/${leaveId}`, payload);
        return response;
    } catch (error) {
        throw error;
    }
}


export const createNewEvent = async (payload) => {
    try {
        const response = await api.post("/admin-school/create-new-events", payload);
        return response;
    } catch (error) {
        throw error;
    }
}


export const getAllEventsList = async () => {
    try {
        const response = await api.get(`/admin-school/all-upcomming-events`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteEventById = async (id) => {
    try {
        const response = await api.delete(`/admin-school/delete-events/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const editEventById = async () => {
    try {
        const response = await api.patch(`/admin-school/edit-events`);
        return response;
    } catch (error) {
        throw error;
    }
};


export const createNewHoliday = async (payload) => {
    try {
        const response = await api.post("/admin-school/create-new-holiday", payload);
        return response;
    } catch (error) {
        throw error;
    }
}


export const getAllUpComingHolidayList = async () => {
    try {
        const response = await api.get(`/admin-school/all-up-comming-holiday-list`);
        return response;
    } catch (error) {
        throw error;
    }
};


export const getAllUpcomingEvents = async () => {
    try {
        const response = await api.get("/admin-school/all-upcomming-events");
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteHolidayById = async (id) => {
    try {
        const response = await api.delete(`/admin-school/delete-holiday/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const editHolidayById = async () => {
    try {
        const response = await api.patch("/admin-school/");
        return response;
    } catch (error) {
        throw error;
    }
}


// class fee
export const createClassFee = async (payload) => {
    try {
        console.log("searchParams", payload)
        const response = await api.post(`/admin-school//create-fee-for-class`, payload);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getClassStudentsFee = async (searchParams) => {
    try {
        const { classId = '', month = '', dueDate = '' } = searchParams;
        console.log("searchParams", searchParams)
        const response = await api.get(`/admin-school/student-fee-history?classId=${classId}&month=${month}&dueDate=${dueDate}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const markFeeSubmitted = async (payload) => {
    try {
        const response = await api.patch(`/admin-school/mark-student-fee`, payload);
        return response;
    } catch (error) {
        throw error;
    }
}


export const adminforgotPassword = async (payload) => {
    try {
        const response = await api.patch(`/admin-school/send-forgot-password-otp`, payload)
        return response;
    } catch (error) {
        throw error;

    }
}



export const adminverifyforgotPasswordOTP = async (payload) => {
    try {
        const response = await api.patch(`/admin-school/verify-forgot-password-otp`, payload)
        return response;
    } catch (error) {
        throw error;

    }
}



export const AdminChangePassword = async (payload) => {
    try {
        const response = await api.patch(`/admin-school/create-new-password`, payload)
        return response;
    } catch (error) {
        throw error;

    }
}


export const updateStudentData = async (studentId, payload) => {
    try {
        const response = await api.patch(`/admin-school/update-student/${studentId}`, payload)
        return response;
    } catch (error) {
        throw error;

    }
}

export const updateTeacherData = async (teacherId, payload) => {
    try {
        const response = await api.patch(`/admin-school/update-teacher/${teacherId}`, payload);
        return response;
    } catch (error) {
        throw error
    }
}



export const deleteParent = async (parentId) => {
    try {
        const response = await api.delete(`/admin-school/delete-parent/${parentId}`);
        return response;
    } catch (error) {
        throw error
    }
}






export const updateParent = async (parentId,payload) => {
    try {
        const response = await api.patch(`/admin-school/edit-parent-details/${parentId}`,payload);
        return response;
    } catch (error) {
        throw error
    }
}