import React, { useEffect, useState } from "react";
import { getAllSubmittedAssignment } from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";

const SubmittedAssignment = () => {
    const [allSubmittedAssignment, setAllSubmittedAssignment] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSubmittedAssignment = async () => {
            setLoading(true);
            try {
                const response = await getAllSubmittedAssignment();
                setAllSubmittedAssignment(response?.data?.assignments || []);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchSubmittedAssignment();
    }, []);

    // Flatten the data for the custom table
    const rows = allSubmittedAssignment.flatMap((assignment) =>
        assignment.submissions.map((submission) => ({
            id: submission._id, // Use a unique identifier
            title: assignment.title,
            subject: assignment.subject,
            dueDate: new Date(assignment.dueDate).toLocaleDateString(),
            dueTime: assignment.dueTime,
            studentName: `${submission.student.name.firstName} ${submission.student.name.middleName || ""} ${submission.student.name.lastName}`,
            studentId: submission.student.studentId,
            submittedDate: new Date(submission.submittedDate).toLocaleDateString(),
            remarks: submission.remarks,
            status: submission.status,
            submittedFiles: submission.submittedFiles[0], 
        }))
    );

    return (
        <div className="font-poppins  ">
            <Headingtitle title="Submitted Assignments List" />
            <main className="bg-white shadow-lg rounded-lg p-6">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <div className="overflow-x-auto h-[80vh]">
                        <table className="min-w-full  table-auto">
                            <thead>
                            <tr className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-sm uppercase font-semibold">
                                    <th className="px-4 py-2 text-left">Assignment Title</th>
                                    <th className="px-4 py-2 text-left">Subject</th>
                                    <th className="px-4 py-2 text-left">Due Date</th>
                                    <th className="px-4 py-2 text-left">Due Time</th>
                                    <th className="px-4 py-2 text-left">Student Name</th>
                                    <th className="px-4 py-2 text-left">Student ID</th>
                                    <th className="px-4 py-2 text-left">Submitted Date</th>
                                    <th className="px-4 py-2 text-left">Remarks</th>
                                    <th className="px-4 py-2 text-left">Status</th>
                                    <th className="px-4 py-2 text-left">File</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row) => (
                                    <tr key={row.id} className="border-t">
                                        <td className="px-4 py-2">{row.title}</td>
                                        <td className="px-4 py-2">{row.subject}</td>
                                        <td className="px-4 py-2">{row.dueDate}</td>
                                        <td className="px-4 py-2">{row.dueTime}</td>
                                        <td className="px-4 py-2">{row.studentName}</td>
                                        <td className="px-4 py-2">{row.studentId}</td>
                                        <td className="px-4 py-2">{row.submittedDate}</td>
                                        <td className="px-4 py-2">{row.remarks}</td>
                                        <td className="px-4 py-2">
                                            <span
                                                className={`px-2 py-1 rounded text-sm font-medium ${
                                                    row.status === "Submitted"
                                                        ? "bg-green-100 text-green-800"
                                                        : "bg-gray-200 text-gray-800"
                                                }`}
                                            >
                                                {row.status}
                                            </span>
                                        </td>
                                        <td className="px-4 py-2">
                                            <a
                                                href={row.submittedFiles}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-500 hover:underline"
                                            >
                                                View File
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </main>
        </div>
    );
};

export default SubmittedAssignment;
