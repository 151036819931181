import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { AdminChangePassword } from "../../../Services/AdminApis";
import { AiOutlineLock } from "react-icons/ai";
import logo from "../../../Assests/Logos/world.png"

const AdminNewPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!password || !confirmPassword) {
            toast.error("Please fill in all fields");
            return;
        }

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        setLoading(true);

        try {
            const response = await AdminChangePassword({
                mobile: localStorage.getItem("mobile"),
                newPassword: password,
            });

            if (response.status === 200 || response.status === 201) {
                toast.success("Password reset successfully!");
                setPassword("");
                setConfirmPassword("");
            } else {
                toast.error("Failed to reset password");
            }
        } catch (error) {
            console.error("Error resetting password:", error);
            toast.error("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center md:bg-gray-50">
            <div
                className="bg-white md:w-[50vw] w-[90vw] max-w-md md:border md:border-gray-200 md:shadow-lg rounded-lg p-6 flex flex-col justify-evenly"
                style={{
                    height: "80vh",
                }}
            >
                <div>
                    <img src={logo} alt="Logo" className="h-12 mx-auto mb-4" />
                    <h2
                        className="text-2xl font-bold text-center mb-4"
                        style={{
                            fontFamily: "Poppins, Arial, sans-serif",
                            color: "#000",
                        }}
                    >
                        Reset Your Password
                    </h2>
                    <p
                        className="text-center mb-6"
                        style={{
                            fontFamily: "Poppins, Arial, sans-serif",
                            color: "#4B5563",
                            fontSize: "14px",
                        }}
                    >
                        Please enter your new password below.
                    </p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4 relative flex flex-col justify-evenly">
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium mb-1"
                            style={{
                                fontFamily: "Poppins, Arial, sans-serif",
                                color: "#000",
                            }}
                        >
                            New Password
                        </label>
                        <div className="relative">
                            <AiOutlineLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 text-lg" />
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full pl-10 pr-3 py-2 border rounded-full shadow-sm focus:outline-none focus:ring focus:ring-gray-300 text-black"
                                style={{
                                    fontFamily: "Poppins, Arial, sans-serif",
                                    fontSize: "14px",
                                }}
                                placeholder="Enter your new password"
                                required
                            />
                        </div>
                    </div>
                    <div className="mb-4 relative">
                        <label
                            htmlFor="confirmPassword"
                            className="block text-sm font-medium mb-1"
                            style={{
                                fontFamily: "Poppins, Arial, sans-serif",
                                color: "#000",
                            }}
                        >
                            Confirm Password
                        </label>
                        <div className="relative">
                            <AiOutlineLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 text-lg" />
                            <input
                                type="password"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="w-full pl-10 pr-3 py-2 border rounded-full shadow-sm focus:outline-none focus:ring focus:ring-gray-300 text-black"
                                style={{
                                    fontFamily: "Poppins, Arial, sans-serif",
                                    fontSize: "14px",
                                }}
                                placeholder="Confirm your new password"
                                required
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className={`w-full py-2 px-4 mt-4 font-bold rounded-full transition-colors ${loading
                            ? "bg-gray-400 cursor-not-allowed"
                            : "bg-blue-500 hover:bg-blue-600 text-white"
                            }`}
                        style={{
                            fontFamily: "Poppins, Arial, sans-serif",
                            fontSize: "16px",
                        }}
                    >
                        {loading ? "Saving..." : "Reset Password"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AdminNewPassword;
