import React, { useEffect, useState, useTransition } from 'react';
import { getAllNoticeBoard, deleteNoticeBoardById } from '../../../Services/AdminApis';
import Headingtitle from '../../Common/Headingtitle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, } from '@mui/material';
import { toast } from 'react-hot-toast';

const NoticeBoardList = () => {
    const [HolidayList, setHolidayList] = useState([]);
    const [isPending, startTransition] = useTransition();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedNotice, setSelectedNotice] = useState(null);

    const fetAllNoticeBoard = async () => {
        try {
            const response = await getAllNoticeBoard();
            if (response.status === 200) {
                startTransition(() => {
                    setHolidayList(response?.data?.notices || []);
                });
            }

        } catch (error) {
            console.error("Error fetching Holidays:", error);
        }
    };
    useEffect(() => {
        fetAllNoticeBoard();
    }, []);

    const handleDeleteConfirmation = (event) => {
        setSelectedNotice(event);
        setOpenDeleteModal(true);
    };

    const handleDelete = async () => {
        try {
            const response = await deleteNoticeBoardById(selectedNotice._id);
            if (response.status === 200) {
                setOpenDeleteModal(false);
                toast.success("Deleted Successfully")
                fetAllNoticeBoard()
            }
        } catch (error) {
            console.error("Error deleting event:", error);
            toast.error(error?.response?.data?.message || "Deleted Successfully")

        }
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Holiday" />
            <main className="bg-white shadow-md rounded-md p-5  ">
                <p className="font-semibold pb-2 text-[1rem] "> Notice Board Lists</p>
                <div>

                    {isPending ? (
                        <p>Loading...</p>
                    ) : (
                        <div className="overflow-x-auto">
                            <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                                <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                                    <tr>
                                        <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                            Title
                                        </th>
                                        <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                            Description
                                        </th>
                                        <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                            Target Audience
                                        </th>
                                        <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                            Attachments
                                        </th>
                                        <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                            Expiry Date
                                        </th>
                                        <th className="border border-gray-300 px-4 py-3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {HolidayList?.map((item, index) => (
                                        <tr
                                            key={item._id}
                                            className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                                } hover:bg-indigo-100 transition-all`}
                                        >
                                            <td className="border border-gray-300 px-4 py-2 text-gray-700 font-medium">
                                                {item.title}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                                {item.content}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                                {item.targetAudience}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                                <img src={item?.attachments} width={20} height={20} alt="attachements" />
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                                {new Date(item.expiryDate).toLocaleDateString()}
                                            </td>
                                            <td className="gap-2 border px-4 py-2">
                                                {/* <button onClick={() => handleEdit(event)} className="text-blue-500 hover:text-blue-700">
                                                    <EditIcon />
                                                </button> */}
                                                <button onClick={() => handleDeleteConfirmation(item)} className="text-red-500 hover:text-red-700">
                                                    <DeleteIcon />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                </div>
            </main>
            {/* Delete Confirmation Modal */}
            <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this Notice Board?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default NoticeBoardList;
