import React from 'react'
import Headingtitle from './Headingtitle'
const ParentProfile = ({ parentsDetails }) => {
    return (
        <div>
            <Headingtitle title="Parents Profile" />
            <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[78vh]">
                <section className="p-4 flex flex-col items-center custom-scroll overflow-y-scroll gap-4 bg-white text-[0.86rem] rounded-lg h-[79vh]">
                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
                        {parentsDetails.map((parent, index) => (
                            <div
                                key={index}
                                className="w-full h-auto px-5 border border-border-50 rounded-lg shadow-md p-4"
                            >
                                <div className="flex justify-center mb-4">
                                    <img
                                        src={parent.avatar}
                                        alt={`${parent.relation} Avatar`}
                                        className="rounded-full shadow-lg w-[70px] md:w-[90px] aspect-square"
                                    />
                                </div>
                                <div className="font-poppins flex flex-col gap-3">
                                    <div>
                                        <p className="text-[0.8rem] md:text-[0.9rem] leading-[17px]">{parent.relation}</p>
                                        <p className="text-[1rem] md:text-[1.1rem] leading-[22px] font-semibold">{parent.name}</p>
                                    </div>
                                    <div>
                                        <p className="text-[0.8rem] md:text-[0.9rem] leading-[17px]">Date of Birth:</p>
                                        <p className="text-[1rem] md:text-[1.1rem] leading-[22px] font-semibold">{parent.dob}</p>
                                    </div>
                                    <div>
                                        <p className="text-[0.8rem] md:text-[0.9rem] leading-[17px]">Email:</p>
                                        <p className="text-[1rem] md:text-[1.1rem] leading-[22px] font-semibold">{parent.email}</p>
                                    </div>
                                    <div>
                                        <p className="text-[0.8rem] md:text-[0.9rem] leading-[17px]">Occupation:</p>
                                        <p className="text-[1rem] md:text-[1.1rem] leading-[22px] font-semibold">{parent.occupation}</p>
                                    </div>
                                    <div>
                                        <p className="text-[0.8rem] md:text-[0.9rem] leading-[17px]">Phone Number:</p>
                                        <p className="text-[1rem] md:text-[1.1rem] leading-[22px] font-semibold">{parent.phone}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>

        </div>
    )
}

export default ParentProfile
