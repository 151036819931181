import React, { useState, useEffect } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { getMyTimeTable } from "../../../Services/TeachersApis";

const TimeTable = () => {
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [selectedDay, setSelectedDay] = useState("Monday");
  const [timeTableData, setTimeTableData] = useState([]);

  useEffect(() => {
    const fetchMyTimetable = async () => {
      try {
        const response = await getMyTimeTable();
        setTimeTableData(response?.data?.timetables || []);
      } catch (error) {
        console.error("Internal Server Error", error);
      }
    };
    fetchMyTimetable();
  }, []);

  // Filter timetable data based on selected day
  const filteredTimetable = timeTableData.filter((item) => item.day.includes(selectedDay));

  return (
    <div>
      <div className="font-poppins">
        <Headingtitle title="TimeTable" />
        <main className="bg-white shadow-md rounded-md p-5 text-[0.77rem]">
          <p className="font-semibold text-[1rem] pb-2">My TimeTable</p>
          {/* Days Buttons */}
          <div className="grid grid-cols-12 gap-5 mb-5">
            <div className="col-span-12 sm:col-span-10 mx-auto flex flex-wrap justify-center sm:justify-evenly space-y-3 sm:space-y-0">
              {days.map((day) => (
                <button
                  key={day}
                  className={`p-3 sm:p-4 font-semibold rounded text-sm  
                  ${selectedDay === day ? "bg-blue-900 text-white shadow-lg" : "text-black"}`}
                  onClick={() => setSelectedDay(day)}
                >
                  {day}
                </button>
              ))}
            </div>
          </div>
          {/* Timetable Section */}
          <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mx-auto justify-center items-start">
            {filteredTimetable.length > 0 ? (
              filteredTimetable.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col  sm:flex-row col-span-1 space-y-4 sm:space-y-0 sm:space-x-4 items-center justify-center"
                >
                  {/* Time Section */}
                  <div className="text-center flex flex-col sm:flex-row sm:text-right text-[0.77rem]   justify-between items-center">
                    <p>{item?.timeSlot?.start}</p>
                    <div className="h-10 w-[1px] bg-gray-400 mx-4"></div>
                    <p>{item?.timeSlot?.end}</p>
                  </div>

                  {/* Class Information Section */}
                  <div className="bg-gray-100 rounded-lg p-4 w-full sm:w-64 shadow-md">
                    <p className="text-[10px] text-gray-500">Subject</p>
                    <p className="text-[0.77rem] font-semibold">{item.subject}</p>
                    <p className="text-[10px] text-gray-500">Class</p>
                    <p className="text-[0.77rem] font-semibold">
                      {item.class.className} {item.class.classSection}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p className="col-span-full text-center text-gray-500">
                No timetable available for {selectedDay}.
              </p>
            )}
          </section>
        </main>
      </div>
    </div>
  );
};

export default TimeTable;
