import React from 'react';

const YouTubeEmbed = ({ videoLink }) => {
  const extractVideoId = (url) => {
    try {
      const urlParams = new URL(url).searchParams;
      return urlParams.get('v');
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  };

  const videoId = extractVideoId(videoLink);

  if (!videoId) {
    return <p className="text-red-500">Invalid YouTube link. Please provide a valid link.</p>;
  }

  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="flex justify-center items-center w-full h-full">
      <iframe
        className="w-full h-[80vh] rounded-lg shadow-lg"
        src={embedUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>

  );
};

export default YouTubeEmbed;
