import React from 'react';

const BreakpointModal = ({ isOpen, record, onClose }) => {
    if (!isOpen || !record) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-2xl font-semibold mb-6 text-center text-indigo-600">Breakpoint Details</h2>
                
                <p className="mb-4"><strong>Student Name:</strong> {`${record.studentName.firstName} ${record.studentName.middleName || ''} ${record.studentName.lastName}`}</p>
                <p className="mb-4"><strong>Month:</strong> {record.month}</p>
                <p className="mb-4"><strong>Due Date:</strong> {new Date(record.dueDate).toLocaleDateString()}</p>
                
                <div className="mb-6">
                    <h3 className="font-semibold text-lg mb-3 text-gray-700">Fee Breakdown:</h3>
                    <ul className="space-y-3">
                        {record.breakdown.map((item) => (
                            <li key={item._id} className="flex justify-between items-center text-sm text-gray-800">
                                <span className="font-medium">{item.component}</span>
                                <span className="text-gray-700">{item.amount}</span>
                            </li>
                        ))}
                    </ul>
                </div>

                <p className="mb-4"><strong>Payment Status:</strong> 
                    <span className={`px-4 py-2 rounded-full text-sm font-semibold shadow-lg ${record.paymentStatus === 'Pending' ? 'bg-yellow-400 text-white' : record.paymentStatus === 'Paid' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                        {record.paymentStatus}
                    </span>
                </p>
                
                <p className="mb-6"><strong>Fee Amount:</strong> ${record.feeAmount}</p>

                <div className="mt-6 flex justify-end gap-4">
                    <button className="px-6 py-3 text-sm border-gray-600 text-white rounded-md shadow-md bg-gray-500 hover:bg-gray-700 transition-colors duration-200" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BreakpointModal;
