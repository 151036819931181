import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { adminverifyforgotPasswordOTP } from '../../../Services/AdminApis';
import logo from "../../../Assests/Logos/world.png"
const OTP_LENGTH = 6;

const AdminVerifyForgotPasswordOtpComp = () => {
    const inputRefs = useRef([]);
    const navigate = useNavigate();
    const mobile = localStorage.getItem('mobile');
    const orderId = localStorage.getItem('orderId');

    const formik = useFormik({
        initialValues: {
            otp: new Array(OTP_LENGTH).fill(''),
        },
        validationSchema: Yup.object({
            otp: Yup.array()
                .of(Yup.string().required('Required').matches(/^\d$/, 'Only digits are allowed'))
                .length(OTP_LENGTH, `Must be exactly ${OTP_LENGTH} digits`),
        }),
        onSubmit: async (values) => {
            const otpValue = values.otp.join('');
            formik.resetForm();
            const payload = {
                mobile: mobile,
                orderId: orderId,
                otp: otpValue,
            };
            try {
                const response = await adminverifyforgotPasswordOTP(payload);
                if (response.status === 200 || response.status === 201) {
                    navigate('/admin-new-password');
                    toast.success('OTP verified successfully!');
                } else {
                    toast.error(response.data.message || 'Error while verifying OTP');
                }
            } catch (error) {
                console.error('Error verifying OTP:', error);
                toast.error(error.response?.data?.message || 'An error occurred');
            }
        },
    });

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]{1}$/.test(value) || value === '') {
            const newOtp = [...formik.values.otp];
            newOtp[index] = value;
            formik.setFieldValue('otp', newOtp);

            if (value && index < OTP_LENGTH - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && formik.values.otp[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData('text').slice(0, OTP_LENGTH).split('');
        const newOtp = [...formik.values.otp];

        pastedData.forEach((char, index) => {
            if (/^[0-9]{1}$/.test(char)) {
                newOtp[index] = char;
            }
        });

        formik.setFieldValue('otp', newOtp);

        if (pastedData.length === OTP_LENGTH) {
            inputRefs.current[OTP_LENGTH - 1].focus();
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-white md:bg-gray-50">
            <div
                className="flex w-[90vw] md:w-[50vw] max-w-md flex-col items-center justify-evenly p-8 rounded-xl md:shadow-lg md:border md:border-gray-200 bg-white"
                style={{ height: '80vh' }}
            >
                {/* Header with Logo */}
                <div className="text-center mb-6">
                    {/* Logo */}
                    <img src={logo} alt="Logo" className="h-12 mx-auto mb-4" />
                    <h2 className="text-2xl font-bold text-gray-800 mb-4">Verify OTP</h2>
                    <p className="text-sm text-gray-500 mb-6">
                        Enter the OTP sent to your mobile number: <br />
                        <span className="font-semibold">{mobile}</span>
                    </p>
                </div>
                {/* OTP Inputs */}
                <form onSubmit={formik.handleSubmit} className="w-full">
                    <div className="flex space-x-3 justify-center mb-6">
                        {formik.values.otp.map((digit, index) => (
                            <input
                                key={index}
                                ref={(el) => (inputRefs.current[index] = el)}
                                type="text"
                                maxLength={1}
                                value={digit}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={handlePaste}
                                className="w-12 h-12 text-center text-lg font-semibold text-gray-800 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        ))}
                    </div>
                    {formik.errors.otp && formik.touched.otp && (
                        <p className="text-red-500 text-sm text-center mb-4">{formik.errors.otp}</p>
                    )}
                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full py-2 bg-blue-500 text-white font-medium rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Verify OTP
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AdminVerifyForgotPasswordOtpComp;
