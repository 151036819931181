import React, { useState, useEffect } from "react";
import { getAllClassIdList, createClassFee } from '../../../Services/AdminApis'
import { months } from '../../../utils/utilityFunction';
import toast from 'react-hot-toast';
import Headingtitle from "../../Common/Headingtitle";


const ClassFeeForm = () => {
    const [classList, setClassList] = useState([]);
    const [formData, setFormData] = useState({
        classId: "",
        feeType: "Monthly",
        month: '',
        feeAmount: "",
        breakdown: [{ component: "", amount: "" }],
        dueDate: "",
    });


    useEffect(() => {
        const fetchClassListOfSchool = async () => {
            try {
                const response = await getAllClassIdList();
                setClassList(response?.data?.classes || []);
            } catch (error) {
                console.error(error);
            }
        };
        fetchClassListOfSchool();
    }, []);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleBreakdownChange = (index, field, value) => {
        const updatedBreakdown = formData.breakdown.map((item, idx) =>
            idx === index ? { ...item, [field]: value } : item
        );
        setFormData({ ...formData, breakdown: updatedBreakdown });
    };

    const addBreakdownRow = () => {
        setFormData({
            ...formData,
            breakdown: [...formData.breakdown, { component: "", amount: "" }],
        });
    };

    const removeBreakdownRow = (index) => {
        const updatedBreakdown = formData.breakdown.filter((_, idx) => idx !== index);
        setFormData({ ...formData, breakdown: updatedBreakdown });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await createClassFee(formData);
            if (response.status === 201 || response.status == 200) {
                toast.success(response?.data?.message || "Class Fee successfully created!");
                setFormData({
                    classId: "",
                    feeType: "Monthly",
                    month: '',
                    feeAmount: "",
                    breakdown: [{ component: "", amount: "" }],
                    dueDate: "",
                });
            }
        } catch (error) {
            console.error(error);
            toast.error(error.response?.data?.message || "Failed to create class fee.");

        }
    };

    return (
        <>
            <Headingtitle title='Class Fee' />
            <div className="p-6 bg-white shadow-md rounded-lg">
                <h2 className="text-xl font-bold mb-4">Create Class Fee</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="classId" className="block text-sm py-2 font-medium text-gray-700">
                            Select Class
                        </label>
                        <select
                            id="classId"
                            name="classId"
                            value={formData.classId}
                            onChange={handleInputChange}
                            className="py-2 mt-1 block w-full bg-slate-50 rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="" disabled>Select a class</option>
                            {classList?.map((cls) => (
                                <option key={cls._id} value={cls._id}>{cls.className}-{cls.classSection}</option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="feeType" className="block text-sm py-2 font-medium text-gray-700">
                            Fee Type
                        </label>
                        <input
                            type="text"
                            id="feeType"
                            name="feeType"
                            value={formData.feeType}
                            onChange={handleInputChange}
                            className="py-2 mt-1 block w-full bg-slate-50 rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="classId" className="block text-sm py-2 font-medium text-gray-700">
                            Month
                        </label>
                        <select
                            id="month"
                            name="month"
                            value={formData.month}
                            onChange={handleInputChange}
                            className="py-2 mt-1 block w-full bg-slate-50 rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="" disabled>Select a month</option>
                            {months?.map((month, index) => (
                                <option key={index} value={month}>{month}</option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="feeAmount" className="block py-2 text-sm font-medium text-gray-700">
                            Fee Amount
                        </label>
                        <input
                            type="number"
                            id="feeAmount"
                            name="feeAmount"
                            value={formData.feeAmount}
                            onChange={handleInputChange}
                            className="py-2 mt-1 block w-full bg-slate-50  rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block py-2 text-sm font-medium text-gray-700">Breakdown</label>
                        {formData.breakdown.map((item, index) => (
                            <div key={index} className="flex items-center space-x-4 mt-2">
                                <select
                                    value={item.component}
                                    onChange={(e) => handleBreakdownChange(index, "component", e.target.value)}
                                    className="py-2 flex-1 rounded-md bg-slate-100 pl-2 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    <option value="">Select Fee Type</option>
                                    <option value="annualFee">Annual Fee</option>
                                    <option value="academicFee">Academic Fee</option>
                                    <option value="examFee">Exam Fee</option>
                                    <option value="transportationFee">Transportation Fee</option>
                                </select>

                                <input
                                    type="number"
                                    placeholder="Amount"
                                    value={item.amount}
                                    onChange={(e) => handleBreakdownChange(index, "amount", e.target.value)}
                                    className="py-2 flex-1 bg-slate-100 rounded-md pl-2 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                                <button
                                    type="button"
                                    onClick={() => removeBreakdownRow(index)}
                                    className="text-red-500 hover:text-red-700 py-2"
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addBreakdownRow}
                            className="mt-2 bg-blue-500 px-2 py-2 rounded-md hover:bg-blue-700 text-white"
                        >
                            Add Component
                        </button>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="dueDate" className="block py-2 text-sm font-medium text-gray-700">
                            Due Date
                        </label>
                        <input
                            type="date"
                            id="dueDate"
                            name="dueDate"
                            value={formData.dueDate}
                            onChange={handleInputChange}
                            className="mt-1 py-2 block w-full bg-slate-50 rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </>

    );
};

export default ClassFeeForm;
