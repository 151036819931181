import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Headingtitle from './Headingtitle';
import toast from 'react-hot-toast';
import MyStudents from '../Core/ParentDashboard/MyStudentList';
import StudentAttendanceList from '../Core/StudentDashboard/StudentAttendanceList';
import MyStudentAttendanceList from '../Core/ParentDashboard/MyStudentAttendanceList';


const Days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const AttendanceComponent = ({
    title,
    fetchAttendanceData,
    showStudentSelector,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const year = parseInt(searchParams.get('year')) || new Date().getFullYear();
    const month = parseInt(searchParams.get('month')) || new Date().getMonth() + 1;
    const studentId = searchParams.get('studentId');

    const updateQueryParams = (updatedParams) => {
        setSearchParams((prev) => {
            const newParams = { ...Object.fromEntries(prev.entries()), ...updatedParams };
            return new URLSearchParams(newParams);
        });
    };

    const updateAttendance = async () => {
        try {
            if (showStudentSelector && !studentId) {
                return;
            }

            const response = showStudentSelector
                ? await fetchAttendanceData(studentId, month, year)
                : await fetchAttendanceData(month, year);

            if (response?.status === 200 || response?.status === 201) {
                const summary = response.data.attendanceSummary[0] || {};
                setTotalPresent(summary.totalPresent || 0);
                setTotalAbsent(summary.totalAbsent || 0);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Failed to fetch attendance data');
        }
    };


    const handleDateChange = (date) => {
        const newYear = date.getFullYear();
        const newMonth = date.getMonth() + 1;
        updateQueryParams({ year: newYear, month: newMonth });
    };

    const handlePrevMonth = () => {
        const prevDate = new Date(year, month - 2);
        handleDateChange(prevDate);
    };

    const handleNextMonth = () => {
        const nextDate = new Date(year, month);
        handleDateChange(nextDate);
    };

    const handleStudentSelect = (id) => {
        updateQueryParams({ studentId: id });
    };

    useEffect(() => {
        updateAttendance();
    }, [studentId, year, month]);

    useEffect(() => {
        const year = searchParams.get('year') || new Date().getFullYear();
        const month = searchParams.get('month') || new Date().getMonth() + 1;
        setSelectedDate(new Date(year, month - 1));
        const attendanceSummary = async () => {
            if (showStudentSelector) {
                if (!studentId) return;
                await fetchAttendanceData(studentId, month, year);
            } else {
                await fetchAttendanceData(month, year);
            }
        };

        attendanceSummary()
    }, [searchParams]);

    return (
        <div className="font-poppins">
            <Headingtitle title={title} />
            {showStudentSelector && <MyStudents onStudentSelect={handleStudentSelect} />}
            <h3>{(showStudentSelector && !studentId) && 'Please select a student to view attendance.'}</h3>
            <main className="bg-white relative w-full rounded-md xsm:p-5 md:p-0">
                <div className="p-5 rounded-lg w-full">
                    <div className="flex flex-col md:flex-row justify-between items-center w-full  p-1 mx-auto">
                        {/* DatePicker Section */}
                        <section className="flex flex-col md:flex-row-reverse justify-evenly items-start bg-white xsm:p-6 md:p-3 shadow-sm rounded-md w-full md:w-[40%] sm:w-[100%]">
                            <div className="flex flex-col items-center w-full md:w-60">
                                <div className="flex justify-between w-full">
                                    {Days.map((day, index) => (
                                        <p
                                            key={index}
                                            className="text-sm font-medium text-gray-500 text-center w-full"
                                        >
                                            {day.toUpperCase()}
                                        </p>
                                    ))}
                                </div>

                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date) => {
                                        setSelectedDate(date);
                                        updateQueryParams(date);
                                    }}
                                    inline
                                    className="mt-2"
                                    renderCustomHeader={() => null}
                                    showPopperArrow={false}
                                />
                            </div>
                        </section>

                        {/* Navigation and Summary Section */}
                        <div className="w-full sm:w-[100%] md:w-[50%] h-auto flex flex-col space-y-8 md:space-y-20 py-5 items-center">
                            {/* Month Navigation */}
                            <div className="flex items-center w-full md:w-80 space-x-2 border-[1px] border-border-100 rounded-lg px-3 py-2">
                                <button
                                    onClick={handlePrevMonth}
                                    className="bg-text_blue-500 text-white p-2 rounded-md"
                                >
                                    <FaArrowLeft />
                                </button>
                                <div className="text-[0.88rem] font-semibold w-full text-center">
                                    {new Date(year, month - 1).toLocaleString('default', {
                                        month: 'long',
                                    })}{' '}
                                    {year}
                                </div>
                                <button
                                    onClick={handleNextMonth}
                                    className="bg-text_blue-500 text-white p-2 rounded-md"
                                >
                                    <FaArrowRight />
                                </button>
                            </div>

                            {/* Attendance Summary */}
                            <div className="flex flex-wrap justify-evenly w-full">
                                <div className="bg-green-500 flex flex-col items-center text-white p-5 w-[120px] aspect-square rounded-md mb-4 md:mb-0">
                                    <div>Total Present</div>
                                    <div className="text-[0.77rem] font-semibold bg-white p-3 w-fit text-black rounded-full">
                                        {totalPresent}
                                    </div>
                                </div>
                                <div className="bg-red-500 flex flex-col items-center text-white p-5 w-[120px] aspect-square rounded-md">
                                    <div>Total Absent</div>
                                    <div className="text-[0.77rem] font-semibold bg-white p-3 w-fit text-black rounded-full">
                                        {totalAbsent}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {showStudentSelector ? <MyStudentAttendanceList /> : <StudentAttendanceList />}
        </div>
    );
};

export default AttendanceComponent;
