import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Headingtitle from "../../Common/Headingtitle";
import { getAllNotice } from "../../../Services/TeachersApis";

const LatestNotice = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNotices = async () => {
            try {
                const response = await getAllNotice(); 
                if (response.status === 200) {
                    const notices = response.data.notices;
                    const formattedRows = notices.map((notice, index) => ({
                        id: notice._id,
                        title: notice.title,
                        content: notice.content,
                        expiryDate: new Date(notice.expiryDate).toLocaleDateString(),
                        createdAt: new Date(notice.createdAt).toLocaleDateString(),
                        targetAudience: notice.targetAudience,
                        status: notice.isDeleted ? 'Deleted' : 'Active',
                    }));
                    setRows(formattedRows);
                } else {
                    console.error("Error fetching notices:", response?.data?.message);
                }
            } catch (error) {
                console.error("Error fetching notices:", error);
            }
            setLoading(false);
        };

        fetchNotices();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'title', headerName: 'Title', width: 250 },
        { field: 'content', headerName: 'Content', width: 350 },
        { field: 'expiryDate', headerName: 'Expiry Date', width: 180 },
        { field: 'createdAt', headerName: 'Created At', width: 180 },
        { field: 'targetAudience', headerName: 'Target Audience', width: 180 },
        { field: 'status', headerName: 'Status', width: 120 },
    ];

    return (
        <div className="h-[80vh]   w-full mt-5">
            <Headingtitle title="Latest Notices" />
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                loading={loading}
                slots={{ toolbar: GridToolbar }}
                sx={{
                    backgroundColor: 'white',
                    boxShadow: 3, // Adding shadow effect to the DataGrid
                    borderRadius: 2, // Rounded corners for the grid
                    fontFamily: 'Roboto, sans-serif', // Change font family
                    // fontFamily: "poppins", // Alternate row colors
                    fontWeight: "500",

                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#f0f0f0', // Light background for column headers
                        color: '#333', // Dark text color for header
                        fontWeight: '600', // Bold text for headers
                    },
                    '& .MuiDataGrid-row': {
                        backgroundColor: '#fff', // White background for rows
                        '&:nth-child(even)': {
                            backgroundColor: '#f9f9f9',
                        },
                        '&:hover': {
                            backgroundColor: '#e0e0e0', // Light grey on hover
                        },
                    },
                    '& .MuiDataGrid-cell': {
                        color: '#333', // Text color for the cells
                        fontSize: '14px', // Adjust text size for readability
                    },
                    '& .MuiDataGrid-footerContainer': {
                        backgroundColor: '#f0f0f0', // Footer background color
                        color: '#333', // Footer text color
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        backgroundColor: '#ff', // Toolbar background color
                        borderRadius: '4px 4px 0 0', // Rounded corners for the toolbar
                        padding: '5px',
                    },
                }}
            />
        </div>
    );
};

export default LatestNotice;
