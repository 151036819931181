import api from "./apiUtility";


export const SuperAdminLogin = async (payload) => {
    try {
        const response = await api.post("/super-admin/login", payload);
        return response;
    } catch (error) {
        throw error
    }
}


export const OnboardNewSchool = async (payload) => {
    try {
        const response = await api.post("/super-admin/onboard-new-admin-school", payload,
            {
                headers: {
                    'Content-Type': 'multipart/form-data', // Ensure this is set
                }
            }
        );
        return response;
    } catch (error) {
        throw error
    }
}

export const CreateSection = async (payload) => {
    try {
        const response = await api.post("/super-admin/add-section", payload);
        return response;
    } catch (error) {
        throw error;
    }

}

export const getSuperAdminStats = async () => {
    try {
        const response = await api.get("super-admin/get-super-admin-stats");
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllSchoolList = async () => {
    try {
        const response = await api.get("super-admin/all-admin-school");
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllSectionList = async () => {
    try {
        const response = await api.get("/section/all-list");
        return response;
    } catch (error) {
        throw error;
    }
}


export const CreateClass = async (payload) => {
    try {
        const response = await api.post("/super-admin/add-class-category", payload);
        return response;
    } catch (error) {
        throw error;
    }

}

export const getAllClassList = async () => {
    try {
        const response = await api.get("/all-class-category-list");
        return response;
    } catch (error) {
        throw error;
    }
}


export const editCreatedSchool = async (id,payload) => {
    try {
        const response = await api.patch(`/super-admin/edit-admin-school/${id}`,payload);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteCreatedSchool = async (id) => {
    try {
        const response = await api.delete(`/super-admin/delete-admin-school/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

