import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Headingtitle from "../../Common/Headingtitle";
import { GetOnboardedStudentList, GetClassSectionList,getAllClassIdList } from "../../../Services/AdminApis";
import OnboardClipboardModel from "../../../Layout/Models/OnboardClipboardModel";
import axios from "axios";
import { baseUrl } from "../../../utils/baseUrl";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const ParentOnboarded = () => {
    const [isModalOpen, setIsModalOpen] = useState(null);
    const animatedComponents = makeAnimated();
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [studentList, setStudentList] = useState([]);
    const [selectedGurdians, setSelectedGurdians] = useState('parent');
    const [formData, setFormData] = useState({
        father: {
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            mobile: "",
            occupation: "",
            dateOfBirth: "",
        },
        mother: {
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            mobile: "",
            occupation: "",
            dateOfBirth: "",
        },
        guardian: {
            relations: "",
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            mobile: "",
            address: "",
            occupation: "",
            dateOfBirth: "",
        },
        fatherAvatar: "",
        motherAvatar: "",
        guardianAvatar: "",
        students: [],
    });

    const [errors, setErrors] = useState({
        fatherAvatar: "",
        motherAvatar: "",
        guardianAvatar: "",
    });


    const handleOpenModel = (data) => {
        const { parentId, password, email } = data;
        setIsModalOpen({
            codeId: parentId,
            password: password,
            email: email,
        });
    };

    const handleClose = () => {
        setIsModalOpen(null);
    };

    const handleSelectionChange = (selected) => {
        setSelectedStudents(selected || []);
        setFormData((prev) => ({
            ...prev,
            students: selected.map((option) => option.value),
        }));
    };


    const [classList, setClassList] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const classData = await getAllClassIdList();
                if (classData.status === 200) {
                    const formattedClassList = classData.data?.classes.map(cls => ({
                        value: cls._id,
                        label: `${cls.className}-${cls.classSection}`
                    }));
                    console.log("formattedClassList", formattedClassList);
                    setClassList(formattedClassList);
                }
            } catch (error) {
                console.error("Error fetching class list:", error);
            }
        };
        fetchClassList();
    }, []);

    useEffect(() => { 
        const fetchStudentList = async () => {
            if (!selectedClass) return;
            try {
                const studentData = await GetOnboardedStudentList(selectedClass.value);
                if(studentData.status===200){
                    setStudentList(studentData?.data?.students.map(student => ({
                        value: student._id,
                        label: `${student.name.firstName} ${student.name.middleName || ""} ${student.name.lastName}`
                    })) || []);
                }
             
            } catch (error) {
                console.error("Error fetching students:", error);
            }
        };
        fetchStudentList();
    }, [selectedClass]);
  

    const handleInputChange = (section, field, value) => {
        setFormData((prev) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            guardian: {
                relations: selectedGurdians, // Set the relations field
                ...(selectedGurdians === "parent"
                    ? { ...prev.father } // Autofill from father's details
                    : selectedGurdians === "mother"
                        ? { ...prev.mother } // Autofill from mother's details
                        : selectedGurdians === "others"
                            ? {
                                relations: "",
                                firstName: "",
                                middleName: "",
                                lastName: "",
                                email: "",
                                mobile: "",
                                address: "",
                                occupation: "",
                                dateOfBirth: "",
                            }
                            : prev.guardian),
            },
        }));
    }, [selectedGurdians]);

    const handleFileChange = (field, file) => {
        if (file) {
            // Check if the file size exceeds 300KB
            if (file.size > 307200) {
                setErrors((prev) => ({
                    ...prev,
                    [field]: "File size should be less than 300KB.",
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    [field]: "",
                }));
                setFormData((prev) => ({
                    ...prev,
                    [field]: file,
                }));
            }
        }
    };

    const isFormValid = () => {
        const checkRequiredFields = (section) => {
            return Object.entries(formData[section]).every(([key, value]) => {
                if (key === "avatar" || key === "middleName") {
                    return true;
                }
                return value && value !== "";
            });
        };

        return (
            checkRequiredFields("father") &&
            checkRequiredFields("mother") &&
            checkRequiredFields("guardian") &&
            formData.students.length > 0
        );
    };


    const handleAutoSetGurdians = (data) => {
        setSelectedGurdians(data);

        setFormData((prev) => ({
            ...prev,
            guardian: {
                relations: data, // Set the relations field
                ...(data === "parent" // Autofill for "parent"
                    ? { ...prev.father }
                    : data === "mother" // Autofill for "mother"
                        ? { ...prev.mother }
                        : data === "others" // Clear form for "others"
                            ? {
                                relations: "",
                                firstName: "",
                                middleName: "",
                                lastName: "",
                                email: "",
                                mobile: "",
                                address: "",
                                occupation: "",
                                dateOfBirth: "",
                            }
                            : prev.guardian), // Default case (retain existing data)
            },
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!isFormValid()) {
            toast.error("Please fill out all required fields before submitting.");
            setLoading(false);
            return;
        }

        const formDataToSend = new FormData();
        const appendNestedFields = (data, parentKey = "") => {
            Object.keys(data).forEach((key) => {
                const value = data[key];
                const fieldKey = parentKey ? `${parentKey}[${key}]` : key;

                if (value instanceof File) {
                    formDataToSend.append(fieldKey, value);
                } else if (typeof value === "object" && value !== null) {
                    appendNestedFields(value, fieldKey);
                } else {
                    formDataToSend.append(fieldKey, value);
                }
            });
        };

        appendNestedFields({
            ...formData,
            fatherAvatar: formData.fatherAvatar instanceof File ? formData.fatherAvatar : null,
            motherAvatar: formData.motherAvatar instanceof File ? formData.motherAvatar : null,
            guardianAvatar: formData.guardianAvatar instanceof File ? formData.guardianAvatar : null,
        });
        console.log(Object.values(formDataToSend))

        try {
            const token = JSON.parse(window.localStorage.getItem("token"));
            const response = await axios.post(
                `${baseUrl}/admin-school/onboard-new-parent`,
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200 || response.status === 201) {
                const ModelData = response?.data?.loginCredentials || {};
                handleOpenModel(ModelData);
                toast.success("Successfully Added Parents Data");
                setLoading(false);
                resetForm();
            } else {
                toast.error("Error while Adding Parents Data");
                setLoading(false);
            }
        } catch (error) {
            console.error("Internal Server Error", error);
            toast.error(error?.response?.data?.error);
            setLoading(false);
        }

    };

    const resetForm = () => {
        setFormData({
            father: {
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                mobile: "",
                occupation: "",
                dateOfBirth: "",
            },
            mother: {
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                mobile: "",
                occupation: "",
                dateOfBirth: "",
            },
            guardian: {
                relations: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                mobile: "",
                address: "",
                occupation: "",
                dateOfBirth: "",
            },
            fatherAvatar: "",
            motherAvatar: "",
            guardianAvatar: "",
            students: [],
        },
            setErrors({
                fatherAvatar: "",
                motherAvatar: "",
                guardianAvatar: "",
            }));
    };
    return (
        <div className="font-poppins bg-gray-100 p-5 rounded-lg">
            <h1 className="text-xl font-bold mb-5">Parent Onboarding Form</h1>
            {/* Father Section */}
            <div className="space-y-10">
                {/* Father Section */}
                <div className="space-y-10">
                    <div className="bg-white p-5 rounded-lg shadow">
                        <h2 className="text-lg font-semibold mb-4">Father's Details</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
                            {["firstName", "middleName", "lastName", "email", "occupation", "dateOfBirth"].map(
                                (field) => (
                                    <div key={field} className="flex flex-col">
                                        <label className="font-medium capitalize">
                                            {field.replace(/([A-Z])/g, " $1")}
                                        </label>
                                        <input
                                            type={field === "dateOfBirth" ? "date" : "text"}
                                            value={formData.father[field]}
                                            onChange={(e) =>
                                                handleInputChange("father", field, e.target.value)
                                            }
                                            className="w-full border border-gray-300 rounded-lg p-2"
                                            placeholder={`Enter ${field}`}
                                        />
                                    </div>
                                )
                            )}
                            <div className="h-[56px]">
                                <label>
                                    Mobile
                                </label>
                                <PhoneInput
                                    id="mobile"
                                    country={'in'}
                                    value={formData.father.mobile}
                                    onChange={(value) => setFormData({ ...formData, father: { ...formData.father, mobile: value } })}
                                    containerClass="w-full rounded-lg"
                                    inputStyle={{
                                        width: '100%',
                                        borderRadius: "10px",
                                        height: "100%",
                                        border: "2px solid #EBEDF2"
                                    }}
                                    inputClass="w-full py-4 lg:py-[14px] pr-30 text-black h-full text-[20px] rounded-lg border-2 border-[#EBEDF2] focus:outline-none"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label className="font-medium">Avatar</label>
                                <input
                                    type="file"
                                    onChange={(e) =>
                                        handleFileChange("fatherAvatar", e.target.files[0])
                                    }
                                    className="w-full border border-gray-300 rounded-lg p-2"
                                />
                                {errors.fatherAvatar && (
                                    <span className="text-red-500 text-sm">{errors.fatherAvatar}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Mother Section */}
                    <div className="bg-white p-5 rounded-lg shadow">
                        <h2 className="text-lg font-semibold mb-4">Mother's Details</h2>
                        <div className="grid  grid-cols-1 md:grid-cols-2  gap-4">
                            {["firstName", "middleName", "lastName", "email", "occupation", "dateOfBirth"].map(
                                (field) => (
                                    <div key={field} className="flex flex-col">
                                        <label className="font-medium capitalize">
                                            {field.replace(/([A-Z])/g, " $1")}
                                        </label>
                                        <input
                                            type={field === "dateOfBirth" ? "date" : "text"}
                                            value={formData.mother[field]}
                                            onChange={(e) =>
                                                handleInputChange("mother", field, e.target.value)
                                            }
                                            className="w-full border border-gray-300 rounded-lg p-2"
                                            placeholder={`Enter ${field}`}
                                        />
                                    </div>
                                )
                            )}
                            <div className="h-[56px]">
                                <label>
                                    Mobile
                                </label>
                                <PhoneInput
                                    id="mobile"
                                    country={'in'}
                                    value={formData.mother.mobile}
                                    onChange={(value) => setFormData({ ...formData, mother: { ...formData.mother, mobile: value } })}
                                    containerClass="w-full rounded-lg"
                                    inputStyle={{
                                        width: '100%',
                                        borderRadius: "10px",
                                        height: "100%",
                                        border: "2px solid #EBEDF2"
                                    }}
                                    inputClass="w-full py-4 lg:py-[14px] pr-30 text-black h-full text-[20px] rounded-lg border-2 border-[#EBEDF2] focus:outline-none"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label className="font-medium">Avatar</label>
                                <input
                                    type="file"
                                    onChange={(e) =>
                                        handleFileChange("motherAvatar", e.target.files[0])
                                    }
                                    className="w-full border border-gray-300 rounded-lg p-2"
                                />
                                {errors.motherAvatar && (
                                    <span className="text-red-500 text-sm">{errors.motherAvatar}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Guardian Section */}
                    <div className="bg-white p-5 rounded-lg shadow">

                        <div className="flex">
                            <button
                                className={`${selectedGurdians === 'parent' ? 'bg-blue-500 text-white' : 'bg-transparent text-black'
                                    } px-4 py-2 rounded-lg`}
                                onClick={() => handleAutoSetGurdians('parent')}
                            >
                                Parent
                            </button>
                            <button
                                className={`${selectedGurdians === 'mother' ? 'bg-blue-500 text-white' : 'bg-transparent text-black'
                                    } px-4 py-2 rounded-lg`}
                                onClick={() => handleAutoSetGurdians('mother')}
                            >
                                mother
                            </button>
                            <button
                                className={`${selectedGurdians === 'others' ? 'bg-blue-500 text-white' : 'bg-transparent text-black'
                                    } px-4 py-2 rounded-lg`}
                                onClick={() => handleAutoSetGurdians('others')}
                            >
                                Others
                            </button>
                        </div>
                        <h2 className="text-lg font-semibold mb-4">Guardian's Details</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
                            {[
                                "relations",
                                "firstName",
                                "middleName",
                                "lastName",
                                "email",

                                "address",
                                "occupation",
                                "dateOfBirth",
                            ].map((field) => (
                                <div key={field} className="flex flex-col">
                                    <label className="font-medium capitalize">
                                        {field.replace(/([A-Z])/g, " $1")}
                                    </label>
                                    <input
                                        type={field === "dateOfBirth" ? "date" : "text"}
                                        value={formData.guardian[field]}
                                        onChange={(e) =>
                                            handleInputChange("guardian", field, e.target.value)
                                        }
                                        className="w-full border border-gray-300 rounded-lg p-2"
                                        placeholder={`Enter ${field}`}
                                    />
                                </div>
                            ))}
                            <div className="h-[56px]">
                                <label>
                                    Mobile
                                </label>
                                <PhoneInput
                                    id="mobile"
                                    country={'in'}
                                    value={formData.guardian.mobile}
                                    onChange={(value) => setFormData({ ...formData, guardian: { ...formData.guardian, mobile: value } })}
                                    containerClass="w-full rounded-lg"
                                    inputStyle={{
                                        width: '100%',
                                        borderRadius: "10px",
                                        height: "100%",
                                        border: "2px solid #EBEDF2"
                                    }}
                                    inputClass="w-full py-4 lg:py-[14px] pr-30 text-black h-full text-[20px] rounded-lg border-2 border-[#EBEDF2] focus:outline-none"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label className="font-medium">Avatar</label>
                                <input
                                    type="file"
                                    onChange={(e) =>
                                        handleFileChange("guardianAvatar", e.target.files[0])
                                    }
                                    className="w-full border border-gray-300 rounded-lg p-2"
                                />
                                {errors.guardianAvatar && (
                                    <span className="text-red-500 text-sm">{errors.guardianAvatar}</span>
                                )}   </div>
                        </div>
                    </div>

                    {/* Students Section (Placeholder) */}
                    <div className="mb-5">
                        <label className="block text-lg font-medium text-gray-700 mb-2">
                            Select Class To Assign Students
                        </label>
                        <Select
                            options={classList}
                            value={selectedClass}
                            onChange={setSelectedClass}
                            placeholder="-- Select Class --"
                            isClearable
                        />
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow">
                        <h2 className="text-lg font-semibold mb-4">Students</h2>
                        <Select
                            key={formData.students}
                            styles={{ control: (base) => ({ ...base, borderColor: "#EBEDF2", height: "55px" }) }}
                            isMulti
                            components={animatedComponents}
                            options={studentList}
                            value={selectedStudents}
                            onChange={handleSelectionChange}
                            isClearable
                            isSearchable
                        />
                    </div>

                    <div className="w-full ">
                        <button
                            type="submit"
                            className="bg-blue-500 w-full text-white font-bold py-2 px-5 rounded-md hover:bg-blue-600"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? "Submitting..." : "Submit"}
                        </button>
                    </div>
                </div>
                {isModalOpen && (
                    <OnboardClipboardModel
                        codeId={isModalOpen.codeId}
                        password={isModalOpen.password}
                        email={isModalOpen.email}
                        onClose={handleClose}
                    />
                )}
            </div>
        </div>
    );
}
export default ParentOnboarded;
