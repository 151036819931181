import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Sidebar from "../../Component/Common/Sidebar";
import worldLogo from "../../Assests/Logos/world.png"
import { IoMenuOutline } from "react-icons/io5";
import avtar from "../../Assests/Logos/avtar.png"
import { SuperAdminSidebarItems } from "../../data/SidebarItem";
const SuperAdminDashboard = () => {
    const [user, setUser] = useState();
    const [logo, setLogo] = useState("");
    const [academicsYear, setAcademicsYear] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(false); // State to toggle sidebar visibility

    useEffect(() => {
        const logo = localStorage.getItem("logo") ? localStorage.getItem("logo") : worldLogo;
        const user = JSON.parse(localStorage.getItem("user"));
        const academics = JSON.parse(localStorage.getItem("academicYear"));
        setLogo(logo);
        setUser(user);
        setAcademicsYear(academics || []);
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen); // Toggle the sidebar visibility
    };

    const closeSidebar = () => {
        setSidebarOpen(false); // Close the sidebar when overlay is clicked
    };

    return (
        <div className="w-full h-[100vh] overflow-y-hidden md:h-screen grid grid-cols-12 relative">
            {sidebarOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40 cursor-pointer"
                    onClick={closeSidebar} // Close sidebar when overlay is clicked
                />
            )}
            <aside
                className={`col-span-12 sm:col-span-3 lg:col-span-2 bg-[#FFFF] h-[100vh] md:h-auto  fixed top-0 left-0 z-50 transform transition-all duration-300 ${sidebarOpen ? "translate-x-0" : "-translate-x-full"
                    } sm:static sm:translate-x-0`}
            >
                <div className="w-full h-[50px] flex justify-center items-center my-2">
                    <Link to={"/"}>
                        <img src={logo} className="w-[80px] h-[70px]" />
                    </Link>
                </div>
                <Sidebar Sidebar={SuperAdminSidebarItems} />
            </aside>
            <main className="col-span-12 sm:col-span-9 lg:col-span-10 flex flex-col">
                {/* Navbar - Responsive */}
                <nav className="h-[70px] w-full bg-[#FFFF] flex justify-between px-2 sm:px-6 md:px-10 font-poppins">
                    <div className="h-full hidden w-[180px] md:flex items-center justify-between">
                        {/* Menu Icon */}
                        <IoMenuOutline
                            className="w-[30px] hidden bg-red-600 h-[30px] sm:w-[40px] sm:h-[40px] cursor-pointer"
                            onClick={toggleSidebar}
                        />

                    </div>
                    <div className="h-full md:hidden w-full flex items-center justify-around">
                        <IoMenuOutline
                            className="w-[30px]  h-[30px] sm:w-[40px] sm:h-[40px] cursor-pointer"
                            onClick={toggleSidebar} // Toggle sidebar visibility on click
                        />
                        <p className="text-text_gray-100 text-[0.7rem] sm:text-[0.9rem] font-semibold">
                            Session Years: {academicsYear[1]?.year}
                        </p>
                        <div className="flex gap-3 items-center">
                            <img src={logo} className="h-[40px] sm:h-[50px] w-[40px] sm:w-[50px]" />
                            <div className="h-full flex flex-col justify-start items-center">
                                <p className="text-[0.79rem] sm:text-[0.9rem] text-[#000] font-semibold">
                                    {user?.name}
                                </p>
                                <p className="text-[0.6rem] sm:text-[0.7rem] text-[#000]">{user?.superAdminId}</p>
                            </div>
                        </div>

                    </div>
                    <div className="flex-1 hidden sm:flex justify-between items-center">
                        <p className="text-text_gray-100 text-[0.7rem] sm:text-[0.9rem] font-semibold">
                            Session Years: {academicsYear[1]?.year}
                        </p>
                        <div className="flex gap-3 items-center">
                            <img src={logo} className="h-[40px] sm:h-[50px] w-[40px] sm:w-[50px]" />
                            <div className="h-full flex flex-col justify-start items-center">
                                <p className="text-[0.79rem] sm:text-[0.9rem] text-[#000] font-semibold">
                                    {user?.name}
                                </p>
                                <p className="text-[0.6rem] sm:text-[0.7rem] text-[#000]">{user?.superAdminId}</p>
                            </div>
                        </div>
                    </div>
                </nav>
                <section
                    className="bg-text_gray-75 w-full h-[90vh] md:h-[90vh] sm:mt-0 p-1 md:p-5 sm:h-[80vh] xsm:mt-2 overflow-y-scroll"
                    style={{
                        scrollbarWidth: "none", // For Firefox
                        msOverflowStyle: "none", // For Internet Explorer and Edge
                    }}
                >
                    {/* Inline style for Chrome, Safari, and Edge */}
                    <style>
                        {`
                    section::-webkit-scrollbar {
                      display: none;
                    }
                  `}
                    </style>
                    <Outlet />
                </section>
            </main>
        </div>


    )
}

export default SuperAdminDashboard