import React, { useEffect, useState } from "react";
import { getDueAssignment, deleteAParticularAssignment } from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import toast from "react-hot-toast";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';


const DueAssignment = () => {
    const [allDueAssignment, setAllDueAssignment] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null);
    };
    const handleOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
    };

    const fetchDueAssignment = async () => {
        setLoading(true);
        try {
            const response = await getDueAssignment();
            if(response.status===200){
                setAllDueAssignment(response?.data?.assignments || []);
            }
        } catch (error) {
            console.error("Error fetching due assignments:", error);
            setAllDueAssignment([])
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDueAssignment();
    }, []);

    const rows = allDueAssignment.map((assignment) => ({
        id: assignment._id,
        title: assignment.title,
        subject: assignment.subject,
        dueDate: new Date(assignment.dueDate).toLocaleDateString(),
        dueTime: assignment.dueTime,
        instructions: assignment.instructions,
        className: assignment.className,
        section: assignment.section.join(", "),
    }));

    const handleEdit = (assignmentId) => {
        navigate(`/teacher/dashboard/edit-assignment/${assignmentId}`); // Navigate to the edit route with assignmentId
    };


    const handleDelete = async () => {
        try {
            const response = await deleteAParticularAssignment(selectedId);
            if (response.status === 200) {
                toast.success("Deleted successfully");
                fetchDueAssignment()
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            toast.error(error.response?.data?.message || "Error deleting record");
        } finally {
            handleClose();
        }
    };
    return (
        <div className="font-poppins">
            <Headingtitle title="Due Assignment" />
            <main className="bg-white h-auto rounded-md p-5">
                <div className="min-w-full h-[80vh] overflow-auto">
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <table className="min-w-full border border-gray-200 rounded-sm table-auto border-collapse">
                            {/* Table Header */}
                            <thead>
                                <tr className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-sm uppercase font-semibold">
                                    <th className="px-4 py-2">Assignment Title</th>
                                    <th className="px-4 py-2">Subject</th>
                                    <th className="px-4 py-2">Due Date</th>
                                    <th className="px-4 py-2">Due Time</th>
                                    <th className="px-4 py-2">Instructions</th>
                                    <th className="px-4 py-2">Class</th>
                                    <th className="px-4 py-2">Section(s)</th>
                                    <th className="px-4 py-2">Actions</th>
                                </tr>
                            </thead>
                            {/* Table Body */}
                            <tbody>
                                {rows !== null ? (rows.map((row) => (
                                    <tr key={row.id} className="text-sm text-gray-800">
                                        <td className="px-4 py-2">{row.title}</td>
                                        <td className="px-4 py-2">{row.subject}</td>
                                        <td className="px-4 py-2">{row.dueDate}</td>
                                        <td className="px-4 py-2">{row.dueTime}</td>
                                        <td className="px-4 py-2">{row.instructions}</td>
                                        <td className="px-4 py-2">{row.className}</td>
                                        <td className="px-4 py-2">{row.section}</td>
                                        <td className="px-4 py-2">
                                            <button
                                                onClick={() => handleEdit(row.id)}
                                                className="text-indigo-600 hover:text-indigo-900"
                                            >
                                                Edit
                                            </button>

                                            <DeleteIcon
                                                onClick={()=>handleOpen(row.id)} />

                                        </td>
                                    </tr>
                                ))) : (<p>Data Not Found</p>)}
                            </tbody>
                        </table>
                    )}
                </div>
            </main>
              {/* Confirmation Dialog */}
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this record? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DueAssignment;
