import React, { useState, useEffect, useTransition } from 'react';
import { myFeeNotification } from '../../../Services/StudentApis';
import FeesTableComponent from '../../Common/FeesTableComponent';
import Headingtitle from '../../Common/Headingtitle';

const MyFees = () => {
    const [isPending, startTransition] = useTransition();
    const [notifications, setNotifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    useEffect(() => {
        startTransition(() => {
            myFeeNotification()
                .then((response) => {
                    setNotifications(response?.data?.notifications || []);
                })
                .catch((error) => {
                    console.error("Error fetching notifications:", error);
                });
        });
    }, []);

    const handleViewClick = (notification) => {
        setSelectedNotification(notification);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedNotification(null);
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Fee " />
            <div className="min-w-full bg-white rounded-lg min-h-[80vh] font-poppins p-3">

                <FeesTableComponent
                    isPending={isPending}
                    handleCloseModal={handleCloseModal}
                    notifications={notifications}
                    isModalOpen={isModalOpen}
                    selectedNotification={selectedNotification}
                    handleViewClick={handleViewClick}
                />
            </div>
        </div>
    );
};

export default MyFees;
