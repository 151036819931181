import React, { useEffect, useState } from 'react';
import { getAllClassCategoryList, getAllSectionList, getAllClassList, getSubjectListofClassById } from '../../../Services/TeachersApis';
import Headingtitle from '../../Common/Headingtitle';
import { Link } from 'react-router-dom';

const ClassSubjectList = () => {
    const [schoolId, setSchoolId] = useState('');
    const [sectionList, setSectionList] = useState([]);
    const [classCategoryList, setClassCategoryList] = useState([]);
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [subjectList, setSubjectList] = useState([]);


    const fetchMyClassData = async () => {
        try {
            const sectionResponse = await getAllSectionList();
            const myClassResponse = await getAllClassList();

            const classCategoryResponse = await getAllClassCategoryList();

            if (sectionResponse.status === 200 && classCategoryResponse.status === 200 && myClassResponse.status === 200) {
                setSectionList(sectionResponse?.data?.sections || []);
                setSchoolId(myClassResponse?.data?.classesDetails?.school)
                setClassCategoryList(classCategoryResponse?.data?.classCategories || []);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (selectedCategory && selectedSection) {
                try {
                    const response = await getSubjectListofClassById(schoolId, selectedCategory, selectedSection);
                    if (response.status === 200) {
                        setSubjectList(response.data || []);
                    }
                } catch (error) {
                    console.error('Error fetching subject list:', error);
                }
            }
        };

        fetchData();
    }, [schoolId, selectedCategory, selectedSection]);

    // Initial fetch of class and section data
    useEffect(() => {
        fetchMyClassData();
    }, []);

    return (
        <div className="font-poppins">
            <Headingtitle title="Class Subject" />
            <main className="bg-white rounded-md p-6 text-gray-800 relative min-h-[80vh] shadow-lg">
                <p className="font-semibold text-[1.25rem] pb-4  border-b border-gray-300">
                    Subject List
                </p>

                {/* Dropdowns for Class and Section */}
                <div className="grid w-full grid-cols-12 gap-5 grid-rows-2 text-[0.85rem] mb-6">
                    {/* Class Category Dropdown */}
                    <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="col-span-6 border rounded-md p-3 bg-gray-50 shadow-sm "
                    >
                        <option value="">Select Class Name</option>
                        {classCategoryList.length > 0 &&
                            classCategoryList.map((data) => (
                                <option key={data._id} value={data.className}>
                                    {data.className}
                                </option>
                            ))}
                    </select>

                    {/* Section Dropdown */}
                    <select
                        value={selectedSection}
                        onChange={(e) => setSelectedSection(e.target.value)}
                        className="col-span-6 border rounded-md p-3 bg-gray-50 shadow-sm "
                    >
                        <option value="">Select Section</option>
                        {sectionList.length > 0 &&
                            sectionList.map((data) => (
                                <option key={data._id} value={data.classSection}>
                                    {data.classSection}
                                </option>
                            ))}
                    </select>
                </div>

                {/* Subject List Table */}
                <div className="mt-6">
                    <h3 className="font-semibold text-[1.1rem] mb-4 ">
                        Subjects:
                    </h3>
                    <table className="w-full border-collapse rounded-md overflow-hidden shadow-md">
                        <thead>
                            <tr className="bg-gradient-to-r from-blue-200 via-blue-300 to-blue-400  font-medium">
                                <th className="border border-gray-300 p-3">Subject Name</th>
                                <th className="border border-gray-300 p-3">Class Name</th>
                                <th className="border border-gray-300 p-3">Section</th>
                                <th className="border border-gray-300 p-3">Action</th>
                                <th className="border border-gray-300 p-3">Study Material</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white text-gray-600">
                            {subjectList.length > 0 ? (
                                subjectList?.map((subject) => (
                                    <tr
                                        key={subject._id}
                                        className="hover:bg-gray-50 transition duration-200 text-center"
                                    >
                                        <Link to={`/teacher/dashboard/subject-overview/${subject._id}`}>
                                            <td className="p-3 text-indigo-500 font-semibold cursor-pointer">
                                                {subject.subjectName}
                                            </td>
                                        </Link>
                                        <td className="border border-gray-300 p-3">{subject.className}</td>
                                        <td className="border border-gray-300 p-3">
                                            <p className="bg-gray-200 p-2 font-bold rounded-md shadow-sm inline-block">
                                                {Array.isArray(subject.sections)
                                                    ? subject.sections.join(", ")
                                                    : subject.sections}
                                            </p>
                                        </td>
                                        <Link to={`/teacher/dashboard/subject-overview/${subject._id}`}>

                                            <td className="p-3 font-semibold cursor-pointer">
                                                View
                                            </td>
                                        </Link>


                                        <td className="p-3 font-semibold cursor-pointer">
                                            <Link to={`/teacher/dashboard/study-material-overview/${subject._id}`}>
                                                View
                                            </Link>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="3"
                                        className="p-5 text-center text-gray-500 font-semibold"
                                    >
                                        No subjects found for the selected class and section.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </main>
        </div>
    );
};

export default ClassSubjectList;
