import React, { useState } from "react";
import { TextField, Button, Typography, Box, Alert } from "@mui/material";
import { baseUrl } from "../../utils/baseUrl";
import toast from 'react-hot-toast';
import store from "../../Redux/store/store";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
    const navigate=useNavigate();
    const [formData, setFormData] = useState({
        oldPassword: "",
        newPassword: "",
    });
    const { token } = store.getState().auth;
    const role = window.localStorage.getItem("userRole");
    const [message, setMessage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.oldPassword === formData.newPassword) {
            setMessage({ type: "error", text: "New password cannot be the same as the old password." });
        } else {
            setMessage({ type: "success", text: "Password reset successfully!" });
            let apiUrl=`${baseUrl}/${role.toLowerCase()}/reset-password`
            let payload={
                oldPassword: formData.oldPassword,
                newPassword: formData.newPassword,
            }
            try {
                const response = await fetch(apiUrl, {
                    method: 'PATCH',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                     },
                    body: JSON.stringify(payload),
                });
                const result = await response.json();
                if (response.ok) {
                    console.log("response", response)
                    console.log("result", result)
                    toast.success(result?.message || 'Password reset successfully');
                    navigate(`/${role.toLowerCase()}/dashboard`)
                }
            } catch (error) {
                toast.error(error?.response?.data?.message || 'Failed to fetch attendance data');
            }
        }
    };

    return (
        <Box sx={{ maxWidth: 400, mx: "auto", mt: 4, p: 2, border: "1px solid #ddd", borderRadius: 2 }}>
            <Typography variant="h5" align="center" gutterBottom>
                Reset Password
            </Typography>

            <Alert severity="info" sx={{ mb: 2 }}>
                Ensure your new password is at least 8 characters long, contains a mix of uppercase, lowercase, numbers, and special characters.
            </Alert>

            <Alert severity="warning" sx={{ mb: 2 }}>
                Avoid reusing your old passwords or passwords that are easy to guess.
            </Alert>

            <Alert severity="success" sx={{ mb: 2 }}>
                Always keep your password confidential and do not share it with anyone.
            </Alert>

            <form onSubmit={handleSubmit}>
                <TextField
                    label="Old Password"
                    name="oldPassword"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={formData.oldPassword}
                    onChange={handleChange}
                    required
                />

                <TextField
                    label="New Password"
                    name="newPassword"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={formData.newPassword}
                    onChange={handleChange}
                    required
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Reset Password
                </Button>
            </form>

            {message && (
                <Alert severity={message.type} sx={{ mt: 2 }}>
                    {message.text}
                </Alert>
            )}
        </Box>
    );
};

export default ResetPassword;
