export const formatDateOfBirth = (dateOfBirth) => {
  const date = new Date(dateOfBirth);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const ordinal = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${ordinal(day)} ${month} ${year}`;
};

export const formatName = (userName) => {
  if (!userName || !userName.name) return "Ms. Unknown";

  const { firstName, middleName, lastName } = userName.name;

  return (
    `${firstName || ""} ${middleName || ""} ${lastName || ""}`.trim() ||
    "Ms. Unknown"
  );
};

export const formatGuardianInfo = (guardian, field, fallback = "N/A") =>
  guardian?.[field] || fallback;

export const getCurrentAcademicSession = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  // Academic year starts in April
  if (currentMonth >= 4) {
    return `${currentYear}-${currentYear + 1}`;
  } else {
    return `${currentYear - 1}-${currentYear}`;
  }
};

export const formatDateTime=(dateString)=> {
  const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
  };
  return new Date(dateString).toLocaleString('en-GB', options);
}

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
