import React, { useState } from 'react';
import { FaIdCard, FaKey } from "react-icons/fa";
import { Tabs, Tab, TextField, Button, Box, Grid, Typography, Container } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Make sure to import the CSS
import { baseUrl } from '../../utils/baseUrl';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import LoginLogo from "../../Assests/Logos/world.png"; // Add your logo here

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [selectedRole, setSelectedRole] = useState('student');
    const [formData, setFormData] = useState({
        mobile: '',
        studentId: '',
        otp: '',
        orderId: '',
        newPassword: '',
    });
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);

    const handleRoleChange = (event, newValue) => {
        setSelectedRole(newValue);
        setFormData({ mobile: '', studentId: '', otp: '', orderId: '', newPassword: '' });
        setIsOtpSent(false);
        setIsOtpVerified(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleForgotPassword = async () => {
        let apiUrl;
        let payload;


        switch (selectedRole) {
            case 'student':
                apiUrl = `${baseUrl}/student/send-forgot-password-otp`;
                payload = {
                    mobile: formData.mobile,
                    studentId: formData.studentId,
                };
                break;

            case 'parent':
                apiUrl = `${baseUrl}/parent/send-forgot-password-otp`;
                payload = { mobile: formData.mobile };
                break;

            case 'teacher':
                apiUrl = `${baseUrl}/teacher/send-forgot-password-otp`;
                payload = { mobile: formData.mobile };
                break;

            default:
                return;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
            const result = await response.json();
            if (response.ok) {
                toast.success(result?.message || 'OTP sent successfully');
                const orderId = result?.data?.orderId;
                setFormData((prev) => ({ ...prev, orderId }));
                setIsOtpSent(true);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Failed to send OTP');
        }
    };

    const handleVerifyOtp = async () => {
        let apiUrl;
        let payload;

        switch (selectedRole) {
            case 'student':
                apiUrl = `${baseUrl}/student/verify-forgot-password-otp`;
                payload = {
                    mobile: formData.mobile,
                    otp: formData.otp,
                    studentId: formData.studentId,
                    orderId: formData.orderId,
                };
                break;

            case 'parent':
            case 'teacher':
                apiUrl = `${baseUrl}/${selectedRole}/verify-forgot-password-otp`;
                payload = {
                    mobile: formData.mobile,
                    otp: formData.otp,
                    orderId: formData.orderId,
                };
                break;

            default:
                return;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
            const result = await response.json();
            if (response.ok) {
                toast.success(result?.message || 'OTP verified successfully');
                setIsOtpVerified(true);
            }
        } catch (error) {
            toast.error(error?.message || 'Failed to verify OTP');
        }
    };

    const handleCreateNewPassword = async () => {
        let apiUrl;
        let payload;

        switch (selectedRole) {
            case 'student':
                apiUrl = `${baseUrl}/student/create-new-password`;
                payload = {
                    mobile: formData.mobile,
                    studentId: formData.studentId,
                    newPassword: formData.newPassword,
                };
                break;

            case 'parent':
            case 'teacher':
                apiUrl = `${baseUrl}/${selectedRole}/create-new-password`;
                payload = {
                    mobile: formData.mobile,
                    newPassword: formData.newPassword,
                };
                break;

            default:
                return;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
            const result = await response.json();
            if (response.ok) {
                toast.success(result?.message || 'Password changed successfully');
                setFormData({ mobile: '', studentId: '', otp: '', orderId: '', newPassword: '' });
                setIsOtpSent(false);
                setIsOtpVerified(false);
                navigate("/");
            }
        } catch (error) {
            toast.error(error?.message || 'Failed to create new password');
        }
    };

    return (
        <section className="min-h-screen font-poppins flex items-center justify-center md:bg-gray-50">
            <div
                style={{ height: "80vh" }}
                className="bg-white md:w-[50vw] w-[90vw] max-w-md md:border md:border-gray-200 md:shadow-lg rounded-lg p-6 flex flex-col justify-evenly">
                <img src={LoginLogo} alt="Logo" className="h-12 mx-auto mb-4" />

                {!isOtpSent && (
                    <Tabs
                        value={selectedRole}
                        onChange={handleRoleChange}
                        centered
                        sx={{
                            "& .MuiTab-root": {
                                color: "#4B5563", // Text color
                                fontWeight: "600", // Equivalent to font-semibold
                                fontSize: "14px", // Text size
                                fontFamily: "'Poppins', sans-serif", // Font family
                                textTransform: "none", // Prevent uppercase transformation
                            },
                            "& .Mui-selected": {
                                color: "#1D4ED8", // Optional: Highlighted color for the selected tab
                            },
                        }}
                    >
                        <Tab label="Student" value="student" />
                        <Tab label="Parent" value="parent" />
                        <Tab label="Teacher" value="teacher" />
                    </Tabs>
                )}

                <Box sx={{ mt: 3 }}>
                    {!isOtpSent ? (
                        <>
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{
                                    fontSize: "1.5rem", // Equivalent to text-2xl
                                    fontWeight: "600",  // Equivalent to font-semibold
                                }}
                            >
                                Forgot Password
                            </Typography>

                            <Box sx={{ position: "relative", height: "80px" }}>
                                <label
                                    htmlFor="mobile"
                                    style={{
                                        fontFamily: "Poppins, Arial, sans-serif",
                                        color: "#000",
                                        display: "block",
                                        marginBottom: "4px",
                                        fontSize: "0.875rem", // Tailwind's `text-sm`
                                        fontWeight: "500",    // Tailwind's `font-medium`
                                    }}
                                >
                                    Mobile Number
                                </label>
                                <PhoneInput
                                    country={"in"}
                                    value={formData.mobile}
                                    onChange={(value) => setFormData({ ...formData, mobile: value })}
                                    inputStyle={{
                                        width: "100%",
                                        borderRadius: "999px",
                                        paddingLeft: "50px",
                                        border: "1px solid #ccc",
                                        transition: "border-color 0.3s",
                                    }}
                                    containerStyle={{
                                        marginTop: "20px",
                                    }}
                                    inputClass="custom-phone-input"
                                // prefix="+91 " // Adds the country code as a prefix
                                // disableDropdown={false} // Disables dropdown if country code should be fixed
                                />
                                <style jsx>{`
        .custom-phone-input:focus {
          outline: none;
        //   paddingleft:"50px"
          border-color: #007bff; /* Blue border color on focus */
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Optional shadow effect */
        }
      `}</style>
                            </Box>

                            {selectedRole === "student" && (
                                <Box sx={{ position: "relative", mb: 4, height: "80px" }}>
                                    <label
                                        htmlFor="studentId"
                                        style={{
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#000",
                                            display: "block",
                                            marginBottom: "4px",
                                            fontSize: "0.875rem",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Student ID
                                    </label>
                                    <FaIdCard
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "15px",
                                            transform: "translateY(-50%)",
                                            color: "gray",
                                        }}
                                    />
                                    <TextField
                                        id="studentId"
                                        name="studentId"
                                        value={formData.studentId}
                                        onChange={handleChange}
                                        placeholder="Email or Mobile No."
                                        fullWidth
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "999px", // Tailwind's `rounded-full`
                                                paddingLeft: "40px",  // Adjust to create space for icon
                                                // paddingTop: "0.5rem", // Tailwind's `py-2` equivalent
                                                // paddingBottom: "0.5rem", // Tailwind's `py-2` equivalent
                                            },
                                            "& input": {
                                                padding: "8px ", // Tailwind's `p-2`
                                            },
                                        }}
                                    />
                                </Box>
                            )}

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleForgotPassword}
                                fullWidth
                                sx={{
                                    mt: 2,
                                    borderRadius: "999px", // Tailwind's `rounded-full`
                                    fontFamily: "'Poppins', sans-serif", // Poppins font family for the button text
                                    fontWeight: "600",  // Semi-bold font weight
                                    fontSize: "0.9rem",  // Slightly larger font size for the button
                                    textTransform: "none",  // Remove text transformation (capitalize, etc.)
                                    padding: "10px",  // Add some padding for better aesthetics
                                }}
                            >
                                Send OTP
                            </Button>
                        </>
                    ) : isOtpVerified ? (
                        <>
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{
                                    fontSize: "1.5rem", // Equivalent to text-2xl
                                    fontWeight: "600",  // Equivalent to font-semibold
                                }}
                            >
                                Create New Password
                            </Typography>

                            <Box sx={{ position: "relative", }}>
                                <label
                                    htmlFor="newPassword"
                                    style={{
                                        fontFamily: "Poppins, Arial, sans-serif",
                                        color: "#000",
                                        display: "block",
                                        marginBottom: "4px",
                                        fontSize: "0.875rem",
                                        fontWeight: "500",
                                    }}
                                >
                                    New Password
                                </label>
                                <FaKey
                                    style={{
                                        position: "absolute",
                                        top: "70%",
                                        left: "15px",
                                        transform: "translateY(-50%)",
                                        color: "gray",
                                    }}
                                />
                                <TextField
                                    name="newPassword"
                                    value={formData.newPassword}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "999px", // Tailwind's `rounded-full`
                                            paddingLeft: "40px",  // Adjust to create space for icon
                                        },
                                        "& input": {
                                            padding: "8px 12px", // Tailwind's `p-2`
                                            fontSize: "0.9rem",  // Slightly larger font size
                                            fontWeight: "600",   // Semi-bold font weight
                                            fontFamily: "'Poppins', sans-serif",  // Poppins font family for the input text
                                        },
                                    }}
                                />
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCreateNewPassword}
                                fullWidth
                                sx={{
                                    mt: 2,
                                    borderRadius: "999px", // Tailwind's `rounded-full`
                                    fontFamily: "'Poppins', sans-serif", // Poppins font family for the button text
                                    fontWeight: "600",  // Semi-bold font weight
                                    fontSize: "0.9rem",  // Slightly larger font size for the button
                                    textTransform: "none",  // Remove text transformation (capitalize, etc.)
                                    padding: "10px",  // Add some padding for better aesthetics
                                }}
                            >
                                Create New Password
                            </Button>

                        </>
                    ) : (<>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                fontSize: "1.5rem", // Equivalent to text-2xl
                                fontWeight: "600",  // Equivalent to font-semibold
                            }}
                        >
                            Verify OTP
                        </Typography>
                        <>
                            <TextField
                                label="Enter OTP"
                                name="otp"
                                value={formData.otp}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleVerifyOtp}
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                Submit
                            </Button>
                        </>

                    </>
                    )}
                </Box>
            </div>
        </section>
    );
};

export default ForgotPassword;
