import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Headingtitle from '../../Common/Headingtitle';
import Select from "react-select";

import { getAllClassIdList, getAllStudentList, GetOnboardedStudentList, deleteAStudent } from '../../../Services/AdminApis';
import EditStudentModal from '../../../Layout/Models/EditStudentModal';

import DeleteIcon from '@mui/icons-material/Delete';
import toast from "react-hot-toast";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const GuradiansStudentList = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedClass, setSelectedClass] = useState(null);
  const [classList, setClassList] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState(null)
  const [open, setOpen] = useState(false);

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const fetchStudents = async () => {
    try {
      const response = await GetOnboardedStudentList(selectedClass.value);
      const studentData = response.data?.students;

      // Transform API data into the format required by DataGrid rows
      const formattedRows = studentData.map((student, index) => ({
        id: index + 1,
        firstName: student.name.firstName,
        middleName: student.name.middleName || 'N/A',
        lastName: student.name.lastName,
        gender: student.gender,
        email: student.email,
        mobile: student.contactInfo.mobile,
        address: student.contactInfo.address,
        academicYear: student.academicYear,
        admissionDate: new Date(student.admissionDate).toLocaleDateString(),
        className: `${student.class?.className}-${student.class?.classSection}` || 'N/A',
        class: student.class?._id || '',
        classSection: student.class?.classSection || 'N/A',
        rollNumber: student.rollNumber,
        studentId: student._id,
        guardianName: `${student.parent?.guardian?.firstName || 'N/A'} ${student.parent?.guardian?.lastName || ''}`,
        guardianEmail: student.parent?.guardian?.email || 'N/A',
        guardianMobile: student.parent?.guardian?.mobile || 'N/A',
        guardianAddress: student.parent?.guardian?.address || 'N/A',
        image: student.avatar,
      }));

      setRows(formattedRows);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching students:', error);
      setLoading(false);
    }
  };


  useEffect(() => {
    const fetchClassList = async () => {
      try {
        const classData = await getAllClassIdList();
        if (classData.status === 200) {
          const formattedClassList = classData.data?.classes.map(cls => ({
            value: cls._id,
            label: `${cls.className}-${cls.classSection}`
          }));
          console.log("formattedClassList", formattedClassList);
          setClassList(formattedClassList);
        }
      } catch (error) {
        console.error("Error fetching class list:", error);
      }
    };
    fetchClassList();
  }, []);

  const handleOpenDeleteModal = (id) => {
    setSelectedStudentId(id);
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
    setSelectedStudentId(null);
  };

  const handleDelete = async () => {
    try {
      // console.log("selectedStudentId",selectedStudentId)
      const response = await deleteAStudent(selectedStudentId);
      if (response.status === 200) {
        toast.success("Deleted successfully");
        fetchStudents();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to delete");
    } finally {
      handleCloseDeleteModal();
    }
  };

  const handleEditClick = (student) => {
    setSelectedStudent(student);
    setEditModalOpen(true);
  };


  const columns = [
    { field: 'id', headerName: 'No.', width: 70 },
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'middleName', headerName: 'Middle Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 100 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'address', headerName: 'Address', width: 300 },
    { field: 'academicYear', headerName: 'Academic Year', width: 150 },
    { field: 'admissionDate', headerName: 'Admission Date', width: 150 },
    { field: 'class', headerName: 'Class ', width: 100 },
    { field: 'classSection', headerName: 'Class Section', width: 100 },
    { field: 'rollNumber', headerName: 'Roll Number', width: 100 },
    { field: 'studentId', headerName: 'Student ID', width: 150 },
    { field: 'guardianName', headerName: 'Guardian Name', width: 200 },
    { field: 'guardianEmail', headerName: 'Guardian Email', width: 200 },
    { field: 'guardianMobile', headerName: 'Guardian Mobile', width: 150 },
    { field: 'guardianAddress', headerName: 'Guardian Address', width: 300 },
    {
      field: 'image', headerName: 'Image', width: 100, renderCell: (params) => (
        <Avatar src={params.value || 'https://via.placeholder.com/50'} alt="avatar" />
      )
    },
    {
      field: 'action', headerName: 'Action', width: 150, renderCell: (params) => (
        <div>
          <IconButton color="primary"
            onClick={() => handleOpenDeleteModal(params.row.studentId)}>
            <DeleteIcon />
          </IconButton>

          <EditIcon color="primary" sx={{ cursor: "pointer" }} onClick={() => handleEditClick(params.row)}>
            <EditIcon />
          </EditIcon>
        </div>

      )
    },
  ];


  useEffect(() => {
    fetchStudents();
  }, [selectedClass]);


 


  return (
    <div className="h-[80vh] w-full mt-5">
      <Headingtitle title="Student Lists" />
      <div className='p-4 shadow-md rounded-md bg-white'>

        <div className="mb-5">

          <p className="text-red-500 text-center font-semibold text-[0.9rem] italic">Please select a class to view the student list</p>
          <label className="block text-lg font-medium text-gray-700 mb-2">
            Select Class
          </label>
          <Select
            options={classList}
            value={selectedClass}
            onChange={setSelectedClass}
            placeholder="-- Select Class --"
            isClearable
          />
        </div>

        <DataGrid
          style={{ backgroundColor: 'white' }}
          rows={rows}
          columns={columns}
          loading={loading}
          pageSize={10}
          slots={{ toolbar: GridToolbar }}
          checkboxSelection
        />
      </div>
      {isEditModalOpen && (
        <EditStudentModal
          open={isEditModalOpen}
          handleClose={() => setEditModalOpen(false)}
          studentData={selectedStudent}
          refreshData={fetchStudents} // Function to refresh data
        />
      )}

      <Dialog open={open} onClose={handleCloseDeleteModal}>
        <DialogTitle>Delete Teacher</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this teacher permanently? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GuradiansStudentList;
