import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, Grid } from '@mui/material';
import { getAllClassIdList, updateParent, updateStudentData } from '../../Services/AdminApis';

const EditParentModal = ({ open, handleClose, parentData, refreshData }) => {
    const [formData, setFormData] = useState(parentData || {});
    const [classList, setClassList] = useState([]);
    const [parentInfo, setParentInfo] = useState(parentData?.father || {});
    const [motherInfo, setMotherInfo] = useState(parentData?.mother || {});
    const [guardianInfo, setGuardianInfo] = useState(parentData?.guardian || {});

    const [selectedClass, setSelectedClass] = useState({
        value: formData?.class || '',
        label: formData?.className || '',
    });
    console.log(formData)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleParentChange = (type, e) => {
        const { name, value } = e.target;
        if (type === 'father') {
            setParentInfo((prev) => ({ ...prev, [name]: value }));
        } else if (type === 'mother') {
            setMotherInfo((prev) => ({ ...prev, [name]: value }));
        } else if (type === 'guardian') {
            setGuardianInfo((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async () => {
        console.log(formData?.parentId)
        const payload = {
            father: parentInfo,
            mother: motherInfo,
            guardian: guardianInfo
        };

        try {
            const response = await updateParent(formData.parentId, payload);
            if (response.status === 200) {
                console.log(response);
                refreshData();
                handleClose();
            }
        } catch (error) {
            console.error('Error updating student data:', error);
        }
    };

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const classData = await getAllClassIdList();
                if (classData.status === 200) {
                    const formattedClassList = classData.data?.classes.map((cls) => ({
                        value: cls._id,
                        label: `${cls.className}-${cls.classSection}`,
                    }));
                    setClassList(formattedClassList);
                }
            } catch (error) {
                console.error('Error fetching class list:', error);
            }
        };
        fetchClassList();
    }, []);

    return (
        <Modal open={open}>
            <Box
                className="bg-white p-5 rounded-md w-[80vw] mx-auto mt-2 h-[90vh] overflow-y-scroll"
                sx={{ outline: 'none' }}
            >
                <h2 className="text-xl font-bold mb-4">Edit Parent Details</h2>
                <Grid container spacing={2} sx={{ display: "flex", }}>

                    <Grid item xs={6}>
                        <h3>Father's Information</h3>
                        {Object.keys(parentInfo).map((key, index) => {
                            if (key === "avatar") return (
                                <TextField
                                    fullWidth
                                    key={`father-${index}`}
                                    label={`Father ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                    name={key}
                                    value={parentInfo[key] || ''}
                                    onChange={(e) => handleParentChange('father', e)}
                                    disabled
                                    margin="normal"
                                />
                            )
                            else if (key === "dateOfBirth")
                                return (
                                    <TextField
                                        fullWidth
                                        type="date" // Input type for date
                                        key={`father-${index}`}
                                        label={`Father ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                        name={key}
                                        value={key === "dateOfBirth" ? new Date(parentInfo[key]).toISOString().split('T')[0] : parentInfo[key] || ''}
                                        onChange={(e) => handleParentChange('father', e)}
                                        margin="normal"
                                    />

                                )
                            return (
                                <TextField
                                    fullWidth
                                    key={`father-${index}`}
                                    label={`Father ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                    name={key}
                                    value={parentInfo[key] || ''}
                                    onChange={(e) => handleParentChange('father', e)}
                                    margin="normal"
                                />
                            );
                        })}
                    </Grid>


                    <Grid item xs={6}>
                        <h3>Mother's Information</h3>
                        {Object.keys(motherInfo).map((key, index) => {
                            if (key === "avatar") return (
                                <TextField
                                    fullWidth
                                    key={`mother-${index}`}
                                    label={`mother ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                    name={key}
                                    value={parentInfo[key] || ''}
                                    onChange={(e) => handleParentChange('mother', e)}
                                    disabled
                                    margin="normal"
                                />
                            )
                            else if (key === "dateOfBirth")
                                return (
                                    <TextField
                                        fullWidth
                                        type="date" // Input type for date
                                        key={`mother-${index}`}
                                        label={`mother ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                        name={key}
                                        value={key === "dateOfBirth" ? new Date(parentInfo[key]).toISOString().split('T')[0] : parentInfo[key] || ''}
                                        onChange={(e) => handleParentChange('mother', e)}
                                        margin="normal"
                                    />
                                )
                            return (
                                <TextField
                                    fullWidth
                                    key={`mother-${index}`}
                                    label={`Mother ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                    name={key}
                                    value={motherInfo[key] || ''}
                                    onChange={(e) => handleParentChange('mother', e)}
                                    margin="normal"
                                />)

                        })}
                    </Grid>

                    <Grid item xs={6}>
                        <h3>Guardian's Information</h3>
                        {Object.keys(guardianInfo).map((key, index) => {
                            if (key === "avatar") return (
                                <TextField
                                    fullWidth
                                    key={`guardian-${index}`}
                                    label={`guardian ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                    name={key}
                                    value={guardianInfo[key] || ''}
                                    onChange={(e) => handleParentChange('guardian', e)}
                                    disabled
                                    margin="normal"
                                />
                            )
                            else if (key === "dateOfBirth")
                                return (
                                    <TextField
                                        fullWidth
                                        type="date" // Input type for date
                                        key={`Gurdian-${index}`}
                                        label={`Gurdian ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                        name={key}
                                        value={key === "dateOfBirth" ? new Date(parentInfo[key]).toISOString().split('T')[0] : parentInfo[key] || ''}
                                        onChange={(e) => handleParentChange('guardian', e)}
                                        margin="normal"
                                    />

                                )

                            return (
                                <TextField
                                    fullWidth
                                    key={`guardian-${index}`}
                                    label={`Guardian ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                    name={key}
                                    value={guardianInfo[key] || ''}
                                    onChange={(e) => handleParentChange('guardian', e)}
                                    margin="normal"
                                />)
                        })}
                    </Grid>
                </Grid>

                <Box className="flex justify-end gap-4 mt-5">
                    <Button variant="contained" color="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditParentModal;
