import React, { useEffect, useState, useTransition } from 'react';
import { getAllUpComingHolidayList, editHolidayById, deleteHolidayById } from '../../../Services/AdminApis';
import Headingtitle from '../../Common/Headingtitle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { toast } from 'react-hot-toast';

const HolidaysList = () => {
    const [HolidayList, setHolidayList] = useState([]);
    const [isPending, startTransition] = useTransition(); 
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [updatedEvent, setUpdatedEvent] = useState({
        title: '',
        desc: '',
        targetAudience: '',
        targetClasses: '',
        expiryDate: '',
    });

    const fetchUpcomingHoliday = async () => {
        try {
            const response = await getAllUpComingHolidayList();
            startTransition(() => {
                setHolidayList(response?.data?.holidays || []);
            });
        } catch (error) {
            console.error("Error fetching Holidays:", error);
        }
    };
    useEffect(() => {
        fetchUpcomingHoliday();
    }, []);

    const handleEdit = (event) => {
        setSelectedEvent(event);
        setUpdatedEvent({
            title: event.title,
            desc: event.desc,
            targetAudience: event.targetAudience.join(', '),
            targetClasses: event.targetClasses.join(', '),
            expiryDate: new Date(event.expiryDate).toISOString().split('T')[0],
        });
        setOpenEditModal(true);
    };

    const handleDeleteConfirmation = (event) => {
        setSelectedEvent(event);
        setOpenDeleteModal(true);
    };

    const handleDelete = async () => {
        try {
            const response = await deleteHolidayById(selectedEvent._id);
            if (response.status === 200) {
                setOpenDeleteModal(false);
                toast.success("Deleted Successfully")
                fetchUpcomingHoliday()
            }
        } catch (error) {
            console.error("Error deleting event:", error);
            toast.error(error?.response?.data?.message || "Deleted Successfully")

        }
    };

    const handleEditSubmit = async () => {
        try {
            const updatedData = {
                ...updatedEvent,
                targetAudience: updatedEvent.targetAudience.split(',').map(a => a.trim()),
                targetClasses: updatedEvent.targetClasses.split(',').map(c => c.trim()),
            };
            await editHolidayById(selectedEvent._id, updatedData);
            setOpenEditModal(false);
            alert("Event updated successfully.");
        } catch (error) {
            console.error("Error updating event:", error);
            alert("Failed to update event. Please try again.");
        }
    };
    return (
        <div className="font-poppins">
            <Headingtitle title="Holiday" />
            <main className="bg-white shadow-md rounded-md p-5  ">
                <p className="font-semibold pb-2 text-[1rem] "> Holiday Lists</p>
                <div>

                    {isPending ? (
                        <p>Loading...</p>
                    ) : (
                        <div className="overflow-x-auto">
                            <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                                <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                                    <tr>
                                        <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                            Title
                                        </th>
                                        <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                            Description
                                        </th>
                                        <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                            Target Audience
                                        </th>
                                        <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                            Target Classes
                                        </th>
                                        <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                            Expiry Date
                                        </th>
                                        <th className="border border-gray-300 px-4 py-3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {HolidayList?.map((Holiday, index) => (
                                        <tr
                                            key={Holiday._id}
                                            className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                                } hover:bg-indigo-100 transition-all`}
                                        >
                                            <td className="border border-gray-300 px-4 py-2 text-gray-700 font-medium">
                                                {Holiday.name}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                                {Holiday.description}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                                {Holiday.applicableClasses.includes("All")
                                                    ? "All"
                                                    : "Specific Classes"}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                                {Holiday.applicableClasses.includes("All")
                                                    ? "All"
                                                    : Holiday.applicableClasses.join(", ")}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                                {new Date(Holiday.date).toLocaleDateString()}
                                            </td>
                                            <td className="gap-2 border px-4 py-2">
                                                {/* <button onClick={() => handleEdit(event)} className="text-blue-500 hover:text-blue-700">
                                                    <EditIcon />
                                                </button> */}
                                                <button onClick={() => handleDeleteConfirmation(Holiday)} className="text-red-500 hover:text-red-700">
                                                    <DeleteIcon />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                </div>
            </main>

            {/* Edit Modal */}
            <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)}>
                <DialogTitle>Edit Event</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Title"
                        fullWidth
                        margin="dense"
                        value={updatedEvent.title}
                        onChange={(e) => setUpdatedEvent({ ...updatedEvent, title: e.target.value })}
                    />
                    <TextField
                        label="Description"
                        fullWidth
                        margin="dense"
                        value={updatedEvent.desc}
                        onChange={(e) => setUpdatedEvent({ ...updatedEvent, desc: e.target.value })}
                    />
                    <TextField
                        label="Target Audience (comma-separated)"
                        fullWidth
                        margin="dense"
                        value={updatedEvent.targetAudience}
                        onChange={(e) => setUpdatedEvent({ ...updatedEvent, targetAudience: e.target.value })}
                    />
                    <TextField
                        label="Target Classes (comma-separated)"
                        fullWidth
                        margin="dense"
                        value={updatedEvent.targetClasses}
                        onChange={(e) => setUpdatedEvent({ ...updatedEvent, targetClasses: e.target.value })}
                    />
                    <TextField
                        label="Expiry Date"
                        type="date"
                        fullWidth
                        margin="dense"
                        value={updatedEvent.expiryDate}
                        onChange={(e) => setUpdatedEvent({ ...updatedEvent, expiryDate: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditModal(false)}>Cancel</Button>
                    <Button onClick={handleEditSubmit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Modal */}
            <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this Holiday?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default HolidaysList;
