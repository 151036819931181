import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Modal,
  Box, MenuItem, Select, InputLabel, FormControl,
  CircularProgress, TablePagination, Grid2
} from '@mui/material';
import { FaDollarSign, FaEye, FaExclamationCircle } from 'react-icons/fa'; // Import icons

import { getClassStudentsFee, getAllClassIdList, markFeeSubmitted } from '../../../Services/AdminApis';
import { months } from '../../../utils/utilityFunction';
import StudentFeeModal from './StudentFeeDetailsModal';
import Headingtitle from '../../Common/Headingtitle';
import BreakpointModal from '../../Common/BreakPointComp';

const StudentFeeTable = () => {
  const [feeHistory, setFeeHistory] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchParams, setSearchParams] = useState({ classId: '', month: '', dueDate: '' });
  const [openModal, setOpenModal] = useState(false);
  const [selectedFee, setSelectedFee] = useState(null);
  const [classList, setClassList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    amountPaid: '',
    paymentMethod: '',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedStudentFeeDetails, setSelectedStudentFeeDetails] = useState(null);

  const [isBreakpointModalOpen, setIsBreakpointModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const openBreakpointModal = (record) => {
    setSelectedRecord(record);
    setIsBreakpointModalOpen(true);
  };

  const closeBreakpointModal = () => {
    setIsBreakpointModalOpen(false);
    setSelectedRecord(null);
  };


  const openViewDetailsModal = (fee) => {
    setSelectedStudentFeeDetails(fee);
    setOpenViewModal(true);
  };

  const closeViewDetailsModal = () => {
    setOpenViewModal(false);
    setSelectedStudentFeeDetails(null);
  };

  useEffect(() => {
    const currentMonth = months[new Date().getMonth()];
    setSearchParams((prev) => ({ ...prev, month: currentMonth }));
    fetchFeeData({ month: currentMonth });
  }, []);

  useEffect(() => {
    fetchClass();
  }, []);

  const fetchClass = async () => {
    try {
      const response = await getAllClassIdList();
      setClassList(response?.data?.classes || []);
    } catch (error) {
      console.error('Error fetching class list:', error);
    }
  };

  const fetchFeeData = async (params = searchParams) => {
    setLoading(true);
    try {
      const response = await getClassStudentsFee(params);
      setFeeHistory(response.data.feeHistory);
      setFilteredData(response.data.feeHistory);
    } catch (error) {
      console.error('Error fetching fee data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchFeeData();
  };

  const handleSearchFilter = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    const filtered = feeHistory.map((record) => {
      // console.log("Processing Record:", record);
      const filteredStudentFees = record.studentFees.filter((studentFee) => {
        const studentName = `${studentFee.studentName.firstName} ${studentFee.studentName.middleName || ''} ${studentFee.studentName.lastName}`;
        // console.log("Full Student Name:", studentName); 
        const match = studentName.toLowerCase().includes(value);
        return match;
      });

      if (filteredStudentFees.length > 0) {
        return {
          ...record,
          studentFees: filteredStudentFees,
        };
      }
      return null;
    }).filter(record => record !== null);
    setFilteredData(filtered);
    setPage(0);
  };



  const openFeeModal = (fee) => {
    setSelectedFee(fee);
    setOpenModal(true);
  };

  const closeFeeModal = () => {
    setOpenModal(false);
    setSelectedFee(null);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const submitFee = async () => {
    const payload = {
      feeId: selectedFee._id,
      studentId: selectedFee.student,
      amountPaid: parseFloat(formValues.amountPaid),
      paymentMethod: formValues.paymentMethod,
    };
    try {
      const response = await markFeeSubmitted(payload);
      fetchFeeData();
      closeFeeModal();
    } catch (error) {
      console.error('Error submitting fee:', error);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const paginatedData = filteredData
    .flatMap((record) => record.studentFees.map((studentFee) => ({ ...studentFee, ...record })))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <div className="  font-poppins">
        <Headingtitle title="Search Filters" />
        <div className="grid grid-cols-1  rounded-lg shadow-md bg-white gap-4 p-4 md:grid-cols-12 place-content-end items-baseline">
          {/* Class Selection */}
          <div className="col-span-12 md:col-span-4">
            <div className="relative ">
              {/* <label htmlFor="classSelect" className="block px-3 pt-2 text-sm font-medium ">
                Class
              </label> */}
              <select
                id="classSelect"
                className="block w-full px-3 py-2 mt-1 text-gray-900 bg-white  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={searchParams.classId}
                onChange={(e) => setSearchParams({ ...searchParams, classId: e.target.value })}
              >
                <option value="">Select Class</option>
                {classList.map((classItem) => (
                  <option key={classItem._id} value={classItem._id}>
                    {classItem.className}-{classItem.classSection}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Month Selection */}
          <div className="col-span-12 md:col-span-3">
            <div className="relative ">
              {/* <label htmlFor="monthSelect" className="block px-3 pt-2 text-sm font-medium ">
                Month
              </label> */}
              <select
                id="monthSelect"
                className="block w-full px-3 py-2 mt-1 text-gray-900 bg-white  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={searchParams.month}
                onChange={(e) => setSearchParams({ ...searchParams, month: e.target.value })}
              >
                <option value="">Select Month</option>
                {months.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Due Date Input */}
          {/* <div className="col-span-12 md:col-span-3">
            <div className="relative ">
              <label htmlFor="dueDate" className="block px-3 pt-2 text-sm font-medium ">
                Due Date
              </label>
              <input
                id="dueDate"
                type="date"
                className="block w-full px-3 py-2 mt-1 text-gray-900 bg-white  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={searchParams.dueDate}
                onChange={(e) => setSearchParams({ ...searchParams, dueDate: e.target.value })}
              />
            </div>
          </div> */}

          <div className="col-span-12 md:col-span-2">
            <button
              onClick={handleSearch}
              className="w-full px-4 py-2 text-white bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Search
            </button>
          </div>
        </div>
        <div className='p-4 bg-white mt-6 rounded-lg shadow-lg'>
          <TextField
            label="Search Student"
            variant="outlined"
            fullWidth
            value={searchText}
            onChange={handleSearchFilter}
            style={{ margin: '20px 0' }}
          />

          <div className="min-w-full h-[80vh] overflow-auto">
            {loading ? (
              <div className="flex justify-center items-center h-full">
                <CircularProgress />
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                  <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                    <tr>
                      <th className="px-6 py-4 text-left text-[16px] font-bold">Student Name</th>
                      <th className="px-6 py-4 text-left text-[16px] font-bold">Fee Type</th>
                      <th className="px-6 py-4 text-left text-[16px] font-bold">Month</th>
                      <th className="px-6 py-4 text-left text-[16px] font-bold">Fee Amount</th>
                      <th className="px-6 py-4 text-left text-[16px] font-bold">Due Date</th>
                      <th className="px-6 py-4 text-left text-[16px] font-bold">Payment Status</th>
                      <th className="px-6 py-4 text-left text-[16px] font-bold">Breakpoint</th> {/* New column */}
                      <th className="px-6 py-4 text-left text-[16px] font-bold">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((record, index) => (
                      <tr key={index} className="text-center odd:bg-gray-100 even:bg-white hover:bg-gray-200 transition-colors duration-200">
                        <td className="px-4 py-3 font-medium text-base">
                          {`${record.studentName.firstName} ${record.studentName.middleName || ''} ${record.studentName.lastName}`}
                        </td>
                        <td className="px-6 py-4 text-gray-700 text-[15px]">{record.feeType}</td>
                        <td className="px-6 py-4 text-gray-700 text-[15px]">{record.month}</td>
                        <td className="px-6 py-4 text-gray-700 text-[15px]">{record.feeAmount}</td>
                        <td className="px-6 py-4 text-gray-700 text-[15px]">{new Date(record.dueDate).toLocaleDateString()}</td>
                        <td className="px-0 py-3">
                          <span className={`px-3 py-1 rounded-full text-sm font-semibold shadow-lg ${record.paymentStatus === 'Pending' ? 'bg-yellow-400 text-white' : record.paymentStatus === 'Paid' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                            {record.paymentStatus}
                          </span>
                        </td>
                        <td className="px-4 py-4 text-gray-700 text-[15px]">
                          <button
                            className="px-4 py-2 bg-blue-600 border text-[0.8rem]  rounded-md shadow hover:bg-blue-700 text-white transition-colors duration-200"
                            onClick={() => openBreakpointModal(record)}
                          >
                           Fees
                          </button>
                        </td>
                        <td className="px-6 py-4 flex justify-center items-center gap-2">
                          <p
                            className="px-4 py-2 cursor-pointer text-[0.8rem] bg-blue-600 text-white rounded-md shadow  hover:bg-blue-700 transition-colors duration-200"
                            onClick={() => openFeeModal(record)}
                          >
                            Pay Fee
                          </p>
                          <button
                            className="px-4 py-2 bg-gray-600 text-white text-[0.8rem] rounded-md shadow hover:bg-gray-700 transition-colors duration-200"
                            onClick={() => openViewDetailsModal(record)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>


            )}
          </div>


        </div>

        <Modal open={openModal} onClose={closeFeeModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: 400 }, // Responsive width
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: { xs: 2, sm: 4 }, // Responsive padding
              borderRadius: 2, // Rounded corners for better aesthetics
            }}
          >
            <h2 style={{ fontSize: '1.5rem', textAlign: 'center' }}>Submit Fee</h2>
            {selectedFee && (
              <>
                <TextField
                  label="Student Name"
                  value={`${selectedFee.studentName.firstName} ${selectedFee.studentName.middleName || ''} ${selectedFee.studentName.lastName}`}
                  disabled
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Amount Paid"
                  name="amountPaid"
                  value={formValues.amountPaid}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
                  type="number"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Payment Method</InputLabel>
                  <Select
                    name="paymentMethod"
                    value={formValues.paymentMethod}
                    onChange={handleFormChange}
                  >
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Card">Card</MenuItem>
                    <MenuItem value="Online">Online</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                  <Button variant="contained" color="primary" onClick={submitFee}>
                    Submit
                  </Button>
                  <Button variant="outlined" color="secondary" onClick={closeFeeModal}>
                    Cancel
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Modal>


        <StudentFeeModal
          open={openViewModal}
          onClose={closeViewDetailsModal}
          selectedStudentFeeDetails={selectedStudentFeeDetails}
        />
      </div>
      <BreakpointModal
        isOpen={isBreakpointModalOpen}
        record={selectedRecord}
        onClose={closeBreakpointModal}
      />
    </div>
  );
};

export default StudentFeeTable;
