import React, { useState } from 'react';
import Joyride from 'react-joyride';

const Tour = ({ role, onComplete }) => {
  const [run, setRun] = useState(true);

  const steps = {
    superAdmin: [
      { target: '.dashboard', content: 'This is your dashboard' },
      { target: '.manage-tenants', content: 'Manage all tenants here' },
    ],
    admin: [
      { target: '.academic', content: 'Add subject related to school here and Onboard new class with class and teacher' },
      { target: '.teacher', content: 'Onboard new teacher and view teacher list here' },
      { target: '.student', content: 'Onboard new student and view student list here' },
      { target: '.parent', content: 'Onboard new parent and view  parent list here' },
      {
        target: '.last-step',
        content: (
          <div>
            <p>You’ve completed the tour. Let’s get started!</p>
            <button onClick={() => onComplete()}>Got it</button>
          </div>
        ),
      },
    ],
    // Add steps for other roles
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === 'finished' || status === 'skipped') {
      setRun(false);
      onComplete(); 
    }
  };

  return (
    <Joyride
      steps={steps[role]}
      run={run}
      continuous
      showSkipButton
      callback={handleJoyrideCallback}
    />
  );
};

export default Tour;
