import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { IoEyeSharp } from "react-icons/io5";
import Modal from "@mui/material/Modal";
import toast from "react-hot-toast"
import { StudentAttendanceList, markClassAttendance, studentAttendanceListById } from "../../../Services/TeachersApis";
import { Link } from "react-router-dom";
import { PiListChecksFill } from "react-icons/pi";
import Headingtitle from "../../Common/Headingtitle";

const AttendanceList = () => {
    const [loading, setLoading] = useState(true);
    const [formattedRows, setFormattedRows] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [attendanceModalOpen, setAttendanceModalOpen] = useState(null);
    const [detailsModalOpen, setDetailsModalOpen] = useState(null);
    const [studentDetails, setStudentDetails] = useState(null);
    const [selectedDate, setSelectedDate] = useState(() => {
        const today = new Date();
        return today.toISOString().split("T")[0];
    });

    const classId = JSON.parse(localStorage.getItem("classId"));
    useEffect(() => {
        const fetchAttendanceList = async () => {
            try {
                const response = await StudentAttendanceList(classId, selectedDate);

                const attendanceData = response?.data?.attendance || {}; // Get the attendance object
                const attendanceRecords = attendanceData?.attendanceRecords || []; // Get attendanceRecords array

                const rows = attendanceRecords
                    .filter((record) => record?.student !== null) // Filter out records with null students
                    .map((record) => ({
                        id: record?._id,
                        studentClassId: record?.student?._id || "",// Unique ID of the record
                        studentId: record?.student?.studentId || "", // Student ID
                        studentName: `${record?.student?.name?.firstName || ""} ${record?.student?.name?.middleName || ""} ${record?.student?.name?.lastName || ""}`.trim(), // Full student name
                        status: record?.status || "", // Attendance status
                        classId: attendanceData?.class || "", // Class ID from the root attendance object
                        schoolId: attendanceData?.school || "", // School ID from the root attendance object
                        teacherId: attendanceData?.teacher || "", // Teacher ID from the root attendance object
                        attendanceDate: new Date(attendanceData?.date).toISOString().split('T')[0], // Convert date to YYYY-MM-DD
                    }));

                setFormattedRows(rows);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching attendance data:", error);
                setLoading(false);
            }


        };

        fetchAttendanceList();
    }, [selectedDate]);

    // Handle opening attendance modal
    const handleAttendanceClick = (row) => {
        setSelectedStudent(row);
        setAttendanceModalOpen(row);
    };

    // Handle updating attendance status
    const handleUpdateStatus = async (data, status) => {
        console.log(data, status)
        const payload = {
            classId: classId,
            studentId: data.studentClassId,
            date: selectedDate,
            status: status
        }
        console.log("payload", payload);
        try {
            const response = await markClassAttendance(payload);
            if (!response.status === 200) {
                toast.error("Error while Marked Attendance");
            } else {
                toast.success("succesFully Marked Attendance")
                setFormattedRows((prevRows) =>
                    prevRows.map((row) =>
                        row.id === selectedStudent.id ? { ...row, status } : row
                    )
                );
                setAttendanceModalOpen(null);
            }
        } catch (error) {
            console.error("Error updating attendance status:", error);
        }
    };

    // Handle date input changes
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    return (
        <div className=" font-poppins">
            <Headingtitle title="Attendance List" />

            {/* Date Input */}
            <section className=" bg-white rounded-lg shadow-lg p-4">

                <div className="mb-4">
                    <label
                        htmlFor="date"
                        className="block text-sm font-medium mb-2 text-center sm:text-left"
                    >
                        Select Date:
                    </label>
                    <input
                        id="date"
                        type="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        className="border border-gray-300 rounded px-4 py-2 w-full sm:w-auto"
                    />
                </div>

                {/* Render Attendance Data in Custom Table */}
                <div
                    className="overflow-auto rounded-lg shadow-md "

                >
                    {loading ? (
                        <p className="text-center">Loading...</p>
                    ) : formattedRows.length > 0 ? (
                        <table className="min-w-full text-sm border-collapse border border-gray-300">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="px-4 py-2 border border-gray-300 text-center">
                                        Student ID
                                    </th>
                                    <th className="px-4 py-2 border border-gray-300 text-center">
                                        Student Name
                                    </th>
                                    <th className="px-4 py-2 border border-gray-300 text-center">
                                        Status
                                    </th>
                                    <th className="px-4 py-2 border border-gray-300 text-center">
                                        see Attendances
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {formattedRows.map((row) => (
                                    <tr
                                        key={row.id}
                                        className="text-center odd:bg-slate-100 even:bg-white hover:bg-slate-200 rounded-lg shadow-md transition duration-200"
                                    >
                                        <Link to={`/teacher/dashboard/class/student-attendance/${row.studentClassId}`}>
                                            <td className="px-4 py-2 cursor-pointer text-blue-600 font-medium hover:underline">
                                                {row.studentId}
                                            </td>
                                        </Link>
                                        <td className="px-4 py-2 text-gray-700 font-medium">
                                            {row.studentName}
                                        </td>
                                        <td
                                            className="px-2 py-2 cursor-pointer"
                                            onClick={() => handleAttendanceClick(row)}
                                        >
                                            <span
                                                className={`px-3 py-1 rounded-full text-sm font-semibold shadow-lg ${row.status === "Absent"
                                                    ? "bg-red-500 text-white"
                                                    : row.status === "Pending"
                                                        ? "bg-yellow-400 text-white"
                                                        : "bg-green-500 text-white"
                                                    }`}
                                            >
                                                {row.status === "Pending" ? (
                                                    <>
                                                        <span className="hidden sm:inline">Mark</span>
                                                        <EditIcon className="inline ml-1" />
                                                    </>
                                                ) : (
                                                    row.status
                                                )}
                                            </span>
                                        </td>
                                        <Link to={`/teacher/dashboard/class/student-attendance/${row.studentClassId}`}>
                                            <td className="px-4 py-2 cursor-pointer text-blue-600 font-medium hover:underline">
                                                <PiListChecksFill className="inline" />
                                            </td>
                                        </Link>
                                    </tr>


                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-center">No attendance records found for {selectedDate}.</p>
                    )}
                </div>

                {/* Attendance Modal */}
                {attendanceModalOpen !== null && (
                    <Modal open={attendanceModalOpen} onClose={() => setAttendanceModalOpen(false)}>
                        <div className="bg-white p-6 rounded shadow-lg w-[90%] sm:w-80 mx-auto mt-[45vh]">
                            <h3 className="text-lg font-semibold mb-4 text-center">
                                Update Attendance Status
                            </h3>
                            <div className="flex justify-between space-x-2">
                                <button
                                    className={`px-4 py-2 w-full sm:w-auto text-white rounded ${attendanceModalOpen.status === "Present"
                                        ? "bg-green-300 cursor-not-allowed"
                                        : "bg-green-500"
                                        }`}
                                    onClick={() =>
                                        attendanceModalOpen.status !== "Present" &&
                                        handleUpdateStatus(attendanceModalOpen, "Present")
                                    }
                                    disabled={attendanceModalOpen.status === "Present"}
                                >
                                    Present
                                </button>
                                <button
                                    className={`px-4 py-2 w-full sm:w-auto text-white rounded ${attendanceModalOpen.status === "Absent"
                                        ? "bg-red-300 cursor-not-allowed"
                                        : "bg-red-500"
                                        }`}
                                    onClick={() =>
                                        attendanceModalOpen.status !== "Absent" &&
                                        handleUpdateStatus(attendanceModalOpen, "Absent")
                                    }
                                    disabled={attendanceModalOpen.status === "Absent"}
                                >
                                    Absent
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}

                {/* Student Details Modal */}
                <Modal open={detailsModalOpen} onClose={() => setDetailsModalOpen(null)}>
                    <div className="bg-white p-6 rounded shadow-lg w-[90%] sm:w-80 mx-auto mt-20">
                        <h3 className="text-lg font-semibold mb-4 text-center">Student Details</h3>
                        {studentDetails ? (
                            <div>
                                <p>
                                    <strong>Name:</strong> {studentDetails.name}
                                </p>
                                <p>
                                    <strong>Class:</strong> {studentDetails.class}
                                </p>
                                <p>
                                    <strong>Section:</strong> {studentDetails.section}
                                </p>
                            </div>
                        ) : (
                            <p className="text-center">Loading...</p>
                        )}
                    </div>
                </Modal>
            </section>

        </div>
    );
};

export default AttendanceList;
