import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Headingtitle from '../../Common/Headingtitle';
import { deleteParent, getAllParentList } from '../../../Services/AdminApis';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import EditParentModal from '../../../Layout/Models/EditParentModal';

export default function OnboardedParentList() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredRows, setFilteredRows] = useState([]);

    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedParent, setSelectedParent] = useState(null);
    const [selectedParentId, setSelectedParentId] = useState(null);
    const [open, setOpen] = useState(false);

    const fetchParents = async () => {
        try {
            const response = await getAllParentList();
            const parentData = response.data.parent;
            const formattedRows = parentData.map((parent, index) => ({
                id: index + 1,
                parentId: parent?._id,
                father: { ...parent?.father },
                mother: { ...parent?.mother },
                guardian: { ...parent?.guardian },
                fatherName: `${parent.father.firstName} ${parent.father.lastName}`,
                fatherEmail: parent.father.email,
                fatherMobile: parent.father.mobile,
                motherName: `${parent.mother.firstName} ${parent.mother.lastName}`,
                motherEmail: parent.mother.email,
                motherMobile: parent.mother.mobile,
                guardianName: `${parent.guardian.firstName} ${parent.guardian.lastName}`,
                guardianRelation: parent.guardian.relations,
                guardianMobile: parent.guardian.mobile,
                guardianAddress: parent.guardian.address,
                students: parent.students.map((student) => ({
                    name: student.name,
                    class: student.class,
                })),
                image: parent.father.avatar,
            }));

            setRows(formattedRows);
            setFilteredRows(formattedRows);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching parents:', error);
            setLoading(false);
        }
    };

    const handleEditClick = (parent) => {
        setSelectedParent(parent);
        setEditModalOpen(true);
    };
    // Fetch data from the API
    useEffect(() => {
        fetchParents();
    }, []);

    console.log(rows)

    // Handle search input changes
    const searchName = (e) => {
        const searchValue = e.target.value.toLowerCase();

        const filtered = rows.filter((row) => {
            // Check fatherName, motherName, guardianName
            const matchesBasicFields =
                row.fatherName.toLowerCase().includes(searchValue) ||
                row.motherName.toLowerCase().includes(searchValue) ||
                row.guardianName.toLowerCase().includes(searchValue);

            // Check within students array
            const matchesStudents = row.students.some((student) =>
                student.name?.firstName?.toLowerCase().includes(searchValue)

            );

            return matchesBasicFields || matchesStudents;
        });

        console.log('Filtered data:', filtered);
        setFilteredRows(filtered);
    };


    const handleOpenDeleteModal = (parent) => {
        setSelectedParentId(parent.parentId);
        setOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setOpen(false);
        setSelectedParentId(null);
    };

    const handleDelete = async () => {
        try {
            const response = await deleteParent(selectedParentId);
            if (response.status === 200) {
                toast.success("Deleted successfully");
                fetchParents();
            }
        } catch (error) {
            toast.error(error?.response?.data?.error || "Failed to delete");
        } finally {
            handleCloseDeleteModal();
        }
    };


    const columns = [
        { field: 'id', headerName: 'No.', width: 70 },
        {
            field: 'students',
            headerName: 'Students',
            width: 300,
            renderCell: (params) =>
                params.value.map(
                    (student, index) =>
                        ` ${student.name.firstName} ${student.name.lastName} (Class ${student.class.className} - ${student.class.classSection})`
                ).join('\n'),
        },
        { field: 'fatherName', headerName: 'Father Name', width: 200 },
        { field: 'fatherEmail', headerName: 'Father Email', width: 200 },
        { field: 'fatherMobile', headerName: 'Father Mobile', width: 150 },
        { field: 'motherName', headerName: 'Mother Name', width: 200 },
        { field: 'motherEmail', headerName: 'Mother Email', width: 200 },
        { field: 'motherMobile', headerName: 'Mother Mobile', width: 150 },
        { field: 'guardianName', headerName: 'Guardian Name', width: 200 },
        { field: 'guardianRelation', headerName: 'Relation', width: 150 },
        { field: 'guardianAddress', headerName: 'Guardian Address', width: 300 },
        { field: 'guardianMobile', headerName: 'Guardian Mobile', width: 150 },
        {
            field: 'image',
            headerName: 'Father Avatar',
            width: 100,
            renderCell: (params) => <Avatar src={params.value} alt="avatar" />,
        },
        {
            field: 'action', headerName: 'Action', width: 150, renderCell: (params) => (
                <div>
                    <IconButton color="primary"
                        onClick={() => handleOpenDeleteModal(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                    <EditIcon
                        color="primary"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleEditClick(params.row)}
                    />
                </div>)
        },
    ];

    return (
        <div className="h-[80vh]   w-full mt-5">

            <Headingtitle title="Guardians/Parents" />
            <div className="bg-white p-4 shadow-md rounded-md">
                <div className="mb-5">
                    <input
                        type="text"
                        placeholder="Search by FatherName, MohterName, Gurdians,Student"
                        className="p-2 border border-gray-300 rounded w-full"
                        onChange={searchName}
                    />
                </div>

                <DataGrid
                    style={{ backgroundColor: 'white' }}
                    rows={filteredRows}
                    columns={columns}
                    pageSize={10}
                    slots={{ toolbar: GridToolbar }}
                    checkboxSelection
                    loading={loading}
                />
            </div>
            {isEditModalOpen && (
                <EditParentModal
                    open={isEditModalOpen}
                    handleClose={() => setEditModalOpen(false)}
                    parentData={selectedParent}
                    refreshData={fetchParents} // Function to refresh data
                />
            )}
            <Dialog open={open} onClose={handleCloseDeleteModal}>
                <DialogTitle>Delete Teacher</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this teacher permanently? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
