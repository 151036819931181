import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createNoticeBoard } from "../../../Services/AdminApis";
import toast from "react-hot-toast";
import Headingtitle from "../../Common/Headingtitle";

const Noticeboard = () => {
    const { user, token } = useSelector((state) => state.auth);
    const [notice, setNotice] = useState({
        title: "",
        targetAudience: "",
        expiryDate: "",
        file: "",
        content: "",
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;

        if (name === "file") {
            const file = files[0];
            if (file && file.type === "image/jpeg") {
                setNotice({
                    ...notice,
                    [name]: file,
                });
                setErrors({ ...errors, [name]: "" });
            } else {
                setErrors({
                    ...errors,
                    [name]: "Only JPEG files are allowed.",
                });
            }
        } else {
            setNotice({
                ...notice,
                [name]: value,
            });
        }
    };

    // Form validation logic
    const validate = () => {
        const newErrors = {};
        Object.keys(notice).forEach((key) => {
            if (!notice[key] && key !== "file") {
                newErrors[key] = `${key.replace(/([A-Z])/g, " $1")} is required.`;
            }
        });
        if (!notice.file) {
            newErrors.file = "Attachment is required and must be a JPEG file.";
        }
        return newErrors;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors({});
    
            // Create FormData payload
            const formData = new FormData();
            Object.keys(notice).forEach((key) => {
                formData.append(key, notice[key]);
            });
            formData.append("school", token);
    
            try {
                const response = await createNoticeBoard(formData);
                if (response.status === 201 || response.status === 200) {
                    toast.success("Successfully published notice.");
                    resetForm();
                } else {
                    toast.error("Error while publishing notice!");
                }
            } catch (error) {
                toast.error("Internal server error.");
                console.error("Error while publishing notice", error);
            }
        }
    };
    
    const resetForm = () => {
        setNotice({
            title: "",
            targetAudience: "",
            expiryDate: "",
            file: "",
            content: "",
        });
    }

    return (
        <div className="font-poppins">
            <Headingtitle title="Noticeboard"/>
              <form onSubmit={handleSubmit} className="bg-white p-5 grid grid-cols-12 gap-5 rounded-lg shadow-md">
                {Object.keys(notice).map((key) => (
                    <div key={key} className={`flex flex-col ${key === "content" ? "col-span-12" : "col-span-6"}`}>
                        {key === "targetAudience" ? (
                            <select
                                id={key}
                                name={key}
                                value={notice[key]}
                                onChange={handleInputChange}
                                className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors[key] ? "border-red-500" : "border-[#EBEDF2]"
                                    }`}
                            >
                                <option value="">Select Target Audience</option>
                                <option value="All">All</option>
                                <option value="Student">Students</option>
                                <option value="Teacher">Teachers</option>
                                <option value="Parent">Parents</option>
                            </select>
                        ) : key === "file" ? (
                            <input
                                id={key}
                                name={key}
                                type="file"
                                accept=".jpeg"
                                onChange={handleInputChange}
                                className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors[key] ? "border-red-500" : "border-[#EBEDF2]"
                                    }`}
                            />
                        ) : (
                            <>
                                {key === "content" ? (
                                    <textarea
                                        id={key}
                                        name={key}
                                        value={notice[key]}
                                        onChange={handleInputChange}
                                        className={`w-full h-[100px] border-[2px] rounded-lg shadow-sm px-3 py-2 outline-none ${errors[key] ? "border-red-500" : "border-[#EBEDF2]"
                                            }`}
                                        placeholder={`Enter ${key.replace(/([A-Z])/g, " $1")}`}
                                    />
                                ) : (
                                    <input
                                        id={key}
                                        name={key}
                                        type={key === "expiryDate" ? "date" : "text"}
                                        value={notice[key]}
                                        onChange={handleInputChange}
                                        className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors[key] ? "border-red-500" : "border-[#EBEDF2]"
                                            }`}
                                        placeholder={`Enter ${key.replace(/([A-Z])/g, " $1")}`}
                                    />
                                )}

                            </>
                        )}
                        {errors[key] && <span className="text-red-500 text-sm">{errors[key]}</span>}
                    </div>
                ))}
                <button
                    type="submit"
                    className="w-full col-span-12 bg-[#22577A] text-white font-bold py-2 px-5 rounded-md hover:bg-[#1b4e69] focus:outline-none"
                >
                    Submit Notice
                </button>
            </form>
        </div>
    );
};

export default Noticeboard;
