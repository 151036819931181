import React, { useEffect, useState, useTransition } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { useNavigate } from "react-router-dom";
import { ParentChildFeesHistoryAPi, myAllStudentList } from "../../../Services/ParentApi";
import MyStudents from "./MyStudentList";

const ParentChildFeeHistory = () => {
    const [selectedId, setSelectedId] = useState("");
    const navigate = useNavigate();
    const [isPending, startTransition] = useTransition();
    const [feeHistory, setFeeHistory] = useState([]);

    useEffect(() => {
        startTransition(() => {
            ParentChildFeesHistoryAPi(selectedId)
                .then((response) => {
                    setFeeHistory(response?.data?.feeHistory || []);
                })
                .catch((error) => {
                    console.error("Error Fetching Results:", error);
                });
        });
    }, [selectedId]);

    const onStudentSelect = (data) => {
        setSelectedId(data);
    };

    const handleExamClick = (feesId) => {
        localStorage.setItem("fee", JSON.stringify(feesId));
        navigate(`/parent/dashboard/detail/ExamResult/${feesId?._id}`);
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="My Children Fee History" />
            <div className="min-w-full bg-white rounded-lg min-h-[80vh] font-poppins p-3">


                <MyStudents onStudentSelect={onStudentSelect} />

                <div className="mt-5 overflow-x-auto ">
                    <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md">
                        <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                            <tr>
                                <th className="p-3 text-left text-sm font-semibold">Fee Type</th>
                                <th className="p-3 text-left text-sm font-semibold">Due Date</th>
                                <th className="p-3 text-left text-sm font-semibold">Fee Amount</th>
                                <th className="p-3 text-left text-sm font-semibold">Paid Amount</th>
                                <th className="p-3 text-left text-sm font-semibold">Due Amount</th>
                                <th className="p-3 text-left text-sm font-semibold">Payment Status</th>
                                <th className="p-3 text-left text-sm font-semibold">Breakdown</th>
                                <th className="p-3 text-left text-sm font-semibold">Payment History</th>
                            </tr>
                        </thead>
                        <tbody>
                            {feeHistory.length > 0 ? (
                                feeHistory.map((fee, index) => (
                                    <tr
                                        key={index}
                                        className="hover:bg-gray-100 border-b transition duration-300 ease-in-out"
                                    >
                                        <td className="p-3 text-sm">{fee.feeType}</td>
                                        <td className="p-3 text-sm">{new Date(fee.dueDate).toLocaleDateString()}</td>
                                        <td className="p-3 text-sm">{fee.feeAmount}</td>
                                        <td className="p-3 text-sm">{fee.paidAmount}</td>
                                        <td className="p-3 text-sm">{fee.dueAmount}</td>
                                        <td
                                            className={`p-3 text-sm font-semibold ${fee.paymentStatus === "Fully Paid"
                                                    ? "text-green-600"
                                                    : "text-red-600"
                                                }`}
                                        >
                                            {fee.paymentStatus}
                                        </td>
                                        <td className="p-3 text-sm">
                                            {fee.breakdown.map((item, idx) => (
                                                <div key={idx}>
                                                    {item.component}: {item.amount}
                                                </div>
                                            ))}
                                        </td>
                                        <td className="p-3 text-sm">
                                            {fee.paymentHistory.length > 0 ? (
                                                fee.paymentHistory.map((payment, idx) => (
                                                    <div key={idx}>
                                                        {new Date(payment.date).toLocaleDateString()}:{" "}
                                                        {payment.amount} ({payment.method})
                                                    </div>
                                                ))
                                            ) : (
                                                <span className="text-gray-500">No Payments</span>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="8"
                                        className="text-center text-gray-500 p-5"
                                    >
                                        No Fee History Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
};

export default ParentChildFeeHistory;
