import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, Avatar, Grid } from '@mui/material';
import { getAllClassIdList, updateStudentData } from '../../Services/AdminApis';

const EditStudentModal = ({ open, handleClose, studentData, refreshData }) => {

    const [formData, setFormData] = useState(studentData || {});
    const [classList, setClassList] = useState([]);
    const [selectedClass, setSelectedClass] = useState({
        value: formData?.class || '',
        label: formData?.className || ''
    } || {});

    console.log("Updated Selected Class:", selectedClass);
    console.log("Class List:", classList);


    // Update form data on input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSelectedClass = (selectedValue) => {
        const classItem = classList?.find((cls) => cls.value === selectedValue);
        setSelectedClass({
            value: classItem?.value || '',
            label: classItem?.label || '',
        });
        console.log("Selected Value:", selectedValue);


    };

    // Handle form submission
    const handleSubmit = async () => {
        const payload = {
            name: {
                firstName: formData.firstName || '',
                middleName: formData.middleName || '', // Ensure middleName is populated
                lastName: formData.lastName || '' // Ensure lastName is populated
            },
            class: selectedClass.value || '', // Ensure classId is populated from formData
            contactInfo: {
                mobile: formData.mobile || '', // Ensure mobile is populated
                address: formData.address || '' // Ensure address is populated
            },
            rollNumber: formData.rollNumber || '', // Ensure rollNumber is populated
            academicYear: formData.academicYear || '', // Ensure academicYear is populated
        };



        try {
            const response = await updateStudentData(formData.studentId, payload); // Replace with actual API call
            if (response.status === 200) {
                console.log(response);
                refreshData(); // Refresh the data list after successful update
                handleClose(); // Close the modal after update
            }
        } catch (error) {
            console.error('Error updating student data:', error);
        }
    };



    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const classData = await getAllClassIdList();
                if (classData.status === 200) {
                    const formattedClassList = classData.data?.classes.map(cls => ({
                        value: cls._id,
                        label: `${cls.className}-${cls.classSection}`
                    }));
                    console.log("formattedClassList", formattedClassList);
                    setClassList(formattedClassList);
                }
            } catch (error) {
                console.error("Error fetching class list:", error);
            }
        };
        fetchClassList();
    }, []);


    return (
        <Modal open={open} >

            <Box
                className="bg-white p-5 rounded-md w-[80vw] mx-auto mt-2 h-[90vh] overflow-y-scroll"

                sx={{ outline: 'none' }}
            >
                <h2 className="text-xl font-bold mb-4">Edit Student Details</h2>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="First Name"
                            name="firstName"
                            value={formData.firstName || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Middle Name"
                            name="middleName"
                            value={formData?.middleName || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            name="lastName"
                            value={formData.lastName || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Gender"
                            name="gender"
                            disabled
                            value={formData.gender || ''}
                            onChange={handleChange}
                        />


                    </Grid>
                    <Grid item xs={6}>
                        <div className="mb-4">
                            <select
                                id="class"
                                value={selectedClass?.value || ''} // Use `value` instead of `label`
                                onChange={(e) => handleSelectedClass(e.target.value)}
                                className="w-full border rounded px-3 py-4"
                            >
                                <option value="" disabled>Select Class</option>
                                {classList?.map((cls) => (
                                    <option key={cls.value} value={cls.value}>
                                        {cls.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            disabled
                            value={formData.email || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Mobile"
                            name="mobile"

                            value={formData.mobile || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Address"
                            name="address"
                            value={formData.address || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Academic Year"
                            name="academicYear"
                            value={formData.academicYear || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Admission Date"
                            name="admissionDate"
                            disabled

                            type="date"
                            value={formData.admissionDate || ''}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Class Section"
                            name="classSection"
                            disabled

                            value={formData.classSection || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Roll Number"
                            name="rollNumber"
                            value={formData.rollNumber || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Student ID"
                            name="studentId"

                            value={formData.studentId || ''}
                            disabled // Student ID is typically non-editable
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Guardian Name"
                            disabled

                            name="guardianName"
                            value={formData.guardianName || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Guardian Email"
                            name="guardianEmail"
                            disabled
                            value={formData.guardianEmail || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Guardian Mobile"
                            name="guardianMobile"
                            value={formData.guardianMobile || ''}
                            disabled
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Guardian Address"
                            name="guardianAddress"
                            value={formData.guardianAddress || ''}
                            disabled
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="flex items-center gap-4">
                            <Avatar
                                src={formData.image || 'https://via.placeholder.com/50'}
                                alt="Student Avatar"
                                sx={{ width: 50, height: 50 }}
                            />
                            <TextField
                                fullWidth
                                label="Image URL"
                                disabled
                                name="image"
                                value={formData.image || ''}
                                onChange={handleChange}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box className="flex justify-end gap-4 mt-5">
                    <Button variant="contained" color="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditStudentModal;


