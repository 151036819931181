import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { adminforgotPassword } from '../../../Services/AdminApis';
import logo from "../../../Assests/Logos/world.png"

const SAdminForgotPassword = () => {
    const navigate = useNavigate();
    const [mobile, setMobile] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSendOTP = async () => {
        if (!mobile || mobile.length < 10) {
            toast.error('Please enter a valid mobile number');
            return;
        }

        setLoading(true);
        try {
            localStorage.setItem('mobile', mobile);
            const response = await adminforgotPassword({ mobile: mobile });
            if (response.status === 200) {
                localStorage.setItem('orderId', response?.data?.data?.orderId);
                toast.success('OTP sent successfully');
                navigate('/admin-verify-forgot-password-otp');
            }
        } catch (error) {
            toast.error(error.response.data.error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-white md:bg-gray-50">
            <div
                className=" w-[90vw] md:w-[50vw] md:max-w-md p-8  md:bg-white rounded-xl md:shadow-lg md:border md:border-gray-200 flex flex-col justify-evenly"
                style={{ height: '80vh' }}
            >
                {/* Logo */}
                <div className=" text-center mb-6">
                    <img src={logo} alt="Logo" className="h-12 mx-auto mb-4" />

                    {/* Title */}
                    <h2 className="text-2xl font-bold text-gray-800 text-center mb-2">
                        Forgot Password
                    </h2>
                    <p className="text-sm text-gray-500 text-center mb-6">
                        Enter your registered mobile number to receive an OTP.
                    </p>
                </div>
                {/* Phone Input */}
                <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Mobile Number
                    </label>
                    <PhoneInput
                        country={'in'}
                        value={mobile}
                        onChange={(phone) => setMobile(phone)}
                        inputStyle={{
                            width: '100%',
                            height: '50px',
                            borderRadius: '8px',
                            fontSize: '14px',
                            border: '1px solid #d1d5db',
                            paddingLeft: '48px', // Adjust for flag icon
                        }}
                    />
                </div>
                {/* Submit Button */}
                <button
                    onClick={handleSendOTP}
                    disabled={loading}
                    className={`w-full py-2 text-white font-medium rounded-full ${loading
                        ? 'bg-green-300 cursor-not-allowed'
                        : 'bg-green-500 hover:bg-green-600'
                        }`}
                >
                    {loading ? 'Sending OTP...' : 'Send OTP'}
                </button>
            </div>
        </div>
    );
};

export default SAdminForgotPassword;
