import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { ChildLeaveList } from "../../../Services/ParentApi";
import toast from "react-hot-toast";
import MyStudents from "./MyStudentList";
import ParentLeaveModel from "../../../Layout/Models/ParentLeaveModel";

const ParentChildLeave = () => {
  const [leaveList, setLeaveList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Pending"); // Default status filter
  const [selectStudentId, setSelectStudentId] = useState("");
  const [isModelOpen, setIsModelOpen] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await ChildLeaveList(selectStudentId, status); // Fetch leaves based on selected status
      setLeaveList(response?.data || []);
    } catch (error) {
      setLeaveList([]);
      console.error("Error fetching leaves:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setIsModelOpen(true);
  }

  const handleClose = () => {
    setIsModelOpen(false);
  }

  useEffect(() => {
    fetchData();
  }, [status, selectStudentId]);

  const onStudentSelect = (data) => {
    setSelectStudentId(data);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Pending":
        return "bg-gray-200 text-gray-800";
      case "Rejected":
        return "bg-red-200 text-red-800";
      case "Approved":
        return "bg-green-200 text-green-800";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };

  return (
    <div className="font-poppins">
      <Headingtitle title="Manage Leave Applications" />


      <div className="bg-white h-auto rounded-md px-5 pb-5 mt-5 pt-2 ">
        <div className="w-full flex justify-end my-4">
          <button
            onClick={handleOpen}
            className="bg-blue-600 rounded-md py-2 text-[0.8rem] font-semibold px-4 shadow-md text-white">Apply Leave</button>
        </div>
        {/* Status Filter */}
        <div className="">
          <div className="mb-4 ">
            <select
              className="border w-full rounded-md px-3 py-2 text-gray-700"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>

          {/* Student Selection */}
          <MyStudents onStudentSelect={onStudentSelect} />
        </div>
      </div>

      {/* Leave Applications Table */}
      <div className="bg-white h-auto rounded-md px-5 pb-5 mt-5 pt-2">
        <p className="text-text_blue-500 text-[1.13rem] font-semibold">
          Leave Applications List
        </p>
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-2">
            <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
              <tr>
                <th className="px-6 py-4 text-left text-[16px] font-bold">
                  Student Name
                </th>
                <th className="px-6 py-4 text-left text-[16px] font-bold">
                  Leave Type
                </th>
                <th className="px-6 py-4 text-left text-[16px] font-bold">
                  Start Date
                </th>
                <th className="px-6 py-4 text-left text-[16px] font-bold">
                  End Date
                </th>
                <th className="px-6 py-4 text-left text-[16px] font-bold">
                  Reason
                </th>
                <th className="px-6 py-4 text-left text-[16px] font-bold">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan={6}
                    className="text-center text-gray-500 py-4"
                  >
                    Loading...
                  </td>
                </tr>
              ) : leaveList.length > 0 ? (
                leaveList.map((application, index) => (
                 
                    <tr
                      key={application._id}
                      className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        } hover:bg-indigo-100 transition-all`}
                    >
                      <td className="px-6 py-4 text-gray-700 text-[15px]">
                        {application.studentId?.name?.firstName}{" "}
                        {application.studentId?.name?.middleName}{" "}
                        {application.studentId?.name?.lastName}
                      </td>
                      <td className="px-6 py-4 text-gray-700 text-[15px]">
                        {application.leaveType}
                      </td>
                      <td className="px-6 py-4 text-gray-700 text-[15px]">
                        {new Date(application.startDate).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 text-gray-700 text-[15px]">
                        {new Date(application.endDate).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 text-gray-700 text-[15px]">
                        {application.reason}
                      </td>
                      <td className="px-6 py-4 text-[15px]">
                        <span
                          className={`px-3 py-1 rounded-md text-sm font-medium ${getStatusClass(
                            application.status
                          )}`}
                        >
                          {application.status}
                        </span>
                      </td>
                    </tr>
                
                ))
              ) : (
                <tr>
                  <td
                    colSpan={6}
                    className="text-center text-gray-500 py-4"
                  >
                    No Leave Applications Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isModelOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Overlay */}
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={handleClose}
          ></div>

          {/* Modal Content */}
          <div className="bg-white p-6 rounded shadow-lg z-10 relative w-full max-w-lg">
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 text-gray-600"
            >
              X
            </button>
            <ParentLeaveModel onClose={handleClose} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ParentChildLeave;
