import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatDateOfBirth } from '../../utils/utilityFunction';

const StudentAttendanceListComp = ({ 
    fetchAttendance,
    headingTitle,
    isParentSection
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [attendanceList, setAttendanceList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const studentId = searchParams.get('studentId');


    const fetchAttendanceData = async (year, month) => {
        try {
            setIsLoading(true);

            if (isParentSection && !studentId) {
                return; 
            }
    
            const response = isParentSection 
                ? await fetchAttendance(studentId, month, year) 
                : await fetchAttendance(month, year);
    
            setAttendanceList(response?.data?.attendanceRecords || []);
        } catch (error) {
            console.error('Error fetching attendance:', error);
            setAttendanceList([]);
        } finally {
            setIsLoading(false);
        }
    };
    

    useEffect(() => {
        const year = searchParams.get('year') || new Date().getFullYear();
        const month = searchParams.get('month') || new Date().getMonth() + 1;
        setSelectedDate(new Date(year, month - 1));
        fetchAttendanceData(year, month);
    }, [searchParams]);


    return (
        <div className="w-full p-6">

            {/* Attendance Table */}
            <div className="w-full mt-8">
                <h2 className="text-lg font-medium text-gray-700 mb-4 text-center">
                    Attendance Summary for{' '}
                    {selectedDate.toLocaleString('default', { month: 'long' })}{' '}
                    {selectedDate.getFullYear()}
                </h2>

                {isLoading ? (
                    <p className="text-center text-gray-500">Loading...</p>
                ) : attendanceList ? (
                    <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-md overflow-hidden">
                        <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                            <tr>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">
                                    Date
                                </th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendanceList.map((entry, index) => (
                                <tr
                                    key={index}
                                    className={`${entry.status === 'Absent'
                                        ? 'bg-red-50'
                                        : 'bg-green-50 hover:bg-green-100'
                                        }`}
                                >
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">
                                        {formatDateOfBirth(entry.date)}
                                    </td>
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">
                                        {entry.status}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-gray-500 font-medium">
                        No attendance data available.
                    </p>
                )}
            </div>
        </div>
    );
};

export default StudentAttendanceListComp;
