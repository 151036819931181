import React, { useEffect, useState } from "react";
import { ParentChildReportAPi, myAllStudentList } from "../../../Services/ParentApi";
import { getAcademicsYears } from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";
import { useSearchParams } from "react-router-dom";
import MyStudents from "./MyStudentList";
import ReportsTableComp from "../../Common/ReportsTableComp";


const ParentChildReport = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [report, setReport] = useState(null); // State to store detailed report
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [error, setError] = useState(""); // Error state

    const student = JSON.parse(localStorage.getItem("user"));
    const [selectedId, setSelectedId] = useState('');
    const [academicsYear, setAcademicsYear] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');

    useEffect(() => {
        const fetchReportData = async () => {
            setIsLoading(true);
            setError("");
            try {
                const response = await ParentChildReportAPi(selectedId, searchParams.get("academicYear"));
                if (response.status === 200) {
                    setReport(response?.data?.report || null);
                } else {
                    setReport(null);

                }

            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.response?.data?.message || "Failed to fetch reports. Please try again.");
                setReport(null);
            } finally {
                setIsLoading(false);
            }
        };
        fetchReportData();
    }, [searchParams, selectedYear]);

    // Fetch academic years on component mount
    useEffect(() => {
        const fetchAcademicsYear = async () => {
            try {
                const response = await getAcademicsYears();

                setAcademicsYear(response?.data || []);
            } catch (err) {
                console.error("Error fetching academic years:", err);
                setError("Failed to load academic years.");
            }
        };
        fetchAcademicsYear();
    }, []);

    // Handle year selection
    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        setSelectedYear(selectedYear);
        setSearchParams({ academicYear: selectedYear });
    };

    const onStudentSelect = (studentId) => {
        setSelectedId(studentId);
    }

    return (
        <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[80vh]">
            <Headingtitle title="My Report" />
            <section className="min-w-full p-6 bg-white grid grid-cols-2 gap-5 rounded-lg min-h-[80vh] font-poppins">
                <div className="col-span-1">

                    <div>
                        <label>Student</label>
                        {<MyStudents onStudentSelect={onStudentSelect} />}

                    </div>
                </div>
                <div className="w-full col-span-1 ">
                    <label className="font-semibold">
                        Academic Year

                    </label>
                    <select
                        id="academicYear"
                        value={selectedYear}
                        onChange={handleYearChange}
                        aria-label="Select Academic Year"
                        className="w-full h-12 border border-gray-300 rounded-lg px-4 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 shadow-sm"
                    >
                        <option value="">-- Select Year --</option>
                        {academicsYear?.map((y) => (
                            <option key={y._id} value={y.year}>
                                {y.year}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Error or Loading State */}
                {error && <p className="text-red-500 p-4">{error}</p>}

                <div className="col-span-2 ">
                    <ReportsTableComp
                        isLoading={isLoading}
                        report={report}
                    />
                </div>
            </section>


        </div>
    );
};

export default ParentChildReport;
