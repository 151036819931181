import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Button, Box, Typography } from '@mui/material';

const RemoveStudentFromRoute = ({ response,routeId, onRemoveStudent }) => {
  const [selectedStudent, setSelectedStudent] = useState('');

  const routes = response.find(item=>item._id===routeId)
  console.log("response",response)
  console.log("routeId",routeId)
  console.log("routes",routes)

  const handleStudentChange = (event) => {
    setSelectedStudent(event.target.value);
  };

  const handleSubmit = () => {
    if (routeId && selectedStudent) {
      onRemoveStudent({ routeId, studentId: selectedStudent });
    }
  };

  return (
    <Box sx={{ p: 2, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
      <Typography variant="h6" gutterBottom>
        Remove Student from Transport Route
      </Typography>

      {/* Student Selection */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="student-select-label">Select Student</InputLabel>
        <Select
          labelId="student-select-label"
          value={selectedStudent}
          onChange={handleStudentChange}
        >
          {routes && routes.vehicle.routePoints.map(point => (
              <MenuItem key={point?.studentId} value={point?.studentId}>
                {point.studentName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {/* Submit Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!selectedStudent}
      >
        Remove Student
      </Button>
    </Box>
  );
};

export default RemoveStudentFromRoute;
