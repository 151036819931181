import api from "./apiUtility";


export const getMyTimeTable = async () => {
    try {
        const response = await api.get("/teacher/my-time-table");
        return response;
    } catch (error) {
        throw error;
    }
}


export const markClassAttendance = async (payload) => {
    try {
        const response = await api.post("/teacher/mark-class-attendance", payload);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllClassList = async () => {
    try {
        const response = await api.get("/teacher/my-class");
        return response;
    } catch (error) {
        throw error;
    }
}
export const getAllSubjectListData = async () => {
    try {
        const response = await api.get('/teacher/all-subject-list');
        return response;
    } catch (error) {
        throw error
    }
}

export const getAllSectionList = async () => {
    try {
        const response = await api.get("/section/all-list");
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllClassCategoryList = async () => {
    try {
        const response = await api.get("/all-class-category-list");
        return response;
    } catch (error) {
        throw error;
    }
}



export const studentAttendanceListById = async (studentId, month, year) => {
    try {
        const response = await api.get(`/teacher/student-attendance-list/${studentId}?month=${month}&year=${year}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const StudentAttendanceList = async (classId, date) => {
    try {
        const response = await api.get(`/teacher/attendance-list/${classId}?date=${date}`)
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllNotice = async () => {
    try {
        const response = await api.get("/teacher/latest-notices");
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSubjectOverView = async (subjectId) => {
    try {
        const response = await api.get(`/subject-overview/${subjectId}`);
        return response;
    } catch (error) {
        throw error
    }
}

export const getSubjectListofClassById = async (schoolId, selectedCategory, selectedSection) => {
    try {
        const response = await api.get(`/subject-list-of-a-class/${schoolId}?className=${selectedCategory}&sections=${selectedSection}`);
        return response;
    } catch (error) {
        throw error
    }
}

export const AddClassNewChapter = async (payload) => {
    try {
        const response = api.post("/teacher/add-study-material", payload)
        return response;
    } catch (error) {
        throw error 
    }
}

export const chapterListBySubjectId = async () => {
    try {
        const response = await api.get(`/chapter-list`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const chapterOverview = async () => {
    try {
        const response = await api.get(`/teacher/study-material-by-subject-and-chapter`);
        return response;
    } catch (error) {
        throw error;
    }
}
export const studyMaterialOverview = async () => {
    try {
        const response = await api.get(`/teacher/study-material-by-subject-and-chapter`);
        return response;
    } catch (error) {
        throw error;
    }
}
export const deleteStudyMaterial = async (materialId) => {
    try {
        const response = await api.delete(`/teacher/delete-study-material/${materialId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getTeacherHoliday = async () => {
    try {
        const response = await api.get("/teacher/all-up-comming-holiday-list");
        return response;
    } catch (error) {
        throw error
    }
}

export const ApplyforLeaveApplication = async (payload) => {
    try {
        const response = await api.post("/teacher/apply-for-leave", payload);
        return response;
    } catch (error) {
        throw error
    }
}

export const myLeaveApplications = async () => {
    try {
        const response = await api.get("/teacher/my-leave-applications");
        return response;
    } catch (error) {
        throw error
    }
}

export const deleteMyLeaveApplication = async (leaveId) => {
    try {
        const response = await api.delete(`/teacher/delete-leave-application/${leaveId}`);
        return response;
    } catch (error) {
        throw error
    }
}

export const getLeaveApplication = async (status = "") => {
    try {
        const response = await api.get(`/teacher/my-leave-applications?status=${status}`);
        return response;
    } catch (error) {
        throw error
    }
}


// Assignment

export const createNewAssignment = async (payload) => {
    try {
        const response = await api.post("/teacher/create-new-assignment", payload);
        return response;
    } catch (error) {
        throw error
    } 
}

export const updateAssignment = async (assignmentId, payload,token) => {
    try {
        const response = await api.patch(`teacher/update-assignment/${assignmentId}`, payload,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                }
            }
        );
        return response;
    } catch (error) {
        throw error
    }
}


// /teacher/my-due-assignment

export const getDueAssignment = async () => {
    try {
        const response = await api.get("/teacher/my-due-assignment");
        return response;
    } catch (error) {
        throw error
    }
}


export const getAllSubmittedAssignment = async () => {
    try {
        const response = await api.get("/teacher/submitted-assignment");
        return response;
    } catch (error) {
        throw error
    }
}


export const deleteAParticularAssignment = async (assignmentId) => {
    try {
        const response = await api.delete(`/teacher/delete-assignment/${assignmentId}`)
        return response;
    } catch (error) {
        throw error;

    }
}






export const getStudentLeaveApplicationList = async () => {
    try {
        const response = await api.get("teacher/students-leave-applications");
        return response;
    } catch (error) {
        throw error
    }
}



export const approveApplication = async (applicationId) => {
    try {
        const response = await api.patch(`teacher/approve-leave-application/${applicationId}`);
        return response;
    } catch (error) {
        throw error
    }
}



export const rejectApplication = async (applicationId) => {
    try {
        const response = await api.patch(`/teacher/reject-leave-application/${applicationId}`)
        return response;
    } catch (error) {
        throw error
    }
}


export const getStudentListofClassById = async (selectedCategory, selectedSection) => {
    try {
        const response = await api.get(`/teacher/get-student-parent-list-of-class?className=${selectedCategory}&classSection=${selectedSection}`);
        return response;
    } catch (error) {
        throw error
    }
}


export const getAcademicsYears = async () => {
    try {
        const response = await api.get("/academic/get-academic-years");
        return response;
    } catch (error) {
        throw error
    }
}



export const CreateStudentResult = async (payload) => {
    try {
        const response = await api.post("/teacher/create-student-result", payload)
        return response;
    } catch (error) {
        throw error;

    }
}

export const searchStudentResultCreated = async (examName,className) => {
    try {
        const response = await api.get(`/teacher/search-students-result?examName=${examName}&className=${className}`)
        return response;
    } catch (error) {
        throw error;

    }
}

export const deleteStudentParticularResult = async (resultId) => {
    try {
        const response = await api.delete(`/teacher/delete-student-result/${resultId}`)
        return response;
    } catch (error) {
        throw error;

    }
}