import React, { useEffect, useState } from "react";
import { Doughnut, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { BiSolidDownArrow } from "react-icons/bi";
import Headingtitle from "../../Common/Headingtitle";
import { getTeacherHoliday,getMyTimeTable,getAllNotice } from '../../../Services/TeachersApis';
import { formatDateOfBirth, formatDateTime,  } from "../../../utils/utilityFunction";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);
const dummyHolidays = [
    { name: "Diwali", date: "30-10-24" },
    { name: "Christmas", date: "25-12-24" },
];

const dummyTimetable = [
    { timeSlot: { "start": "10:30", "end": "11:00"}, class:{  "className": "5", "classSection": "A",}, subject: "Environmental Studies (EVS) - Theory" },
    { timeSlot: { "start": "11:30", "end": "12:00"}, class:{  "className": "10", "classSection": "B",}, subject: "Environmental Studies (EVS) - Theory" },
    { timeSlot: { "start": "12:30", "end": "13:00"}, class:{  "className": "12", "classSection": "A",}, subject: "Environmental Studies (EVS) - Theory" },
];
const Dashboard = () => {
    const [holidays, setHolidays] = useState([])
    const [timetable, setTimetable] = useState([])
    const [announcement,setAnnouncement]=useState([])
    const fetchHolidayData = async () => {
        try {
            const response = await getTeacherHoliday();
            if (response.status === 200) {
                setHolidays(response.data.holidays)
            }
        } catch (error) {
            setHolidays(dummyHolidays)
            console.error('Error fetching subject list:', error);
        }
    };

    const fetchTimeTableData = async () => {
        try {
            const response = await getMyTimeTable();
            if (response.status === 200) {
                setTimetable(response.data.timetables)
                console.log("response --teacher dashboard", response.data)
            }
        } catch (error) {
            setTimetable(dummyTimetable)
            console.error('Error fetching subject list:', error);
        }
    };
    const fetchTAnnouncement = async () => {
        console.log("announcement")

        try {
            const response = await getAllNotice();
            if (response.status === 200) {
                setAnnouncement(response.data.notices)
                console.log("response --teacher dashboard", response.data)
            }
        } catch (error) {
            setTimetable(dummyTimetable)
            console.error('Error fetching subject list:', error);
        }
    };

    useEffect(() => {
        fetchHolidayData();
    }, []);

    useEffect(() => {
        console.log("announcement")
        fetchTAnnouncement()
    }, []);

    useEffect(()=>{
        fetchTimeTableData()
    },[])


    const Genderdata = {
        labels: ['Female', 'Male'],
        datasets: [
            {
                label: 'Students',
                data: [200, 169], // Adjust with actual data
                backgroundColor: ['#FF4F9A', '#2ACB47'],
                hoverBackgroundColor: ['#FF8DBB', '#75E199'],
                borderWidth: 0,
            },
        ],
    };

    const Genderoptions = {
        cutout: '70%',
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    font: {
                        size: 12,
                        family: 'Montserrat',
                    },
                },
            },
            tooltip: {
                enabled: true,
            },
        },
    };

    const barchartData = {
        labels: ['A', 'B'],
        datasets: [
            {
                label: 'Percentage',
                data: [95.5, 85.5], // Adjust the data values accordingly
                backgroundColor: '#3ABEFE', // Bar color
                borderRadius: 8,
            },
        ],
    };

    const barchartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                    callback: function (value) {
                        return value + '%'; // Add percentage symbol to y-axis labels
                    },
                },
            },
        },
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Dashboard" />
            <main>
                <section className="grid w-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-4 sm:gap-5 grid-rows-auto p-4">
                    {/* Holiday List */}
                    <div className="col-span-1 sm:col-span-2 lg:col-span-4 bg-white text-text_blue-500 rounded shadow-md">
                        <p className="text-[1.1rem] text-text_blue-500 font-semibold p-3">Holiday</p>
                        <div className="p-3">
                            {holidays?.slice(0, 5).map((holiday, index) => (
                                <div
                                    key={index}
                                    className="mb-4 text-[0.77rem] flex justify-between p-2 rounded-lg bg-text_gray-75"
                                >
                                    <p className="font-semibold">{holiday.name}</p>
                                    <p className="text-text_gray-100">{formatDateOfBirth(holiday.date)}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Leaves Section */}
                    <div className="col-span-1 sm:col-span-2 lg:col-span-4 bg-white text-text_blue-500 rounded shadow-md">
                        <div className="flex w-full justify-between p-3">
                            <p className="text-[1.1rem] text-text_blue-500 font-semibold">Leaves</p>
                            {/* <div className="flex border-2 border-border-100 rounded-md items-center py-3 px-3 w-[150px]">
                                <p>Today</p>
                                <BiSolidDownArrow />
                            </div> */}
                        </div>
                        <div className="text-[0.8rem] text-text_blue-500 font-semibold text-center">
                            All are Present
                        </div>
                    </div>

                    {/* Gender Doughnut Chart */}
                    <div className="col-span-1 sm:col-span-2 lg:col-span-4 bg-white text-text_blue-500 rounded shadow-md">
                        <p className="text-[1.1rem] p-3 text-text_blue-500 font-semibold">Student Gender</p>
                        <div className="relative">
                            <Doughnut data={Genderdata} options={Genderoptions} />
                            <div className="absolute inset-0 flex flex-col items-center justify-center">
                                <p className="text-xl font-semibold">Total</p>
                                <p className="text-3xl font-bold">369</p>
                            </div>
                        </div>
                    </div>

                    {/* TimeTable Section */}
                    <div className="col-span-1 sm:col-span-2 lg:col-span-4 bg-white text-text_blue-500 rounded shadow-md">
                        <p className="text-[1.1rem] text-text_blue-500 font-semibold p-3">TimeTable</p>
                        {timetable.map((item, index) => (
                            <div key={index} className="p-3 font-semibold my-4 border-t-2 border-border-50">
                                <div className="flex justify-between py-2">
                                    <p className="text-[0.85rem]">{item.timeSlot.start}-{item.timeSlot.end}</p>
                                    <p className="text-[0.85rem] text-text_gray-100">{item.class.className}-{item.class.classSection}</p>
                                </div>
                                <p className="text-text_gray-100 text-[0.85rem]">{item.subject}</p>
                            </div>
                        ))}
                    </div>

                    {/* Bar Chart */}
                    <div className="col-span-1 sm:col-span-2 lg:col-span-4 bg-white text-text_blue-500 rounded shadow-md">
                        <p className="text-[1.1rem] text-text_blue-500 font-semibold p-3">Bar Chart</p>
                        <div className="h-[300px]">
                            <Bar data={barchartData} options={barchartOptions} />
                        </div>
                    </div>

                    {/* Announcement Table */}
                    <div className="col-span-1 sm:col-span-2 lg:col-span-12 bg-white text-text_blue-500 rounded shadow-md font-poppins text-[0.77rem]">
                        <p className="text-[1.1rem] text-text_blue-500 font-semibold p-3">Announcement</p>
                        <table className="w-full">
                            <thead className="px-2">
                                <tr className="grid grid-cols-12 px-2">
                                    <th className="col-span-1 text-left">No</th>
                                    <th className="col-span-4 text-left">Title</th>
                                    <th className="col-span-7 text-left">Description</th>
                                </tr>
                            </thead>
                            <hr />
                            <tbody>
                                {announcement && announcement.map((item, index) => (
                                        <tr key={index} className="grid grid-cols-12 p-2">
                                            <td className="col-span-1">{index + 1}</td>
                                            <td className="col-span-4">
                                                <p className="text-text_blue-500 font-semibold p-3">{item.title}</p>
                                            </td>
                                            <td className="col-span-7">
                                              {item.content}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default Dashboard;
