import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { getAlltranportRoute, addStudentToATransportRoute, getAllStudentList, GetOnboardedStudentList, getAllClassIdList } from "../../../Services/AdminApis";
import Headingtitle from "../../Common/Headingtitle";



const OnboardStudentToRoute = () => {
    const animatedComponents = makeAnimated();
    const [allRoutesData, setAllRoutesData] = useState([]);
    const [allStudentListData, setAllStudentListData] = useState([]);
    const [classList, setClassList] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedStudents, setSelectedStudents] = useState([]);

    const [studentRoute, setStudentRoute] = useState({
        routeId: "",
        locationName: "",
        studentName: "",
        studentId: "",
        location: {
            type: "Point",
            coordinates: ["", ""],
        },
        pickUpTime: "",
        dropOffTime: "",
    });

    const [errors, setErrors] = useState({});

    const handleSelectionChange = (selected) => {
        if(selected){
            setSelectedStudents(selected);
            setStudentRoute((prev) => ({
                ...prev,
                studentId: selected.value,
            }));
        }
       
    };

    useEffect(() => {
        const fetchAllRoutes = async () => {
            try {
                const response2 = await getAllStudentList();
                const response = await getAlltranportRoute();
                console.log(response?.data?.routes);
                setAllRoutesData(response?.data?.routes);
                setAllStudentListData(response2?.data?.students)
            } catch (error) {
                console.error("Error While Getting Routes data");
            }
        };
        fetchAllRoutes();
    }, []);

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const classData = await getAllClassIdList();
                if (classData.status === 200) {
                    const formattedClassList = classData.data?.classes.map(cls => ({
                        value: cls._id,
                        label: `${cls.className}-${cls.classSection}`
                    }));
                    console.log("formattedClassList", formattedClassList);
                    setClassList(formattedClassList);
                }
            } catch (error) {
                console.error("Error fetching class list:", error);
            }
        };
        fetchClassList();
    }, []);

    useEffect(() => {
        const fetchStudentList = async () => {
            if (!selectedClass) return;
            try {
                const studentData = await GetOnboardedStudentList(selectedClass.value);
                if (studentData.status === 200) {
                    setAllStudentListData(studentData?.data?.students.map(student => ({
                        value: student._id,
                        label: `${student.name.firstName} ${student.name.middleName || ""} ${student.name.lastName}`
                    })) || []);
                }

            } catch (error) {
                console.error("Error fetching students:", error);
            }
        };
        fetchStudentList();
    }, [selectedClass]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith("coordinates")) {
            const index = name === "coordinates[0]" ? 0 : 1;
            setStudentRoute((prev) => ({
                ...prev,
                location: {
                    ...prev.location,
                    coordinates: [
                        index === 0 ? value : prev.location.coordinates[0],
                        index === 1 ? value : prev.location.coordinates[1],
                    ],
                },
            }));
        } else {
            setStudentRoute((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const validateForm = () => {
        const tempErrors = {};
        Object.keys(studentRoute).forEach((key) => {
            if (key === "location") {
                const { coordinates } = studentRoute.location;
                if (!coordinates[0]) tempErrors["coordinates[0]"] = "Longitude is required";
                if (!coordinates[1]) tempErrors["coordinates[1]"] = "Latitude is required";
            } else if (!studentRoute[key]) {
                tempErrors[key] = `${key.replace(/([A-Z])/g, " $1")} is required`;
            }
        });
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const response = await addStudentToATransportRoute(studentRoute);

                if (response?.status === 200 || response?.status === 201) {
                    resetForm();
                    toast.success("Successfully added student to transport route");
                } else {
                    toast.error("Something went wrong");
                    console.error(response?.message);
                }
            } catch (error) {
                toast.error(error?.response?.data?.message || error.message);
                console.error(error.message);
            }
        }
    };

    const resetForm = () => {
        setStudentRoute({
            routeId: "",
            locationName: "",
            studentName: "",
            studentId: "",
            location: {
                type: "Point",
                coordinates: ["", ""], // Empty coordinates
            },
            pickUpTime: "",
            dropOffTime: "",
        });
    };


    return (

        <div className=" p-5 rounded-lg font-poppins">
            <Headingtitle title="Onboard Student to Route" />
            <form onSubmit={handleSubmit} className="grid grid-cols-6 bg-white gap-4 p-5 rounded-md">
                {Object.keys(studentRoute).map((key) => {
                    if (key === "location") {
                        return (
                            <React.Fragment key={key} className=" grid-cols-8">
                                <div className="flex flex-col col-span-12 md:col-span-4">
                                    <input
                                        id="coordinates[0]"
                                        name="coordinates[0]"
                                        type="text"
                                        value={studentRoute.location.coordinates[0]}
                                        onChange={handleInputChange}
                                        placeholder="Enter Longitude"
                                        className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors["coordinates[0]"] ? "border-red-500" : "border-[#EBEDF2]"}`
                                        }
                                    />
                                    {errors["coordinates[0]"] && (
                                        <span className="text-red-500 text-sm">{errors["coordinates[0]"]}</span>
                                    )}
                                </div>
                                <div className="flex flex-col col-span-12  md:col-span-4">
                                    <input
                                        id="coordinates[1]"
                                        name="coordinates[1]"
                                        type="text"
                                        value={studentRoute.location.coordinates[1]}
                                        onChange={handleInputChange}
                                        placeholder="Enter Latitude"
                                        className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors["coordinates[1]"] ? "border-red-500" : "border-[#EBEDF2]"}`
                                        }
                                    />
                                    {errors["coordinates[1]"] && (
                                        <span className="text-red-500 text-sm">{errors["coordinates[1]"]}</span>
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    } else if (key === "routeId") {
                        return (
                            <div key={key} className="flex flex-col col-span-12">
                                <select
                                    id="routeId"
                                    name="routeId"
                                    value={studentRoute.routeId}
                                    onChange={handleInputChange}
                                    className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors.routeId ? "border-red-500" : "border-[#EBEDF2]"}`}
                                >
                                    <option value="">Select Route</option>
                                    {allRoutesData.map((route) => (
                                        <option key={route._id} value={route._id}>
                                            {`${route.vehicle.routeNumber} - ${route.vehicle.driverName} (${route.vehicle.vehicleNumber})`}
                                        </option>
                                    ))}
                                </select>
                                {errors[key] && (
                                    <span className="text-red-500 text-sm">{errors[key]}</span>
                                )}
                            </div>
                        );
                    } else if (key === "studentId") {
                        return (
                            <div key={key} className="flex flex-col  col-span-12 md:col-span-4 ">
                            
                                <div className="">
                                    <Select
                                        styles={{ control: (base) => ({ ...base, borderColor: "#EBEDF2", height: "55px" }) }}
                                        options={classList}
                                        value={selectedClass}
                                        onChange={setSelectedClass}
                                        placeholder="-- Select Class --"
                                        isClearable
                                    />
                                </div>
                                <div className="bg-white py-5 rounded-lg shadow">
                                    <Select
                                        key={studentRoute.students}
                                        styles={{ control: (base) => ({ ...base, borderColor: "#EBEDF2", height: "55px" }) }}
                                        components={animatedComponents}
                                        options={allStudentListData}
                                        value={selectedStudents}
                                        onChange={handleSelectionChange}
                                        isClearable
                                        isSearchable
                                        placeholder="-- Select Student --"

                                    />
                                </div>
                                {errors[key] && (
                                    <span className="text-red-500 text-sm">{errors[key]}</span>
                                )}
                            </div>
                        );
                    } else {
                        return (
                            <div key={key} className="flex flex-col col-span-12">
                                <div>
                                    <label>{`${key.replace(/([A-Z])/g, " $1")}`}</label>
                                    <input
                                        id={key}
                                        name={key}
                                        type={key.includes("Time") ? "time" : "text"}
                                        value={studentRoute[key]} // Set the correct value for the input field
                                        onChange={handleInputChange}
                                        placeholder={`Enter ${key.replace(/([A-Z])/g, " $1")}`}
                                        className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors[key] ? "border-red-500" : "border-[#EBEDF2]"}`}
                                    />
                                    {errors[key] && (
                                        <span className="text-red-500 text-sm">{errors[key]}</span>
                                    )}
                                </div>
                            </div>
                        );
                    }
                })}


                <div className="col-span-12 flex justify-end">
                    <button
                        type="submit"
                        className="bg-[#22577A] w-full text-white py-2 px-4 rounded-lg hover:bg-[#1b4460]"
                    >
                        Onboard Student
                    </button>
                </div>
            </form>
        </div>
    );
};

export default OnboardStudentToRoute;
