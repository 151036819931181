import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import Headingtitle from '../../Common/Headingtitle';
import { OnboardNewTeacher } from "../../../Services/AdminApis";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import toast from "react-hot-toast";
import OnboardClipboardModel from "../../../Layout/Models/OnboardClipboardModel";
const TeacherOnboard = () => {
    const [isModalOpen, setIsModalOpen] = useState(null);
    const [formData, setFormData] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",

        phone: "",
        address: "",
        specializations: [],
        avatar: '',
        newSpecialization: "", // To handle the new specialization input
    });

    const handleOpenModel = (data) => {
        const { teacherId, password, email } = data;
        setIsModalOpen({
            codeId: teacherId,
            password: password,
            email: email,
        })
    }

    const handleClose = () => {
        setIsModalOpen(null);
    }

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);  // Added for loading state
    const [successMessage, setSuccessMessage] = useState("");  // For success message
    const [error, setError] = useState('');


    // Handling specialization add using the `newSpecialization` state from formData
    const handleSpecializationAdd = () => {
        if (formData.newSpecialization.trim() !== "") {
            setFormData((prevData) => ({
                ...prevData,
                specializations: [...prevData.specializations, formData.newSpecialization],
                newSpecialization: "", // Clear the input field after adding
            }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (!file) {
            // Handle case where no file is selected
            setError("Please select a file.");
            setFormData((prev) => ({
                ...prev,
                avatar: null,
            }));
            return;
        }

        // Check file size (300KB = 307200 bytes)
        if (file.size > 307200) {
            setError("File size should be less than 300KB.");
            setFormData((prev) => ({
                ...prev,
                avatar: null,
            }));
            return;
        }

        // Clear any existing error and update the form data
        setError("");
        setFormData((prevData) => ({ ...prevData, avatar: file }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("data", formData);
        // if (!validateForm()) return;

        const formDataToSend = new FormData();
        for (const key in formData) {
            if (key === 'specializations') {
                formDataToSend.append(key, JSON.stringify(formData[key]));
            } else if (key === 'avatar' && formData.avatar) {
                formDataToSend.append(key, formData.avatar);
            }

            else if (key === 'phone') {
                formDataToSend.append("mobile", formData[key]);
            }


            else if (key !== 'newSpecialization') {
                formDataToSend.append(key, formData[key]);
            }
        }

        // Show loading state while the API call is in progress
        setLoading(true);
        setSuccessMessage("");  // Clear any previous success messages

        try {
            const response = await OnboardNewTeacher(formDataToSend);

            if (response?.status === 201 || 200) {
                const ModelData = response?.data?.loginCredentials || {}
                handleOpenModel(ModelData);
                toast.success("Teacher onboarded successfully!")
                setError('')

            }
            // setSuccessMessage("Teacher onboarded successfully!"); // Show success message
            setFormData({
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",

                phone: "",
                address: "",
                specializations: [],
                avatar: '',
                newSpecialization: "",
            });
            setErrors({});
        } catch (error) {
            console.error("Failed to submit form", error);
            // setSuccessMessage("Failed to onboard teacher. Please try again.");  // Show error message
        } finally {
            setLoading(false); // Hide loading state after API call finishes
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };


    return (
        <div className="font-poppins">
            <Headingtitle title="Manage" />
            <main className="bg-white min-h-[80vh] rounded-md p-5">
                <p className="text-[1rem] font-semibold pb-2">Teacher Onboard</p>
                <form onSubmit={handleSubmit}>
                    <div className="grid w-full grid-cols-6 md:grid-cols-12 gap-5 text-[0.77rem]">
                        <div className="h-[80px] col-span-6">
                            <TextField
                                label="First Name"
                                name="firstName"
                                variant="outlined"
                                fullWidth
                                value={formData.firstName}
                                onChange={handleChange}
                                helperText={errors.firstName}
                                error={Boolean(errors.firstName)}
                                sx={textFieldStyles}
                            />
                        </div>
                        <div className="h-[80px] col-span-6">
                            <TextField
                                label="Middle Name"
                                name="middleName"
                                variant="outlined"
                                fullWidth
                                value={formData.middleName}
                                onChange={handleChange}
                                sx={textFieldStyles}
                            />
                        </div>
                        <div className="h-[80px] col-span-6">
                            <TextField
                                label="Last Name"
                                name="lastName"
                                variant="outlined"
                                fullWidth
                                value={formData.lastName}
                                onChange={handleChange}
                                helperText={errors.lastName}
                                error={Boolean(errors.lastName)}
                                sx={textFieldStyles}
                            />
                        </div>

                        <div className="h-[80px] col-span-6">
                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                variant="outlined"
                                fullWidth
                                value={formData.email}
                                onChange={handleChange}
                                helperText={errors.email}
                                error={Boolean(errors.email)}
                                sx={textFieldStyles}
                            />
                        </div>

                        <div className="col-span-6">
                            <div className="h-[56px]">
                                <PhoneInput
                                    id="phone"
                                    country={'in'}
                                    value={formData.phone}
                                    onChange={(value) => setFormData({ ...formData, phone: value })}
                                    containerClass="w-full rounded-lg"
                                    inputStyle={{
                                        width: '100%',
                                        borderRadius: "8px",
                                        height: "100%",
                                        border: "2px solid #EBEDF2"
                                    }}
                                    inputClass="w-full py-4 lg:py-[18px] pr-30 text-black h-full text-[20px] rounded-lg border-2 border-[#EBEDF2] focus:outline-none"
                                />
                            </div>
                        </div>
                        <Box className="col-span-6" sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', height: "55px" }}>
                                <TextField
                                    label="Avatar"
                                    name="avatar"
                                    variant="outlined"
                                    placeholder="Add Avatar"
                                    fullWidth
                                    value={formData.avatar ? formData.avatar.name : ""}
                                    InputProps={{ readOnly: true }}
                                    sx={textFieldStyles}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{
                                        height: '100%',
                                        minWidth: 'fit-content',
                                        borderTopLeftRadius: '0',
                                        borderBottomLeftRadius: '0',
                                        backgroundColor: '#22577A',
                                        '&:hover': { backgroundColor: '#194F68' },
                                    }}
                                >
                                    Choose File
                                    <input
                                        type="file"
                                        hidden
                                        name="avatar"
                                        onChange={handleFileChange}
                                    />
                                </Button>
                            </Box>
                            {error && (<span className="text-red-500 text-[0.8rem] italic">{error}</span>)}
                        </Box>
                        <div className="col-span-6">
                            <TextField
                                label="Address"
                                name="address"
                                variant="outlined"
                                fullWidth
                                value={formData.address}
                                onChange={handleChange}
                               
                                sx={textFieldStyles}
                            />
                        </div>

                        {/* Specialization input field and Add button */}
                            <div className="h-[80px] col-span-6 md:col-span-12">
                                <TextField
                                    label="Specialization"
                                    name="newSpecialization"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.newSpecialization}
                                    onChange={handleChange}
                                    sx={textFieldStyles}
                                />
                                <Button
                                    variant="contained"
                                    onClick={handleSpecializationAdd}
                                    sx={{
                                        marginTop: "10px",
                                        backgroundColor: "#22577A",
                                        "&:hover": { backgroundColor: "#194F68" },
                                    }}
                                >
                                    Add Specialization
                                </Button>
                            </div>

                            {/* Display list of added specializations */}
                            <div className="col-span-6 md:col-span-12">
                                <p className="font-semibold">Added Specializations:</p>
                                <ul>
                                    {formData.specializations.map((spec, index) => (
                                        <li key={index} className="text-[0.9rem]">{spec}</li>
                                    ))}
                                </ul>
                            </div>
                    </div>

                    {successMessage && <div className="mt-5 text-green-500">{successMessage}</div>}

                    <Box sx={{ textAlign: "center", width:"100%",marginTop: "20px" }}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                backgroundColor: "#22577A",
                                "&:hover": { backgroundColor: "#194F68" },
                                width:"100%"
                            }}
                            disabled={loading}
                        >
                            {loading ? "Submitting..." : "Submit"}
                        </Button>
                    </Box>
                </form>
            </main>
            {isModalOpen && (
                <OnboardClipboardModel
                    codeId={isModalOpen.codeId}
                    password={isModalOpen.password}
                    email={isModalOpen.email}
                    onClose={handleClose}
                />
            )}
        </div>
    );
};

const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
    },
    '& .MuiInputLabel-root': {
        fontSize: '14px',
    },
};

export default TeacherOnboard;
