import React, { Fragment, useState, useEffect } from 'react';
import Headingtitle from '../../Common/Headingtitle';
import { useForm } from 'react-hook-form';
import {
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,

} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { baseUrl } from '../../../utils/baseUrl';
import { CreateClass } from '../../../Services/superAdminApis';
import toast from 'react-hot-toast';
const ClassCategory = () => {
    const [search, setSearch] = useState('');
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)

    const fetchClassCategoryData = async () => {
        try {
            const response = await fetch(`${baseUrl}/all-class-category-list`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || "Something went wrong.")
            }
            setClasses(data.classCategories);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };
    useEffect(() => {


        fetchClassCategoryData();
    }, []);

    // Filter classes based on search input
    const filteredClasses = classes.filter((classItem) =>
        classItem.className.toLowerCase().includes(search.toLowerCase())
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    // Add new class category
    const onSubmit = async (data) => {
        console.log(data);
        try {
            const response = await CreateClass(data);
            if (response?.status === 201 || 200) {
                toast.success("Successfully added class Category!");
                reset();
                fetchClassCategoryData();
            }
        } catch (error) {
            toast.error(error.message || "Internal Server Error");
        }
    };

    

    return (
        <Fragment>
            <div className="font-poppins">
                {/* Heading section */}
                <Headingtitle title="Academics" />

                {/* Main content section */}
                <main className="px-4 py-2">
                    <section className="w-full flex flex-col gap-5 md:flex-row">
                        {/* Class Category Form */}
                        <div className="w-full md:w-1/2 bg-white text-text_blue-500 rounded shadow-md p-4 h-fit">
                            <p className="text-[1.1rem] font-semibold mb-4">Class Category</p>
                            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                                <div className="h-[80px]">
                                    <TextField
                                        label="Class Name"
                                        variant="outlined"
                                        placeholder="Enter the School name"
                                        fullWidth
                                        className="col-span-6"
                                        {...register('className', { required: 'School name is required' })}
                                        error={!!errors.className}
                                        helperText={errors.className ? errors.className.message : ''}
                                        sx={{
                                            height: "55px",
                                            "& .MuiOutlinedInput-root": {
                                                height: "100%",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    borderColor: "#EBEDF2",
                                                    borderWidth: "2px",
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: "#B0B3B8",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#22577A",
                                                    borderWidth: "2px",
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                                color: "#000000",
                                            },
                                        }}
                                    />
                                </div>
                                <div className="flex justify-end">
                                    <button type="submit" className="text-white rounded-lg bg-[#1A2A5A] px-3 py-2">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>

                        {/* List of Classes */}
                        <div className=" w-full md:w-1/2 bg-white text-text_blue-500 rounded shadow-md p-4">
                            <div className="md:p-3">
                                {classes.length > 0 ? (
                                    <div className="p-4 font-poppins">
                                        <h2 className="text-[1.1rem] font-semibold mb-4">List Class</h2>

                                        {/* Search Filter */}
                                        <TextField
                                            variant="outlined"
                                            placeholder="Search"
                                            fullWidth
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            sx={{
                                                marginBottom: '1rem',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px',
                                                },
                                            }}
                                        />

                                        {/* Table */}
                                        <TableContainer component={Paper} className="h-[50vh] overflow-y-scroll">
                                            <Table>
                                                <TableHead>
                                                    <TableRow className="text-[#1A2A5A] text-[0.77rem] leading-[18px] font-semibold">
                                                        <TableCell>No</TableCell>
                                                        <TableCell>Class Category</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="">
                                                    {filteredClasses.length > 0 ? (
                                                        filteredClasses.map((classItem, index) => (
                                                            <TableRow key={classItem.id}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>Class - {classItem.className}</TableCell>
                                                                
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={3} align="center">
                                                                No classes available.
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                ) : (
                                    <p className="text-gray-600">No classes available.</p>
                                )}
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </Fragment>
    );
};

export default ClassCategory;
