import React, { useState, useEffect } from 'react';
import Headingtitle from '../../Common/Headingtitle';
import {
    getAllClassCategoryList,
    searchStudentResultCreated,
    deleteStudentParticularResult
} from '../../../Services/TeachersApis';
import StudentTableCard from '../../Common/StudentTableCard';
import toast from "react-hot-toast";

const SearchCreatedStudentResultList = () => {
    const [classCategoryList, setClassCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [studentResultList, setStudentResultList] = useState([]);
    const [error, setError] = useState(null);
    const [examName, setExamName] = useState();
    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null);
    };
    const handleOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
    };

    const fetchMyClassData = async () => {
        try {
            const classCategoryResponse = await getAllClassCategoryList();

            if (classCategoryResponse.status === 200) {
                setClassCategoryList(classCategoryResponse?.data?.classCategories || []);
            }
        } catch (error) {
            setError('Failed to fetch class or section data.');
            console.error(error);
        }
    };

    const fetchData = async (selectedCategory, examName) => {
        if (selectedCategory && examName) {
            try {
                const response = await searchStudentResultCreated(examName, selectedCategory);
                if (response.status === 200) {
                    setStudentResultList(response.data.allResults || []);
                    console.log("response.data", response.data)
                }
            } catch (error) {
                setError('Error fetching subject list.');
                console.error(error);
            }
        }
    };
    useEffect(() => {
        fetchData(selectedCategory, examName);
    }, [examName, selectedCategory]);

    useEffect(() => {
        fetchMyClassData();
    }, []);

    const handleDelete = async () => {
        try {
            const response = await deleteStudentParticularResult(selectedId);
            if (response.status === 200) {
                toast.success("Deleted successfully");
                fetchData(selectedCategory, examName);
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            toast.error(error.response?.data?.message || "Error deleting record");
        } finally {
            handleClose();
        }
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Students" />
            <main className="bg-white rounded-md p-6 text-gray-800 relative min-h-[80vh] shadow-lg">
                <p className="font-semibold text-[1.25rem] pb-4 border-b border-gray-300">
                    Search Student Result
                </p>

                {error && <p className="text-red-500">{error}</p>}

                <div className="grid w-full grid-cols-12 gap-5 grid-rows-2 text-[0.85rem] mb-6">
                    <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="col-span-6 border rounded-md p-3 bg-gray-50 shadow-sm "
                    >
                        <option value="">Select Class Name</option>
                        {classCategoryList.length > 0 &&
                            classCategoryList.map((data) => (
                                <option key={data._id} value={data.className}>
                                    {data.className}
                                </option>
                            ))}
                    </select>

                    <input
                        type={"text"}
                        name={"examName"}
                        value={examName}
                        onChange={(e) => setExamName(e.target.value)}
                        placeholder={`Enter Exam Name`}
                        className="col-span-6 w-full border border-gray-300 rounded-md px-3 py-2"
                    />
                </div>

                <div>

                    <StudentTableCard data={studentResultList} open={open} handleDelete={handleDelete} handleOpen={handleOpen} />

                </div>
            </main>
        </div>
    );
};

export default SearchCreatedStudentResultList;
