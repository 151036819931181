import React from 'react'
import Headingtitle from '../../Common/Headingtitle'
import YouTubeEmbed from '../../Common/YoutubeEmbedComp'
const TeacherYoutube = () => {
    const videoLink = 'https://www.youtube.com/watch?v=cPvqVjnBVGY'
    return (
        <div className="font-poppins">
            <Headingtitle title="Watch the Video" />
            <main className="px-4 py-0">
                <YouTubeEmbed videoLink={videoLink} />
            </main>
        </div>
    )
}

export default TeacherYoutube
