import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { useNavigate } from "react-router-dom";
import { getChildrenResult, getChildrenResultOverview, myAllStudentList } from "../../../Services/ParentApi";
import ResultListComponent from "../../Common/ResultListComponent";
import MyStudents from "./MyStudentList";

const ParentChildResult = () => {
    const [isPending, startTransition] = React.useTransition();
    const [selectedId, setSelectedId] = useState('');
    const [results, setResults] = useState([]); // Results array
    const navigate = useNavigate();

    useEffect(() => {
        startTransition(() => {
            getChildrenResult(selectedId)
                .then((response) => {
                    setResults(response?.data?.results || []);
                })
                .catch((error) => {
                    console.error("Error Fetching Results:", error);
                });
        });
    }, [selectedId]);

    const onStudentSelect = (studentId) => {
        setSelectedId(studentId);
    }


    // Function to handle storing the exam result in localStorage and navigation
    const handleExamClick = (result) => {
        localStorage.setItem("examResult", JSON.stringify(result));
        navigate(`/parent/dashboard/detail/ExamResult/${result?._id}`);
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="My Childrean Result" />
            <div className="min-w-full bg-white rounded-lg min-h-[80vh] font-poppins p-3">

            {<MyStudents onStudentSelect={onStudentSelect} />}

            <ResultListComponent isPending={isPending} results={results} handleExamClick={handleExamClick} />
            </div>
        </div>
    );
};

export default ParentChildResult;
