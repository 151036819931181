import React, { useState, useEffect } from "react";
import Select from "react-select";
import { 
    GetOnboardedStudentList, 
    getAllParentList, 
    assignNewStudentToParent, 
    getAllClassIdList 
} from "../../../Services/AdminApis";
import toast from "react-hot-toast";

const AssignStudentParent = () => {
    const [students, setStudents] = useState([]);
    const [parents, setParents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [selectedParent, setSelectedParent] = useState(null);
    const [classList, setClassList] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const classData = await getAllClassIdList();
                if (classData.status === 200) {
                    const formattedClassList = classData.data?.classes.map(cls => ({
                        value: cls._id,
                        label: `${cls.className}-${cls.classSection}`
                    }));
                    console.log("formattedClassList", formattedClassList);
                    setClassList(formattedClassList);
                }
            } catch (error) {
                console.error("Error fetching class list:", error);
            }
        };
        fetchClassList();
    }, []);

    useEffect(() => {
        const fetchStudentList = async () => {
            if (!selectedClass) return;
            try {
                const studentData = await GetOnboardedStudentList(selectedClass.value);
                setStudents(studentData?.data?.students.map(student => ({
                    value: student._id,
                    label: `${student.name.firstName} ${student.name.middleName || ""} ${student.name.lastName}`
                })) || []);
            } catch (error) {
                console.error("Error fetching students:", error);
            }
        };
        fetchStudentList();
    }, [selectedClass]);

    useEffect(() => {
        const fetchParentList = async () => {
            try {
                const parentData = await getAllParentList();
                setParents(parentData?.data?.parent.map(parent => ({
                    value: parent._id,
                    label: `${parent.father.firstName} ${parent.father.lastName} (Father) ${parent.guardian.mobile}`
                })) || []);
            } catch (error) {
                console.error("Error fetching parents:", error);
            }
        };
        fetchParentList();
    }, []);

    const handleSubmit = async () => {
        if (!selectedStudent || !selectedParent) {
            alert("Please select both a student and a parent");
            return;
        }

        try {
            const response = await assignNewStudentToParent(selectedStudent.value, selectedParent.value);
            if (response.status === 201 || response.status === 200) {
                toast.success("Successfully assigned student to parent");
            }
        } catch (error) {
            console.error("Error during assignment:", error);
            toast.error(error?.response?.data?.error || "Failed to assign student to parent");
        }
    };

    return (
        <div className="p-6 mx-auto bg-white shadow-lg rounded-lg">
            <h2 className="text-3xl font-semibold mb-6 text-center text-blue-600">
                Assign Student to Parent
            </h2>

            {/* Class Selection */}
            <div className="mb-5">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                    Select Class
                </label>
                <Select
                    options={classList}
                    value={selectedClass}
                    onChange={setSelectedClass}
                    placeholder="-- Select Class --"
                    isClearable
                />
            </div>

            {/* Student Dropdown */}
            <div className="mb-5">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                    Select Student
                </label>
                <Select
                    options={students}
                    value={selectedStudent}
                    onChange={setSelectedStudent}
                    placeholder="-- Select Student --"
                    isClearable
                    isSearchable
                />
            </div>

            {/* Parent Dropdown */}
            <div className="mb-5">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                    Select Parent
                </label>
                <Select
                    options={parents}
                    value={selectedParent}
                    onChange={setSelectedParent}
                    placeholder="-- Select Parent --"
                    isClearable
                    isSearchable
                />
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
                <button
                    className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition ease-in-out duration-200"
                    onClick={handleSubmit}
                >
                    Assign
                </button>
            </div>
        </div>
    );
};

export default AssignStudentParent;
