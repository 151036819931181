import React ,{useState}from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip, Modal, Box, Typography, TextField, Button ,Grid2} from "@mui/material";
import { deleteCreatedSchool, editCreatedSchool } from "../../../Services/superAdminApis";
import toast from "react-hot-toast";

export default function SchoolList({ schoolData ,fetchAllSchoolList}) {

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [formData, setFormData] = useState({
        schoolName: "",
        address: "",
        alternateMobile: "",
        mobile: "",
        email: "",
    });
    const [searchTerm, setSearchTerm] = useState("");

    const handleEdit = (school) => {
        setSelectedSchool(school.id);
        setFormData({
            schoolName: school?.schoolName || "",
            address: school?.address || "",
            alternateMobile: school?.alternateMobile || "",
            mobile: school?.mobile || "",
            email: school?.email || "",
        });
        setEditModalOpen(true);
    };

    const handleDelete = (id) => {
        setSelectedSchool(id);
        setDeleteModalOpen(true);
    };

    const closeEditModal = () => setEditModalOpen(false);
    const closeDeleteModal = () => setDeleteModalOpen(false);

    const saveChanges = async () => {
        try {
            const response = await editCreatedSchool(selectedSchool, formData);
            if (response.status === 200) {
                toast.success(response.data.message || "Edited successfully");
                closeEditModal();
                fetchAllSchoolList();
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
    };

    const confirmDelete = async () => {
        try {
            const response = await deleteCreatedSchool(selectedSchool);
            if (response.status === 200) {
                toast.success(response.data.message || "Deleted successfully");
                closeDeleteModal();
                fetchAllSchoolList();
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
    };

    const columns = [
        { field: "id", headerName: "ID", width: 100 },
        { field: "schoolName", headerName: "School Name", width: 200 },
        { field: "schoolCode", headerName: "School Code", width: 150 },
        { field: "email", headerName: "Email", width: 200 },
        { field: "mobile", headerName: "Mobile", width: 150 },
        { field: "alternateMobile", headerName: "Alternate Mobile", width: 180 },
        { field: "address", headerName: "Address", width: 300 },
        { field: "numberOfStudents", headerName: "Students", width: 120 },
        { field: "numberOfTeachers", headerName: "Teachers", width: 120 },
        { field: "numberOfClasses", headerName: "Classes", width: 120 },
        {
            field: "active",
            headerName: "Active",
            width: 100,
            renderCell: (params) => (params.value ? "Yes" : "No"),
        },
        {
            field: "registrationDate",
            headerName: "Registration Date",
            width: 180,
            renderCell: (params) =>
                new Date(params.value).toLocaleDateString("en-US"),
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 150,
            renderCell: (params) => (
                <div style={{ display: "flex", gap: "10px" }}>
                    <Tooltip title="Edit">
                        <IconButton onClick={() => handleEdit(params.row)}>
                            <EditIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton onClick={() => handleDelete(params.row.id)}>
                            <DeleteIcon color="error" />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ];


    const rows = schoolData.map((school, index) => ({
        id: school._id,
        schoolName: school.schoolName,
        schoolCode: school.schoolCode,
        email: school.email,
        mobile: school.mobile,
        alternateMobile: school.alternateMobile,
        address: school.address,
        numberOfStudents: school.schoolDetails.numberOfStudents,
        numberOfTeachers: school.schoolDetails.numberOfTeachers,
        numberOfClasses: school.schoolDetails.numberOfClasses,
        active: school.active,
        registrationDate: school.registrationDate,
    }));

  
    return (
        <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 50]}
                components={{ Toolbar: GridToolbar }}
                sx={{
                    boxShadow: 3,
                    border: 1,
                    borderColor: "#ccc",
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#197231",
                        color: "#000",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                    },
                    "& .MuiDataGrid-columnHeaders:hover": {
                        backgroundColor: "#1565c0",
                    },
                    "& .MuiDataGrid-row": {
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: "0.9rem",
                        color: "#333",
                    },
                    "& .MuiDataGrid-row:nth-of-type(odd)": {
                        backgroundColor: "#f9f9f9",
                    },
                    "& .MuiDataGrid-row:nth-of-type(even)": {
                        backgroundColor: "#fff",
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: "#f5f5f5",
                        borderTop: "1px solid #ccc",
                    },
                }}
            />

            {/* Edit Modal */}
            <Modal open={editModalOpen} onClose={closeEditModal}>
                <Box sx={{ p: 3, width: 400, mx: "auto", mt: "10%", bgcolor: "white", borderRadius: 2 }}>
                    <Typography variant="h6" mb={2}>
                        Edit School
                    </Typography>
                    <Grid2 container spacing={2}>
                        <Grid2 item size={12}>
                            <TextField
                                label="School Name"
                                fullWidth
                                value={formData.schoolName}
                                onChange={(e) =>
                                    setFormData((prev) => ({ ...prev, schoolName: e.target.value }))
                                }
                            />
                        </Grid2>
                        <Grid2 item size={12}>
                            <TextField
                                label="Address"
                                fullWidth
                                value={formData.address}
                                onChange={(e) =>
                                    setFormData((prev) => ({ ...prev, address: e.target.value }))
                                }
                            />
                        </Grid2>
                        <Grid2 item size={12}>
                            <TextField
                                label="Email"
                                fullWidth
                                value={formData.email}
                                onChange={(e) =>
                                    setFormData((prev) => ({ ...prev, email: e.target.value }))
                                }
                            />
                        </Grid2>
                        <Grid2 item size={12}>
                            <TextField
                                label="Mobile"
                                fullWidth
                                value={formData.mobile}
                                onChange={(e) =>
                                    setFormData((prev) => ({ ...prev, mobile: e.target.value }))
                                }
                            />
                        </Grid2>
                        <Grid2 item size={12}>
                            <TextField
                                label="Alternate Mobile"
                                fullWidth
                                value={formData.alternateMobile}
                                onChange={(e) =>
                                    setFormData((prev) => ({ ...prev, alternateMobile: e.target.value }))
                                }
                            />
                        </Grid2>
                        <Grid2 item size={12}>
                            <Button variant="contained" color="primary" onClick={saveChanges}>
                                Save Changes
                            </Button>
                        </Grid2>
                    </Grid2>
                </Box>
            </Modal>

            {/* Delete Modal */}
            <Modal open={deleteModalOpen} onClose={closeDeleteModal}>
                <Box sx={{ p: 3, width: 300, mx: "auto", mt: "20%", bgcolor: "white", borderRadius: 2, textAlign: "center" }}>
                    <Typography variant="h6" mb={2}>
                        Confirm Deletion
                    </Typography>
                    <Typography mb={3}>Are you sure you want to delete this school?</Typography>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={confirmDelete}
                        sx={{ mr: 2 }}
                    >
                        Delete
                    </Button>
                    <Button variant="outlined" onClick={closeDeleteModal}>
                        Cancel
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}
