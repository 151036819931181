import React, { useState, useEffect } from "react";
import { BiSolidDownArrow } from "react-icons/bi";
import Headingtitle from "../../Common/Headingtitle";
import Count_Icon from "../../../Assests/Logos/Count_Icon.png";
import Student_Img from "../../../Assests/Logos/Student_Img.png";
import StudentAnalyticsChart from "./StudentAnalytics";
import {
  myProfile,
  myAnalytics,
  myAcademicCalendar,
} from "../../../Services/StudentApis";
import { formatDateOfBirth, formatName } from "../../../utils/utilityFunction";
import AcademicCalendar from "../../Common/StudentAcademicCalendar";

const StudentDashboard = () => {
  const [analytics, setAnalytics] = useState([]);
  const [profile, setProfile] = useState({});
  const [events, setEvents] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const academicYear = user.academicYear;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await myAcademicCalendar(academicYear);
        if (response.status === 200) {
          setEvents(response?.data);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const fetchAnalyticsData = async () => {
    try {
      const response = await myAnalytics();
      if (response.status === 200) {
        const data = response.data.analytics || [];
        setAnalytics(data);
      }
    } catch (error) {}
  };

  const fetchProfileData = async () => {
    try {
      const response = await myProfile();
      if (response.status === 200) {
        const data = response.data.student || {};
        setProfile(data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchProfileData();
    fetchAnalyticsData();
  }, []);
  return (
    <div className="font-poppins">
      <Headingtitle title="Dashboard" />

      <main className="px-4 md:px-6 py-2">
  <section className="grid w-full grid-cols-1 md:grid-cols-12 gap-5">
    {/* Academic Calendar */}
    <div className="col-span-12">
      <AcademicCalendar events={events} academicYear={academicYear} />
    </div>

    {/* Student Info Section */}
    <div className="col-span-12 md:col-span-6 h-auto md:h-[410px] flex flex-col justify-between font-poppins bg-white text-text_blue-500 px-5 py-3 rounded-xl shadow-md">
      <p className="text-[1.2rem] md:text-[1.5rem] font-semibold h-[100px] border-b-[1px] pl-2 md:pl-5 border-border-100">
        Student Info
      </p>
      <div className="flex flex-wrap md:flex-nowrap justify-between w-full my-1 h-auto md:h-[250px] mb-5 pl-2 md:pl-5">
        {/* Left Section */}
        <div className="w-full md:w-[50%] mb-5 md:mb-0">
          <div className="flex gap-2 md:gap-[4px] flex-col items-center md:items-start">
            <img
              src={profile?.avatar || Student_Img}
              className="w-[80px] h-[80px] md:w-[100px] md:h-[100px] rounded-full"
            />
            <p className="text-[1rem] md:text-[1.1rem] font-semibold leading-[27px] text-center md:text-left">
              Student Name
            </p>
            <p className="text-[0.8rem] md:text-[0.88rem] text-text_gray-100 text-center md:text-left">
              {formatName(profile)}
            </p>
          </div>
          <div>
            <p className="text-[1rem] md:text-[1.1rem] font-semibold leading-[27px] text-center md:text-left">
              Admission Date
            </p>
            <p className="text-[0.8rem] md:text-[0.88rem] text-text_gray-100 text-center md:text-left">
              {formatDateOfBirth(profile?.admissionDate)}
            </p>
          </div>
        </div>
        {/* Right Section */}
        <div className="w-full md:w-[50%] flex flex-col justify-between">
          <div>
            <p className="text-[1rem] md:text-[1.1rem] font-semibold leading-[27px] text-left">
              Class (Section)
            </p>
            <p className="text-[0.8rem] md:text-[0.88rem] text-text_gray-100">
              {profile.class?.className}-{profile?.class?.classSection}
            </p>
          </div>
          <div>
            <p className="text-[1rem] md:text-[1.1rem] font-semibold leading-[27px] text-left">
              Roll No
            </p>
            <p className="text-[0.8rem] md:text-[0.88rem] text-text_gray-100">
              {profile?.rollNumber}
            </p>
          </div>
          <div>
            <p className="text-[1rem] md:text-[1.1rem] font-semibold leading-[27px] text-left">
              Guardian Name
            </p>
            <p className="text-[0.8rem] md:text-[0.88rem] text-text_gray-100">
              {`${profile?.parent?.guardian?.firstName} ${profile?.parent?.guardian?.middleName} ${profile?.parent?.guardian?.lastName}`}
            </p>
          </div>
          <div>
            <p className="text-[1rem] md:text-[1.1rem] font-semibold leading-[27px] text-left">
              Mobile Number
            </p>
            <p className="text-[0.8rem] md:text-[0.88rem] text-text_gray-100">
              {profile?.parent?.guardian?.mobile}
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* Leaves Section */}
    <div className="col-span-12 md:col-span-6 bg-white h-auto md:h-[410px] px-5 py-3 text-text_blue-500 rounded-xl shadow-md">
      <StudentAnalyticsChart analyticsData={analytics} />
    </div>
  </section>
</main>
</div>
  );
};

export default StudentDashboard;
