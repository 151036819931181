import React from 'react'

const ReportsTableComp = ({ isLoading, report }) => {
    return (
        <div className='w-full'>
            {isLoading ? (
                <p className="text-center text-blue-500 p-4">Loading...</p>
            ) : (
                <>


                    {report !== null && (
                        <div className="p-6 mt-5 mx-auto bg-gray-50 rounded-lg shadow-md">
                            <h2 className="text-xl font-semibold text-gray-800 mb-4">Detailed Report</h2>
                            <p className="mb-2">
                                <strong>Overall Grade:</strong> {report?.overallGrade}
                            </p>
                            <p className="mb-2">
                                <strong>Overall Percentage:</strong> {report?.overallPercentage.toFixed(2)}%
                            </p>
                            <p className="mb-4">
                                <strong>Remarks:</strong> {report?.remarks}
                            </p>
                            <h3 className="text-lg font-semibold text-gray-700 mb-3">Result Summary</h3>
                            {report?.resultSummary?.length > 0 ?
                                (
                                    <div className='overflow-x-auto'>
                                        <table className="min-w-full bg-white rounded-lg border-collapse">
                                            <thead>
                                                <tr className="bg-gray-200 text-gray-700">
                                                    <th className="border border-gray-300 p-4">Exam Name</th>
                                                    <th className="border border-gray-300 p-4">Total Marks</th>
                                                    <th className="border border-gray-300 p-4">Obtained Marks</th>
                                                    <th className="border border-gray-300 p-4">Percentage</th>
                                                    <th className="border border-gray-300 p-4">Grade</th>
                                                    <th className="border border-gray-300 p-4">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {report?.resultSummary?.map((exam, index) => (
                                                    <tr
                                                        key={index}
                                                        className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"} hover:bg-gray-100`}
                                                    >
                                                        <td className="border border-gray-300 p-4">{exam.examName}</td>
                                                        <td className="border border-gray-300 p-4">{exam.totalMarks}</td>
                                                        <td className="border border-gray-300 p-4">{exam.obtainedMarks}</td>
                                                        <td className="border border-gray-300 p-4">{exam.percentage}%</td>
                                                        <td className="border border-gray-300 p-4">{exam.grade}</td>
                                                        <td className="border border-gray-300 p-4">{exam.status}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : ("")
                            }
                        </div>
                    )}
                </>
            )}


        </div>
    )
}

export default ReportsTableComp
