import api from "./apiUtility";

export const myAllAttendanceList = async (month, year) => {
    try {
        const response = await api.get(`/student/my-attendance-list?month=${month}&year=${year}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const myAttendanceSummary = async (month,year,) => {
    try {
        const response = await api.get(`/student/my-attendance-summary?year=${year}&month=${month}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const myAcademicCalendar= async (academicYear) => {
    try {
        const response = await api.get(`/student/my-academic-holiday-list?academicYear=${academicYear}`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const myTimetable = async () => {
    try {
        const response = await api.get(`/student/my-time-table`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const myUpcomingExam = async () => {
    try {
        const response = await api.get(`/student/my-upcomming-exam-list`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const StudentlatestNotice = async () => {
    try {
        const response = await api.get(`/student/latest-notices`);
        return response;
    } catch (error) {
        throw error;
    }
}

//



export const getMySubjectNameList = async () => {
    try {
        const response = await api.get(`/student/my-subject-list`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const GetStudentSubjectOverviewbyId = async (subjectId) => {
    try {
        const response = await api.get(`/student/my-subject-overview/${subjectId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllSubjectList = async () => {
    try {
        const response = await api.get(`/student/all-subject-list`);
        return response;
    } catch (error) {
        throw error;
    }
}



export const getStudyMaterialbyIdandChapterNumber = async (subjectId, chapterNumber) => {
    try {
        const response = await api.get(`/student/get-study-material?subjectId=${subjectId}&chapterNumber=${chapterNumber}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const myParentProfile = async () => {
    try {
        const response = await api.get(`/student/my-parent-profile`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const myProfile = async () => {
    try {
        const response = await api.get(`/student/my-profile`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const myResult = async () => {
    try {
        const response = await api.get(`/student/my-results`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const resultOverview = async (resultId) => {
    try {
        const response = await api.get(`/student/my-result-overview/${resultId}`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const myReport = async (reportId, academicYear) => {
    try {
        const response = await api.get(`/student/my-reports/${reportId}?academicYear=${academicYear}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const myAnalytics = async () => {
    try {
        const response = await api.get(`/student/my-analytics-overview`);
        return response;
    } catch (error) {
        throw error;
    }
}







export const myleave = async (status) => {
    try {
        const response = await api.get(`/student/my-leave?status=${status}`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const myDueAssignment = async (status) => {
    try {
        const response = await api.get(`/student/my-due-assignment`);
        return response;
    } catch (error) {
        throw error;
    }
}



export const submitDueAssignment = async (assignmentId,payload) => {
    try {
        const response = await api.patch(`/student/submit-due-assignment/${assignmentId}`,payload);
        return response;
    } catch (error) {
        throw error;
    }
}

export const mySubmittedAssignment = async () => {
    try {
        const response = await api.get(`/student/my-submitted-assignment`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const myFeeNotification = async () => {
    try {
        const response = await api.get(`/student/my-fee-notification`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const myUpcomingHoliday = async (studentClass) => {
    try {
        const response = await api.get(`/student/all-up-comming-holiday-list?studentClass=${studentClass}`);
        return response;
    } catch (error) {
        throw error;
    }
}

// 
export const myPushNotification = async () => {
    try {
        const response = await api.get(`/student/my-notifications`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const notificationDetails = async (NotificationId) => {
    try {
        const response = await api.get(`/student/push-notification-details/${NotificationId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const AllClassCategoryList = async () => {
    try {
        const response = await api.get("/all-class-category-list");
        return response;
    } catch (error) {
        throw error;
    }
}
