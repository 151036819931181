import React, { useEffect, useState } from "react";
import { Button, Autocomplete, TextField, Checkbox, FormControlLabel } from "@mui/material";
import {
    createNewAssignment,
    getAllSectionList,
    getAllClassCategoryList,
    getAllSubjectListData
} from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";
import toast from "react-hot-toast";
import { baseUrl } from "../../../utils/baseUrl";
import axios from "axios";
import { useSelector } from "react-redux";

const CreateNewAssignment = () => {
    const { token } = useSelector((state) => state.auth);
    const [sectionList, setSectionList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [previewImage, setPreviewImage] = useState(null); // To preview the uploaded image
    const [assignmentData, setAssignmentData] = useState({
        className: "",
        sections: [],
        weightage:0,
        title: "",
        instructions: "",
        dueDate: "",
        dueTime: "",
        resubmissionAllowed: false,
        subject: "",
        file: null,
    });

    // Reusable MUI styling for inputs
    const inputStyles = {
        "& .MuiOutlinedInput-root": {
            height: "45px",
            "& fieldset": { borderColor: "#d1d5db" },
            "&:hover fieldset": { borderColor: "#374151" },
            "&.Mui-focused fieldset": { borderColor: "black" },
        },
    };

    // Fetch Section and Class Lists
    useEffect(() => {
        const fetchData = async () => {
            try {
                const sectionResponse = await getAllSectionList();
                const classResponse = await getAllClassCategoryList();
                const subjectResponse = await getAllSubjectListData()
                if (sectionResponse?.status === 200) {
                    setSectionList(sectionResponse?.data?.sections || []);
                }
                if (classResponse?.status === 200) {
                    setClassList(classResponse?.data?.classCategories || []);
                }
                if (subjectResponse?.status === 200) {
                    setSubjectList(subjectResponse?.data?.subjects || []);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // Input Change Handler
    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        if (name === "file" && files.length > 0) {
            try {
                const file = files[0];
                setPreviewImage(URL.createObjectURL(file)); // Set image preview
                setAssignmentData((prevState) => ({
                    ...prevState,
                    [name]: file,
                }));
            } catch (err) {
                console.error("Image preview error:", err);
            }
        } else {
            setAssignmentData((prevState) => ({
                ...prevState,
                [name]: type === "checkbox" ? checked : value,
            }));
        }
    };

    // Class Name Change Handler
    const handleClassChange = (event, value) => {
        setAssignmentData((prevState) => ({
            ...prevState,
            className: value ? value.className : "", // Store className
        }));
    };
    const handleSectionChange = (event, value) => {
        setAssignmentData((prevState) => ({
            ...prevState,
            sections: value.map((section) => section.classSection), // Store classSection values
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Add all fields to formData except sections
            const formData = new FormData();
            Object.keys(assignmentData).forEach((key) => {
                if (key !== "sections") {
                    formData.append(key, assignmentData[key]);
                }
            });

            // Append sections array separately
            assignmentData.sections.forEach((section, index) => {
                formData.append(`section[${index}]`, section);
            });



            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }


            // Send the form data via POST request
            const response = await axios.post(
                `${baseUrl}/teacher/create-new-assignment`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`, // Corrected the syntax for Authorization header
                    }
                }
            );

            if (response?.status === 201) {
                toast.success("Successfully created Assignment");
                restForm();
            } else {
                console.error(response?.data?.message);
                toast.error("SomeThing went wrong")

            }
        } catch (error) {
            console.error("Error creating assignment:", error);
            toast.error("Internal Server Error!")
        }
    };



    const restForm = () => {
        setAssignmentData({
            className: "",
            sections: [],
            weightage: "",
            title: "",
            instructions: "",
            dueDate: "",
            dueTime: "",
            resubmissionAllowed: false,
            subject: "",
            file: null,
        })
    }

    return (
        <div className="font-poppins">
            <Headingtitle title="Create Assignment" />
            <main className="bg-white h-auto rounded-md p-5 shadow-lg">
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Class Name Dropdown */}
                        <div>
                            <label
                                htmlFor="className"
                                className="block text-sm font-medium text-gray-700 capitalize"
                            >
                                Class Name
                            </label>
                            <Autocomplete
                                options={classList}
                                getOptionLabel={(option) => option.className}
                                value={
                                    classList.find((cls) => cls.className === assignmentData.className) || null
                                }
                                onChange={handleClassChange}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" placeholder="Select Class Name" />
                                )}
                                sx={inputStyles}
                            />
                        </div>

                        {/* Sections Dropdown */}
                        <div>
                            <label
                                htmlFor="sections"
                                className="block text-sm font-medium text-gray-700 capitalize"
                            >
                                Sections
                            </label>
                            <Autocomplete
                                multiple
                                options={sectionList}
                                getOptionLabel={(option) => option.classSection}
                                value={sectionList.filter((section) =>
                                    assignmentData.sections.includes(section.classSection)
                                )}
                                onChange={handleSectionChange}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" placeholder="Select Sections" />
                                )}
                                sx={inputStyles}
                            />
                        </div>

                        {/* Dynamic Input Fields */}
                        {["title", "instructions", "weightage", "dueDate", "dueTime"].map((key) => (
                            <div key={key}>
                                <label
                                    htmlFor={key}
                                    className="block text-sm font-medium text-gray-700 capitalize"
                                >
                                    {key.replace(/([A-Z])/g, " $1")}
                                </label>
                                <input
                                    type={key.includes("Date") ? "date" : key.includes("Time") ? "time" : "text"}
                                    name={key}
                                    value={assignmentData[key]}
                                    onChange={handleChange}
                                    placeholder={`Enter ${key}`}
                                    className="w-full border border-gray-300 rounded-md px-3 py-2"
                                />
                            </div>
                        ))}

                        <div >
                            <label
                                htmlFor={"subject"}
                                className="block text-sm font-medium text-gray-700 capitalize"
                            >
                                Subject
                            </label>
                            <select
                                name={"subject"}
                                value={assignmentData["subject"]}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-[12px]"
                            >
                                <option value="">Select Subject</option>
                                {subjectList.map((item,index)=>(
                                <option key={item._id} value={item.subjectName}>{item.subjectName}</option>
                                ))}
                            </select>
                        </div>
                        {/* Resubmission Checkbox */}
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="resubmissionAllowed"
                                        checked={assignmentData.resubmissionAllowed}
                                        onChange={handleChange}
                                    />
                                }
                                label="Allow Resubmission"
                            />
                        </div>

                        {/* Image Upload */}
                        <div>
                            <label
                                htmlFor="image"
                                className="block text-sm font-medium text-gray-700 capitalize"
                            >
                                Upload Image
                            </label>
                            <input
                                type="file"
                                name="file"
                                accept="image/*"
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2"
                            />
                            {previewImage && (
                                <img
                                    src={previewImage}
                                    alt="Preview"
                                    className="mt-3 h-20 w-20 object-cover rounded-md border"
                                />
                            )}
                        </div>
                    </div>

                    {/* Submit Button */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white py-2"
                    >
                        Create Assignment
                    </Button>
                </form>
            </main>
        </div>
    );
};

export default CreateNewAssignment;
