import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, Grid } from '@mui/material';
import { getAllClassIdList, updateTeacherData } from '../../Services/AdminApis';
import { MdDeleteForever } from "react-icons/md";

const EditTeacherModal = ({ open, handleClose, teacherData, refreshData }) => {
    const [formData, setFormData] = useState(teacherData || {});
    const [classList, setClassList] = useState([]);
    const [newSpecialization, setNewSpecialization] = useState(''); // State for new specialization input

    // Define editable fields based on the payload structure
    const editableFields = ['firstName', 'middleName', 'lastName', 'phone', 'address', ];

    // Update form data on input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handle adding new specialization
    const handleSpecializationAdd = () => {
        if (newSpecialization.trim() !== '') {
            setFormData((prev) => ({
                ...prev,
                specializations: [...(prev.specializations || []), newSpecialization.trim()],
            }));
            setNewSpecialization(''); // Clear the input field
        }
    };

    const handleDeleteSpecialization = (spec) => {
        setFormData((prev) => ({
            ...prev,
            specializations: prev.specializations.filter((item) => item !== spec),
        }));
    };
    console.log(formData)


    // Handle form submission
    const handleSubmit = async () => {
        const payload = {
            name: {
                firstName: formData.firstName || '',
                middleName: formData.middleName || '',
                lastName: formData.lastName || '',
            },
            contactInfo: {
                phone: formData.phone || '',
                address: formData.address || '',
            },
            specializations: formData.specializations || [],
        };

        try {
            const response = await updateTeacherData(formData.id, payload);
            if (response.status === 200) {
                refreshData();
                handleClose();
            }
        } catch (error) {
            console.error('Error updating teacher data:', error);
        }
    };



    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const classData = await getAllClassIdList();
                if (classData.status === 200) {
                    const formattedClassList = classData.data?.classes.map((cls) => ({
                        value: cls._id,
                        label: `${cls.className}-${cls.classSection}`,
                    }));
                    setClassList(formattedClassList);
                }
            } catch (error) {
                console.error('Error fetching class list:', error);
            }
        };
        fetchClassList();
    }, []);

    return (
        <Modal open={open}>
            <Box
                className="bg-white p-5 rounded-md w-[80vw] mx-auto grid h-full place-content-center overflow-y-scroll"
                sx={{ outline: 'none' }}
            >
                <h2 className="text-xl font-bold mb-4">Edit Teacher Details</h2>
                <Grid container spacing={2}>
                    {Object.keys(formData).map((key, index) => {
                        const isEditable = editableFields.includes(key);
                        if (['sNo', 'id'].includes(key)) return null;
                        return (
                            <Grid item xs={6} key={index}>
                                <TextField
                                    fullWidth
                                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                                    name={key}
                                    value={formData[key] || ''}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                />
                            </Grid>
                        );
                    })}
                    <Grid xs={12}>
                        {/* Specialization Input and Add Button */}
                        <div className="h-[80px]  mt-2">
                            <TextField
                                label="New Specialization"
                                name="newSpecialization"
                                variant="outlined"
                                fullWidth
                                value={newSpecialization}
                                onChange={(e) => setNewSpecialization(e.target.value)}
                            />
                            <Button
                                variant="contained"
                                onClick={handleSpecializationAdd}
                                sx={{
                                    marginTop: "10px",
                                    backgroundColor: "#22577A",
                                    "&:hover": { backgroundColor: "#194F68" },
                                }}
                            >
                                Add Specialization
                            </Button>
                        </div>

                        {/* Display list of added specializations */}
                        <div className="col-span-6 md:col-span-12 mt-4">
                            <p className="font-semibold">Added Specializations:</p>
                            <ul>
                                {formData?.specializations?.map((spec, index) => (
                                    <li key={index} className="flex justify-between items-center">
                                        {spec}
                                        <MdDeleteForever
                                            sx={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                                            onClick={() => handleDeleteSpecialization(spec)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Grid>
                </Grid>


                <Box className="flex justify-end gap-4 mt-5">
                    <Button variant="contained" color="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditTeacherModal;
