import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { AdminLogin } from "../../Services/AdminPagesApis";
import toast from "react-hot-toast";
import { setToken, setUser } from "../../Redux/slices/authSlice";
import { FaEye, FaEyeSlash, FaEnvelope, FaLock } from "react-icons/fa";
import LoginLogo from "../../Assests/Logos/world.png";
import { CiBarcode } from "react-icons/ci";
import { getAcademicsYears } from "../../Services/TeachersApis";
const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email format")
        .required("Email is required"),
    password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    schoolCode: yup
        .string()
        .required("School Code is required")
});

const AdminLoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [error, setError] = useState(null);
    const isLoading = useSelector((state) => state.auth.isLoading);


    const fetchAcademicYear = async () => {
        try {
            const response = await getAcademicsYears();
            // Storing the data in localStorage
            localStorage.setItem("academicYear", JSON.stringify(response.data));
        } catch (error) {
            console.error(error)
        }
    }

    const onSubmit = async (values) => {
        try {
            const response = await AdminLogin(values);
            if (response.status === 200) {
                dispatch(setUser(response.data?.adminSchool));
                dispatch(setToken(response.data?.adminSchool?.token));
                localStorage.setItem("logo", response.data?.adminSchool?.logo);
                localStorage.setItem("user", JSON.stringify(response.data?.adminSchool));
                localStorage.setItem("token", JSON.stringify(response.data?.adminSchool?.token));
                await fetchAcademicYear()
                toast.success("Login Successfully");
                navigate("/admin/dashboard");
            }
        } catch (error) {
            console.log("error", error);
            setError("Login failed");
            toast.error(error?.response?.data?.message || "Login Successfully");
        }
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };
    return (
        <>
            <section className="min-h-screen font-poppins md:flex items-center justify-center md:bg-gray-50 hidden ">
                <div
                    style={{ height: "80vh" }}
                    className="bg-white md:w-[50vw] w-[90vw] max-w-md md:border md:border-gray-200 md:shadow-lg rounded-lg p-6 flex flex-col justify-evenly">

                    <div className=" ">
                        <img src={LoginLogo} alt="Logo" className="h-12 mx-auto mb-4" />

                        <h2
                            className="text-2xl font-bold text-center mb-2"
                            style={{
                                fontFamily: "Poppins, Arial, sans-serif",
                                color: "#000",
                            }}
                        >
                            Welcome Back
                        </h2>
                        <p
                            className="text-center mb-4"
                            style={{
                                fontFamily: "Poppins, Arial, sans-serif",
                                color: "#4B5563",
                                fontSize: "14px",
                            }}
                        >     Today is a new day. It's your day. You shape it.
                            Sign in to start managing your projects.
                        </p>
                    </div>

                    <Formik
                        initialValues={{ email: "", password: "", schoolCode: "" }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form className="flex flex-col  p-2 rounded-md">
                                <div className="mb-4 h-[80px]">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium mb-1"
                                        style={{
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#000",
                                        }}
                                    >    Email
                                    </label>
                                    <div className="relative">
                                        <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                                        <Field
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="Email or Mobile No."
                                            className="w-full pl-10 p-2 placeholder:text-[0.8rem] placeholder:font-semibold border border-border-50 rounded-full focus:outline-none"
                                        />
                                    </div>
                                    <ErrorMessage name="email" component="p" className="text-red-500 text-[0.8rem] mt-1" />
                                </div>

                                <div className="mb-4 h-[80px]">
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium mb-1"
                                        style={{
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#000",
                                        }}
                                    >      Password
                                    </label>
                                    <div className="relative rounded-full">
                                        <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                                        <Field
                                            id="password"
                                            name="password"
                                            type={isPasswordVisible ? "text" : "password"}
                                            placeholder="Password"
                                            autoComplete="current-password"
                                            className="w-full pl-10 p-2 placeholder:text-[0.8rem] placeholder: border border-border-50 rounded-full focus:outline-none"
                                        />
                                        <span
                                            onClick={togglePasswordVisibility}
                                            className="absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer p-2"
                                        >
                                            {isPasswordVisible ? (
                                                <FaEye className="h-6 w-6 text-gray-500" />
                                            ) : (
                                                <FaEyeSlash className="h-6 w-6 text-gray-500" />
                                            )}
                                        </span>
                                    </div>
                                    <ErrorMessage name="schoolCode" component="p" className="text-red-500 text-[0.8rem] mt-1" />
                                </div>
                                <div className="mb-4 h-[80px]">
                                    <label
                                        htmlFor="schoolCode"
                                        className="block text-sm font-medium mb-1"
                                        style={{
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#000",
                                        }}
                                    >
                                        School Code
                                    </label>
                                    <div className="relative">
                                        {/* Icon positioned within the input field */}
                                        <CiBarcode className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700 text-lg" />
                                        <Field
                                            id="schoolCode"
                                            name="schoolCode"
                                            type="text"
                                            placeholder="School Code"
                                            className="w-full pl-10 p-2 text-sm placeholder:text-gray-400 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        />
                                    </div>
                                    <ErrorMessage
                                        name="schoolCode"
                                        component="p"
                                        className="text-red-500 text-[0.8rem] mt-1"
                                    />
                                </div>

                                <Link to="/admin-reset-password">
                                    <span className="float-right cursor-pointer italic  text-blue-500 hover:text-blue-700 text-[0.8rem] underline">
                                        Forgot password
                                    </span>
                                </Link>

                                <button
                                    type="submit"
                                    className="bg-[#22577A]  text-[0.9rem] text-[#FFFFFF] p-2 rounded-full mt-2 hover:bg-[#22577A] transition"
                                    disabled={isLoading || isSubmitting}
                                >
                                    {isLoading ? "Logging in..." : "Login as Admin"}
                                </button>

                                {error && <p className="text-red-500 mt-2">{error}</p>}
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>

            <section className="w-[100vw] min-h-screen md:hidden rounded-lg flex justify-evenly items-center">
                <div className="w-[90vw] h-[80vh] mx-auto p-5 text-start text-[0.81rem]" >
                    <div className=" mb-6">
                        <img src={LoginLogo} alt="Logo" className="h-12 mx-auto mb-4" />

                        <h1 className="text-2xl font-semibold">Welcome Back</h1>
                        <p className="text-[#4B5563] font-semibold text-[14px] font-poppins ">
                            Today is a new day. It's your day. You shape it.
                            Sign in to start managing your projects.
                        </p>
                    </div>
                    <Formik
                        initialValues={{ email: "", password: "", schoolCode: "" }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form className="flex flex-col max-w-[100%] mx-auto p-2 mt-5 rounded-md">
                                <div className="mb-4 h-[80px]">
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium mb-1"
                                        style={{
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#000",
                                        }}
                                    >      Email
                                    </label>
                                    <div className="relative">
                                        <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                                        <Field
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="Email or Mobile No."
                                            className="w-full pl-10 p-2 placeholder:text-[0.8rem] placeholder:font-semibold border border-border-50 rounded-full focus:outline-none"
                                        />
                                    </div>
                                    <ErrorMessage name="email" component="p" className="text-red-500 text-[0.8rem] mt-1" />
                                </div>

                                <div className="mb-4 h-[80px]">
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium mb-1"
                                        style={{
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#000",
                                        }}
                                    >      Password
                                    </label>
                                    <div className="relative rounded-full">
                                        <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                                        <Field
                                            id="password"
                                            name="password"
                                            type={isPasswordVisible ? "text" : "password"}
                                            placeholder="Password"
                                            autoComplete="current-password"
                                            className="w-full pl-10 p-2 placeholder:text-[0.8rem] placeholder:font-semibold border border-border-50 rounded-full focus:outline-none"
                                        />
                                        <span
                                            onClick={togglePasswordVisibility}
                                            className="absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer p-2"
                                        >
                                            {isPasswordVisible ? (
                                                <FaEye className="h-6 w-6 text-gray-500" />
                                            ) : (
                                                <FaEyeSlash className="h-6 w-6 text-gray-500" />
                                            )}
                                        </span>
                                    </div>
                                    <ErrorMessage name="password" component="p" className="text-red-500 text-[0.8rem] mt-1" />
                                </div>

                                <div className="mb-4 h-[80px]">
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium mb-1"
                                        style={{
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#000",
                                        }}
                                    >     School Code
                                    </label>
                                    <div className="relative">
                                        <Field
                                            id="schoolCode"
                                            name="schoolCode"
                                            type="text"
                                            placeholder="School Code"
                                            className="w-full pl-10 p-2 placeholder:text-[0.8rem] placeholder:font-semibold border border-border-50 rounded-full focus:outline-none"
                                        />
                                    </div>
                                    <ErrorMessage name="schoolCode" component="p" className="text-red-500 text-[0.8rem] mt-1" />
                                </div>

                                <Link to="/admin-reset-password">
                                    <span className="float-right cursor-pointer italic font-semibold text-blue-500 hover:text-blue-700 text-[0.8rem] underline">
                                        Forgot password
                                    </span>
                                </Link>

                                <button
                                    type="submit"
                                    className="bg-[#22577A] font-semibold text-[0.9rem] text-[#FFFFFF] p-2 rounded-full mt-2 hover:bg-[#22577A] transition"
                                    disabled={isLoading || isSubmitting}
                                >
                                    {isLoading ? "Logging in..." : "Login as Admin"}
                                </button>

                                {error && <p className="text-red-500 mt-2">{error}</p>}
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
        </>
    );
};

export default AdminLoginForm;
