import React from 'react'
import Headingtitle from './Headingtitle'
import { AiOutlineCheck} from "react-icons/ai";
import { formatDateTime } from '../../utils/utilityFunction';

const NotificationComp = ({ isPending, notificationList, handleNavigate }) => {
    return (
        <div>
            <div className="font-poppins">
                <Headingtitle title="Notification" />
                <main className="bg-white relative w-full rounded-md p-5 min-h-[80vh]">
                    {/* Main Section */}
                    {isPending ? (
                        <p className="text-center text-gray-500">Loading notifications...</p>
                    ) : notificationList?.length > 0 ? (
                        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            {notificationList?.map((notification) => (
                                <div
                                    onClick={() => handleNavigate(notification._id)}
                                    key={notification._id}
                                    className={`${notification.status?'bg-[azure]':'bg-[white]'} rounded-lg cursor-pointer shadow-md hover:shadow-lg transition-shadow p-4 border`}
                                >
                                    <h3 className="text-xl font-semibold mb-2 text-gray-800">{notification.title}</h3>
                                    <p className="text-gray-600 mb-4">{notification.messageBody}</p>
                                    <small className="text-gray-500">
                                        {formatDateTime(notification.createdAt)}
                                    </small>
                                    {notification.status ? (
                                        <div className="flex items-center justify-end text-green-500">
                                            <AiOutlineCheck color="green" className="w-4 h-4 " />
                                        </div>
                                    ) : (
                                        <div className="flex items-center justify-end text-gray-400">
                                            <AiOutlineCheck className="w-4 h-4" />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-center text-gray-500">No notifications available.</p>
                    )}
                </main >
            </div>



        </div>
    )
}

export default NotificationComp
