import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSubjectOverView, deleteStudyMaterial, chapterOverview } from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import toast from "react-hot-toast";

const SubjectOverViewDetails = () => {
    const { subjectId } = useParams();
    const [loading, setLoading] = useState(false);
    const [subjectChapterList, setSubjectChapterList] = useState([])
    const [selectedSubject, setSelectedSubject] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const fetchSubjectOverview = async () => {
        setLoading(true);
        try {
            const response = await getSubjectOverView(subjectId);
            if (response.status === 200) {
                setSubjectChapterList(response?.data?.chapters || []);
                setSelectedSubject(response?.data)
            } else {
                console.log("Error:", response?.data?.message);
                setSubjectChapterList([]);
            }
        } catch (error) {
            console.error("Error fetching attendance data:", error);
            setSubjectChapterList([]);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchSubjectOverview();
    }, [subjectId]);

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null);
    };
    const handleOpen = async (chapterNumber) => {
        try {
            const response = await chapterOverview(subjectId);
            if (response.status === 200) {
                setSelectedId(response.data._id);
                setOpen(true);
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            toast.error(error.response?.data?.message || "Error deleting record");
        }

    };

    const handleDelete = async () => {
        try {
            const response = await deleteStudyMaterial(selectedId);
            if (response.status === 200) {
                toast.success("Deleted successfully");
                fetchSubjectOverview()
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            toast.error(error.response?.data?.message || "Error deleting record");
        } finally {
            handleClose();
        }
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Subject" />

            {/* Month and Year Select */}
            <main className="bg-white min-h-[80vh] rounded-lg p-6 shadow-lg">
                <p className="text-[1.2rem] font-semibold pb-4 text-gray-800">Subject Overview Detail</p>
                <div>
                    {loading ? (
                        <p className="text-gray-500">Loading...</p>
                    ) : subjectChapterList && subjectChapterList.length > 0 ? (
                        <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md">
                            <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                                <tr>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Chapter Number</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Title</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Description</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Objectives</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Resources</th>
                                    {/* <th className="px-6 py-4 text-left text-[16px] font-bold">Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {subjectChapterList.map((chapter, index) => (
                                    <tr
                                        key={index}
                                        className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                            } hover:bg-indigo-100 transition-all`}
                                    >
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">{chapter.chapterNumber}</td>
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">{chapter.title}</td>
                                        <td className="px-6 py-4 text-gray-600 text-[14px]">{chapter.description}</td>
                                        <td className="px-6 py-4 text-gray-600 text-[14px]">{chapter.objectives}</td>
                                        <td className="px-6 py-4 text-gray-700 text-[14px]">
                                            {chapter.resources.length > 0 ? (
                                                <ul className="list-disc pl-4 space-y-1">
                                                    {chapter.resources.map((resource, resourceIndex) => (
                                                        <li key={resourceIndex}>
                                                            <a
                                                                href={resource}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-indigo-600 hover:underline"
                                                            >
                                                                {resource}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <span className="text-gray-400">No resources available</span>
                                            )}
                                        </td>
                                        {/* <td className="px-6 py-4 text-gray-600 text-[14px]" onClick={() => handleOpen(chapter._id)}><DeleteIcon /></td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-gray-500">No data available</p>
                    )}
                </div>
            </main>
            {/* Confirmation Dialog */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this record? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SubjectOverViewDetails;
