import React, { useEffect, useState, useTransition } from 'react';
import { Timetable, getAllClassIdList, UpdatedTimetable, GetOnboardedTeacherList, getAllSubjectNameList, deleteTimetable } from '../../../Services/AdminApis';
import Headingtitle from '../../Common/Headingtitle';
import {
    Button,
    Grid,
    Select,
    MenuItem,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemText,
    DialogActions as DialogActionsConfirm,
    DialogContentText,
} from "@mui/material";
import toast from 'react-hot-toast';

const TimeTableList = () => {
    const [timeTableList, setTimeTableList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [teacherList, setTeacherList] = useState([]);
    const [subjectName, setSubjectName] = useState([]);
    const [selectedClass, setSelectedClass] = useState('');
    const [isPending, startTransition] = useTransition();
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [editForm, setEditForm] = useState({
        subject: '',
        teacher: '',
        timeSlot: { start: '', end: '' },
        day: []
    });

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const response = await getAllClassIdList();
                setClassList(response?.data?.classes || []);
            } catch (error) {
                console.error("Error fetching class list:", error);
            }
        };
        fetchClassList();
    }, []);

    const fetchTimetable = async () => {
        try {
            const response = await Timetable(selectedClass);
            startTransition(() => {
                setTimeTableList(response?.data?.timetables || []);
            });
        } catch (error) {
            console.error("Error fetching timetable:", error);
        }
    };
    useEffect(() => {
        fetchTimetable();
    }, [selectedClass]);

    const handleEdit = async (data) => {
        setCurrentData(data);
        const subjectResponse = await getAllSubjectNameList();
        const teacherList = await GetOnboardedTeacherList();
        setTeacherList(teacherList?.data?.teachers || []);
        setSubjectName(subjectResponse.data.subjects);
        setEditForm({
            subject: data.subject,
            teacher: data.teacher?._id || "N/A",
            timeSlot: data.timeSlot,
            day: data.day
        });
        setShowEditModal(true);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        if (name === 'day') {
            setEditForm((prev) => ({ ...prev, day: value }));
        } else if (name === 'start' || name === 'end') {
            setEditForm((prev) => ({
                ...prev,
                timeSlot: { ...prev.timeSlot, [name]: value },
            }));
        } else {
            setEditForm((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleUpdate = async () => {
        try {
            const response = await UpdatedTimetable(currentData._id, editForm);
            if (response?.status === 200) {
                toast.success("Timetable updated successfully!");
                setShowEditModal(false);
                fetchTimetable();
            }
        } catch (error) {
            console.error("Error updating timetable:", error);
        }
    };

    const closeEditModal = () => {
        setShowEditModal(false);
        setCurrentData(null);
    };

    const handleDelete = (timetableId) => {
        setCurrentData({ _id: timetableId });
        setShowDeleteConfirmation(true);
    };

    const confirmDelete = async () => {
        try {
            const response = await deleteTimetable(currentData._id);
            if (response?.status === 200) {
                toast.success("Timetable deleted successfully!");
                setShowDeleteConfirmation(false);
                fetchTimetable();
            }
        } catch (error) {
            console.error("Error deleting timetable:", error);
        }
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
        setCurrentData(null);
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Time Table List" />
            <main className="bg-white shadow-md rounded-md p-5">
                <p className="font-semibold pb-2 text-[1rem]">Time Table List</p>

                <div className="mb-4">
                    <select
                        value={selectedClass}
                        onChange={(e) => setSelectedClass(e.target.value)}
                        className="border px-3 py-2 rounded w-full md:w-1/2"
                    >
                        <option value="">Select Class</option>
                        {classList.map((cls) => (
                            <option key={cls._id} value={cls._id}>
                                {cls.className} - {cls.classSection}
                            </option>
                        ))}
                    </select>
                </div>
                {isPending ? 
                (
                    <p>Loading...</p>
                ) : (
                        <div className="overflow-x-auto">
                            <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                                <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                                    <tr>
                                    <th className="border px-4 py-3 text-xs lg:text-sm">Class</th>
                                    <th className="border px-4 py-3 text-xs lg:text-sm">Subject</th>
                                    <th className="border px-4 py-3 text-xs lg:text-sm">Teacher</th>
                                    <th className="border px-4 py-3 text-xs lg:text-sm">Day</th>
                                    <th className="border px-4 py-3 text-xs lg:text-sm">Time Slot</th>
                                    <th className="border px-4 py-3 text-xs lg:text-sm">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {timeTableList.map((timeTable, index) => (
                                    <tr key={timeTable._id} className="text-xs lg:text-sm">
                                        <td className="border px-4 py-2">{timeTable.class.className}-{timeTable.class.classSection}</td>
                                        <td className="border px-4 py-2">{timeTable.subject}</td>
                                        <td className="border px-4 py-2">{timeTable?.teacher?.name?.firstName}</td>
                                        <td className="border px-4 py-2">{timeTable.day.join(', ')}</td>
                                        <td className="border px-4 py-2">
                                            {timeTable.timeSlot.start} - {timeTable.timeSlot.end}
                                        </td>
                                        <td className=" flex gap-2 border px-4 py-2">
                                            <button
                                                onClick={() => handleEdit(timeTable)}
                                                className="bg-indigo-500 w-full text-white px-3 py-1 rounded text-xs lg:text-sm"
                                            >
                                                Edit
                                            </button>

                                            <button
                                                onClick={() => handleDelete(timeTable._id)}
                                                className="bg-indigo-500 w-full text-white px-3 py-1 rounded text-xs lg:text-sm"
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {showEditModal && (
                    <Dialog open={showEditModal} onClose={closeEditModal} fullWidth maxWidth="sm">
                        <DialogTitle>Edit Timetable</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Subject</InputLabel>
                                        <Select
                                            name="subject"
                                            value={editForm.subject}
                                            onChange={handleFormChange}
                                        >
                                            {subjectName.map((subject) => (
                                                <MenuItem key={subject.id} value={subject.subjectName}>
                                                    {subject.subjectName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Teacher</InputLabel>
                                        <Select
                                            name="teacher"
                                            value={editForm?.teacher}
                                            onChange={handleFormChange}
                                        >
                                            {teacherList.map((teacher) => (
                                                <MenuItem key={teacher._id} value={teacher?._id}>
                                                    {teacher.name.firstName} {teacher.name.lastName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Time Slot Start"
                                        type="time"
                                        name="start"
                                        value={editForm.timeSlot.start}
                                        onChange={handleFormChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Time Slot End"
                                        type="time"
                                        name="end"
                                        value={editForm.timeSlot.end}
                                        onChange={handleFormChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Days</InputLabel>
                                        <Select
                                            name="day"
                                            multiple
                                            value={editForm.day}
                                            onChange={handleFormChange}
                                            renderValue={(selected) => selected.join(", ")}
                                        >
                                            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
                                                (day) => (
                                                    <MenuItem key={day} value={day}>
                                                        <Checkbox checked={editForm.day.includes(day)} />
                                                        <ListItemText primary={day} />
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={handleUpdate}>
                                Save Changes
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={closeEditModal}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}

                {/* Delete Confirmation Dialog */}
                {showDeleteConfirmation && (
                    <Dialog open={showDeleteConfirmation} onClose={closeDeleteConfirmation}>
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete this timetable entry?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActionsConfirm>
                            <Button onClick={confirmDelete} color="primary">
                                Yes, Delete
                            </Button>
                            <Button onClick={closeDeleteConfirmation} color="secondary">
                                Cancel
                            </Button>
                        </DialogActionsConfirm>
                    </Dialog>
                )}
            </main>
        </div>
    );
};

export default TimeTableList;
