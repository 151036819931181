import React, { useEffect, useState } from "react";
import { myLeaveApplications, deleteMyLeaveApplication } from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";
import { toast } from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { formatName } from "../../../utils/utilityFunction";

const TeacherLeaveList = () => {
    const [studentLeaveList, setStudentLeaveList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null);
    };
    const handleOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
    };

    const fetchMyLeaveApplications = async () => {
        setLoading(true);
        try {
            const response = await myLeaveApplications();
            setStudentLeaveList(response?.data|| []);
        } catch (error) {
            console.error("Error fetching student leave list:", error);
            setStudentLeaveList([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMyLeaveApplications();
    }, []);

    
    const handleDelete = async () => {
        try {
            const response = await deleteMyLeaveApplication(selectedId);
            if (response.status === 200) {
                toast.success("Deleted successfully");
                fetchMyLeaveApplications()
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            toast.error(error.response?.data?.message || "Error deleting record");
        } finally {
            handleClose();
        }
    };


    return (
        <div>
            <div className="font-poppins">
                <Headingtitle title="Leaves" />
                <main className="bg-white shadow-md rounded-md p-5">
                    <p className="font-semibold pb-2 text-[1rem]">Leaves List</p>
                    <div>
                        {loading ? (
                            <p className="text-gray-500">Loading...</p>
                        ) : studentLeaveList && studentLeaveList.length > 0 ? (
                            <div className="overflow-x-auto">
                                <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                                    <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                                        <tr>
                                            <th className="px-6 py-4 text-left text-[16px] font-bold">Date</th>
                                            <th className="px-6 py-4 text-left text-[16px] font-bold">Teacher Name</th>
                                            <th className="px-6 py-4 text-left text-[16px] font-bold">Reason</th>
                                            <th className="px-6 py-4 text-left text-[16px] font-bold">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentLeaveList.map((record, index) => (
                                            <tr
                                                key={record._id}
                                                className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                                    } hover:bg-indigo-100 transition-all`}
                                            >
                                                <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                    {new Date(record.startDate).toLocaleDateString()} -{" "}
                                                    {new Date(record.endDate).toLocaleDateString()}
                                                </td>
                                                <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                    {formatName(record.teacherId)}
                                                </td>
                                                <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                    {record.reason}
                                                </td>
                                                <td
                                                    onClick={() => handleOpen(record._id)}
                                                    className="px-4 py-2 border border-gray-300 cursor-pointer"
                                                >
                                                    <DeleteIcon />
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="text-gray-500 mt-6">No Leave Applications Found</p>
                        )}
                    </div>
                </main>
            </div>

            {/* Confirmation Dialog */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this record? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TeacherLeaveList;
