import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Headingtitle from '../../Common/Headingtitle';
import { getAlltranportRoute,deleteATransportRoute,removeAStudentFromATransportRoute } from '../../../Services/AdminApis';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField } from '@mui/material';
import { toast } from 'react-hot-toast';
import RemoveStudentFromRoute from './RemoveStudentFromRoute'

const TransportRouteList = () => { 
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [allTraportRoute,setAllTransportRoute]=useState([])

  const fetchRoutes = async () => {
    try {
      const response = await getAlltranportRoute();
      const routeData = response.data.routes;
      if(response.status===200){
        setAllTransportRoute(routeData)
      }
      const formattedRows = routeData.map((route, index) => ({
        sNo: index + 1,
        id:route._id,
        vehicleNumber: route.vehicle.vehicleNumber,
        driverName: route.vehicle.driverName,
        routeNumber: route.vehicle.routeNumber,
        emergencyContactNumber: route.vehicle.emergencyContactNumber,
        currentLocation: route.vehicle.currentLocation,
      }));

      setRoutes(formattedRows);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching routes:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoutes();
  }, []);

  const handleEdit =async (row) => {
    setEditModalOpen(true);
    setSelectedRow(row);
  };

  const onRemoveStudent = async ({ routeId, studentId }) => {
    try {
      const response = await removeAStudentFromATransportRoute({ routeId, studentId });
      if (response.status===200) {
        toast.success("Student removed from Route successfully")
        setEditModalOpen(false);
        setSelectedRow("");
        fetchRoutes()
      }
    } catch (error) {
      console.error('Error removing student:', error);
      toast.error(error.response.data.message ||"Something went wrong")

    }
  };
  

  const handleDelete = (row) => {
    setSelectedRow(row);
    setDeleteDialogOpen(true);
    fetchRoutes()
  };

  const confirmDelete = async() => {
    try {
      const response = await deleteATransportRoute(selectedRow);
      if(response.status===200){
        toast.success("Transport Route deleted successfully")
       setDeleteDialogOpen(false);
      }
    } catch (error) {
      toast.error(error.response.data.message ||"Something went wrong")
      console.log(error)
    }
  
  };

  const columns = [
    { field: 'sNo', headerName: 'No.', width: 70 },
    { field: 'vehicleNumber', headerName: 'Vehicle Number', width: 150 },
    { field: 'driverName', headerName: 'Driver Name', width: 150 },
    { field: 'routeNumber', headerName: 'Route Number', width: 150 },
    { field: 'emergencyContactNumber', headerName: 'Emergency Contact', width: 180 },
    {
      field: 'coordinates',
      headerName: 'Location Coordinates',
      width: 250,
      renderCell: (params) => {
        const { coordinates } = params.row.currentLocation;
        return `${coordinates[0]}, ${coordinates[1]}`;
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => handleDelete(params.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div style={{ marginTop: '20px' }}>
      <Headingtitle title="Transport Routes" />
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <DataGrid
          style={{ backgroundColor: 'white', minWidth: '800px' }}
          rows={routes}
          columns={columns}
          loading={loading}
          pageSize={10}
          slots={{ toolbar: GridToolbar }}
          checkboxSelection
        />
      </div>

      {/* Edit Modal */}
      <Modal open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <div style={{ padding: 20, backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: '50%' }}>
          <h2>Edit Route</h2>
          <RemoveStudentFromRoute response={allTraportRoute} routeId={selectedRow?.id} onRemoveStudent={onRemoveStudent} />
          <Button variant="contained" color="primary" onClick={() => setEditModalOpen(false)} style={{ marginTop: 20 }}>
            Cancel
          </Button>
        </div>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TransportRouteList;
