import React, { useEffect, useState } from 'react';
import { createNewHoliday } from '../../../Services/AdminApis';
import { Button, TextField, Checkbox, FormControlLabel, FormGroup, Box, CircularProgress, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import toast from 'react-hot-toast';
import Headingtitle from '../../Common/Headingtitle';
import { GetClassCategoryList } from '../../../Services/AdminApis';
import { getAcademicsYears } from '../../../Services/TeachersApis';
const CreateHoliday = () => {
    const [classCategories, setClassCategories] = useState([]);
    const [yearList, setYearList] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
        date: '',
        description: '',
        academicYear: '',
        applicableClasses: [],
        isForAllClasses: true, // Toggle between 'All' or specific classes
    });
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    console.log("jbbh b h")

    useEffect(() => {
        const fetchClassCategoryList = async () => {
            try {
                const response = await GetClassCategoryList();
                setClassCategories(response?.data?.classCategories || []);
            } catch (error) {
                console.error("error");
            }
        }
        fetchClassCategoryList();
    }, [])

    useEffect(() => {
        const fetchYearList = async () => {
            try {
                const academicResponse = await getAcademicsYears();
                // console.log("responsei for year List", response?.data)
                if (academicResponse?.status === 200) {
                    setYearList(academicResponse?.data || []);
                }

            }
            catch (error) {
                console.log("error", error);
            }

        }
        fetchYearList();
    }, [])

    const handleClassToggle = () => {
        setFormData((prev) => ({
            ...prev,
            isForAllClasses: !prev.isForAllClasses,
            applicableClasses: prev.isForAllClasses ? [] : 'All', // Reset applicableClasses if toggled
        }));
    };

    const handleClassChange = (e) => {
        const { value, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            applicableClasses: checked
                ? [...prev.applicableClasses, value]
                : prev.applicableClasses.filter((cls) => cls !== value),
        }));
    };
    const handleSubmit = async () => {
        // Extract year from selected academicYear
        const [startYear, endYear] = formData.academicYear.split("-").map(Number);
        const eventYear = new Date(formData.date).getFullYear();

        // Validation Checks
        if (!formData.name) {
            toast.error("Event name is required.");
            return;
        }
        if (!formData.academicYear) {
            toast.error("Please select an academic year.");
            return;
        }
        if (!formData.date) {
            toast.error("Event date is required.");
            return;
        }
        if (eventYear < startYear || eventYear > endYear) {
            toast.error(`Event date must fall within the academic year ${formData.academicYear}.`);
            return;
        }
        if (!formData.description) {
            toast.error("Event description is required.");
            return;
        }
        if (!formData.isForAllClasses && formData.applicableClasses.length === 0) {
            toast.error("Please select at least one class or apply to all classes.");
            return;
        }

        // Prepare the payload
        const payload = {
            name: formData.name,
            academicYear: formData.academicYear,
            date: formData.date,
            description: formData.description,
            applicableClasses: formData.isForAllClasses ? "All" : formData.applicableClasses,
        };

        console.log(payload);

        setLoading(true);
        try {
            const response = await createNewHoliday(payload);
            if (response?.status) {
                toast.success("Event created successfully!");
                setFormData({
                    name: "",
                    date: "",
                    description: "",
                    applicableClasses: [],
                    isForAllClasses: true,
                });
            }
        } catch (error) {
            console.error("Error creating event:", error);
            toast.error("Failed to create event.");
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="font-poppins">
            <Headingtitle title="Holiday" />
            <main className="bg-white min-h-[80vh] rounded-md p-5">
                <p className="text-[1rem] font-semibold pb-2">Create New Holiday</p>
                <form>
                    <div className="grid w-full grid-cols-1 gap-2 text-[0.77rem]">
                        <Box className="flex flex-row gap-4 items-center">
                            {/* Event Name */}
                            <TextField
                                label="Event Name"
                                name="name"
                                fullWidth
                                margin="normal"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />

                            {/* Academic Year */}
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="academicYear-label">Academic Year</InputLabel>
                                <Select
                                    labelId="academicYear-label"
                                    id="academicYear"
                                    name="academicYear"
                                    value={formData.academicYear}
                                    onChange={handleInputChange}
                                    displayEmpty
                                >
                                    {yearList?.map((y) => (
                                        <MenuItem key={y._id} value={y.year}>
                                            {y.year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        {/* Event Date */}
                        <TextField
                            label="Event Date"
                            name="date"
                            type="date"
                            fullWidth
                            margin="normal"
                            value={formData.date}
                            onChange={handleInputChange}
                            InputLabelProps={{ shrink: true }}
                            required
                        />

                        {/* Event Description */}
                        <TextField
                            label="Event Description"
                            name="description"
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            value={formData.description}
                            onChange={handleInputChange}
                            required
                            sx={{

                            }}
                        />

                        {/* Applicable Classes */}
                        <FormGroup sx={{ height: "180px", backgroundColor: "" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.isForAllClasses}
                                        onChange={handleClassToggle}
                                    />
                                }
                                label="Applicable to All Classes"
                            />
                            {!formData.isForAllClasses && (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', }}>
                                    {classCategories?.map((cls, index) => (
                                        <FormControlLabel
                                            key={cls._id}
                                            control={
                                                <Checkbox
                                                    value={cls.className}
                                                    checked={formData.applicableClasses.includes(cls.className)}
                                                    onChange={handleClassChange}
                                                />
                                            }
                                            label={`Class ${cls?.className}`}
                                        />
                                    ))}
                                </Box>
                            )}
                        </FormGroup>

                        {/* Submit Button */}
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 3 }}
                            onClick={handleSubmit}
                            disabled={loading || !formData.name || !formData.date || !formData.description}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Create Event'}
                        </Button>
                    </div>
                </form>
                {/* </Box> */}
            </main>
        </div>
    );
};

export default CreateHoliday;
