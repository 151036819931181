import React, { useEffect, useState, useTransition } from 'react';
import { TextField, Button, Box, MenuItem } from "@mui/material";
import { OnboardNewStudent, getAllClassIdList } from "../../../Services/AdminApis";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import toast from 'react-hot-toast';
import { getAcademicsYears } from '../../../Services/TeachersApis';
import OnboardClipboardModel from '../../../Layout/Models/OnboardClipboardModel';
import Headingtitle from '../../Common/Headingtitle';
const OnboardStudent = () => {
    const [isModalOpen, setIsModalOpen] = useState(null);
    const [isPending, startTransition] = useTransition();
    const [yearList, setYearList] = useState([]);
    const [error, setError] = useState('');

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [AllClassIds, setAllClassIds] = useState([]); // State to store all class IDs
    const [formData, setFormData] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        classId: "",
        avatar: '',
        mobile: "",
        dateOfBirth: '',
        address: "",
        gender: '',
        rollNumber: '',
        academicYear: '',
        admissionDate: '',
    });

    const handleOpenModel = (data) => {
        const { studentId, password, email } = data;
        setIsModalOpen({
            codeId: studentId,
            password: password,
            email: email,
        })
    }

    const handleClose = () => {
        setIsModalOpen(null);
    }

    useEffect(() => {
        const fetchAcademicYears = async () => {
            try {
                const response = await getAcademicsYears();
                startTransition(() => {
                    setYearList(response?.data || []);
                });
            } catch (error) {
                console.error("Error fetching academic years:", error);
            }
        };

        fetchAcademicYears();
    }, []);

    useEffect(() => {
        const getAllClassID = async () => {
            try {
                const response = await getAllClassIdList(); // Assuming this function gets the list of classes
                if (response?.data) {
                    setAllClassIds(response?.data?.classes); // Store class IDs in the state
                }
            } catch (error) {
                console.error('Error fetching class IDs:', error);
            }
        };

        getAllClassID();
    }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (!file) {
            // Handle case where no file is selected
            setError("Please select a file.");
            setFormData((prev) => ({
                ...prev,
                avatar: null,
            }));
            return;
        }

        // Check file size (300KB = 307200 bytes)
        if (file.size > 307200) {
            setError("File size should be less than 300KB.");
            setFormData((prev) => ({
                ...prev,
                avatar: null,
            }));
            return;
        }

        // Clear any existing error and update the form data
        setError("");
        setFormData((prevData) => ({ ...prevData, avatar: file }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("data", formData);
        // Validate form if needed (uncomment validateForm function for validation)
        // if (!validateForm()) return;

        const formDataToSend = new FormData();
        for (const key in formData) {
            if (key === 'avatar' && formData.avatar) {
                formDataToSend.append(key, formData.avatar);
            } else {
                formDataToSend.append(key, formData[key]);
            }
        }

        setLoading(true);

        try {
            const response = await OnboardNewStudent(formDataToSend);  // Update with your API function
            if (response.status === 201 || response.status === 200) {
                const ModelData = response?.data?.loginCredentials || {}
                handleOpenModel(ModelData);
                toast.success("Student onboarded successfully!")
                setFormData({
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    email: "",
                    classId: null,
                    avatar: '',
                    mobile: "",
                    dateOfBirth: '',
                    address: "",
                    gender: '',
                    rollNumber: '',
                    academicYear: '',
                    admissionDate: '',
                });
                setErrors({});
                setError('')
            }
            else {
                console.error(response.message)
                // Handle success logic (e.g., redirect or clear form)
                toast.error("Something went wrong")

            }
        } catch (error) {
            console.error("Failed to submit form", error);
            toast.error("Failed to onboard student. Please try again.")
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Student Onboard" />
            <main className="bg-white min-h-[80vh] rounded-md p-5">
                <p className="text-[1rem] font-semibold pb-2">Student Onboard</p>
                <form onSubmit={handleSubmit}>
                    <div className="grid w-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5 text-[0.77rem]">
                        <div className="h-[80px]">
                            <TextField
                                label="First Name"
                                name="firstName"
                                variant="outlined"
                                fullWidth
                                value={formData.firstName}
                                onChange={handleChange}
                                helperText={errors.firstName}
                                error={Boolean(errors.firstName)}
                                sx={textFieldStyles}
                            />
                        </div>
                        {/* Middle Name */}
                        <div className="h-[80px]">
                            <TextField
                                label="Middle Name"
                                name="middleName"
                                variant="outlined"
                                fullWidth
                                value={formData.middleName}
                                onChange={handleChange}
                                sx={textFieldStyles}
                            />
                        </div>
                        {/* Last Name */}
                        <div className="h-[80px]">
                            <TextField
                                label="Last Name"
                                name="lastName"
                                variant="outlined"
                                fullWidth
                                value={formData.lastName}
                                onChange={handleChange}
                                helperText={errors.lastName}
                                error={Boolean(errors.lastName)}
                                sx={textFieldStyles}
                            />
                        </div>
                        {/* Email */}
                        <div className="h-[80px]">
                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                variant="outlined"
                                fullWidth
                                value={formData.email}
                                onChange={handleChange}
                                helperText={errors.email}
                                error={Boolean(errors.email)}
                                sx={textFieldStyles}
                            />
                        </div>
                        {/* Class ID */}
                        <div className="h-[80px]">
                            <TextField
                                label="Class ID"
                                name="classId"
                                variant="outlined"
                                fullWidth
                                select
                                value={formData.classId}
                                onChange={handleChange}
                                helperText={errors.classId}
                                error={Boolean(errors.classId)}
                                sx={{ marginBottom: 2 }}
                            >
                                {AllClassIds.length > 0 ? (
                                    AllClassIds.map((classItem) => (
                                        <MenuItem key={classItem._id} value={classItem._id}>
                                            {`${classItem.className} ${classItem.classSection} (${classItem.classStream})`}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>No classes available</MenuItem>
                                )}
                            </TextField>
                        </div>
                        {/* Mobile Input */}
                        <div className="h-[80px]">
                            <PhoneInput
                                id="mobile"
                                country={'in'}
                                value={formData.mobile}
                                onChange={(value) => setFormData({ ...formData, mobile: value })}
                                containerClass="w-full rounded-lg"
                                inputStyle={{
                                    width: '100%',
                                    borderRadius: "8px",
                                    height: "100%",
                                    border: "2px solid #EBEDF2"
                                }}
                                inputClass="w-full py-4 lg:py-[18px] pr-30 text-black h-full text-[20px] rounded-lg border-2 border-[#EBEDF2] focus:outline-none"
                            />
                        </div>
                        {/* Date of Birth */}
                        <div className="h-[80px]">
                            <TextField
                                label="Date of Birth"
                                name="dateOfBirth"
                                type="date"
                                variant="outlined"
                                fullWidth
                                value={formData.dateOfBirth}
                                onChange={handleChange}
                                sx={textFieldStyles}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        {/* Address */}
                        <div className="h-[80px]">
                            <TextField
                                label="Address"
                                name="address"
                                variant="outlined"
                                fullWidth
                                value={formData.address}
                                onChange={handleChange}
                                helperText={errors.address}
                                error={Boolean(errors.address)}
                                sx={textFieldStyles}
                            />
                        </div>
                        {/* Roll Number */}
                        <div className="h-[80px]">
                            <TextField
                                label="Roll Number"
                                name="rollNumber"
                                variant="outlined"
                                fullWidth
                                value={formData.rollNumber}
                                onChange={handleChange}
                                helperText={errors.rollNumber}
                                error={Boolean(errors.rollNumber)}
                                sx={textFieldStyles}
                            />
                        </div>
                        {/* Academic Year */}
                        <div className="h-[80px]">
                            <TextField
                                select
                                label="Academic Year"
                                name="academicYear"
                                variant="outlined"
                                fullWidth
                                value={formData.academicYear}
                                onChange={handleChange}
                                helperText={errors.academicYear}
                                error={Boolean(errors.academicYear)}
                                sx={textFieldStyles}
                            >
                                {isPending ? (
                                    <MenuItem disabled>Loading...</MenuItem>
                                ) : (
                                    yearList.map((y, index) => (
                                        <MenuItem key={index} value={y.year}>
                                            {y.year}
                                        </MenuItem>
                                    ))
                                )}
                            </TextField>
                        </div>
                        {/* Admission Date */}
                        <div className="h-[80px]">
                            <TextField
                                label="Admission Date"
                                name="admissionDate"
                                type="date"
                                variant="outlined"
                                fullWidth
                                value={formData.admissionDate}
                                onChange={handleChange}
                                sx={textFieldStyles}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>

                        {/* Gender Selection */}
                        <div>
                            <TextField
                                select
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                                fullWidth
                                sx={textFieldStyles}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </TextField>
                        </div>

                        {/* Avatar File Input */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: "56px",
                                width: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: "start",
                                    width: '100%',
                                    height: "56px",
                                }}
                            >
                                <TextField
                                    label="Avatar"
                                    name="avatar"
                                    variant="outlined"
                                    placeholder="Add Avatar"
                                    fullWidth
                                    value={formData.avatar ? formData.avatar.name : ""}
                                    InputProps={{ readOnly: true }}
                                    sx={{ flex: 1 }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{
                                        height: '100%',
                                        minWidth: 'fit-content',
                                        borderTopLeftRadius: '0',
                                        borderBottomLeftRadius: '0',
                                        backgroundColor: '#22577A',
                                        '&:hover': { backgroundColor: '#194F68' },
                                        marginLeft: '4px',
                                    }}
                                >
                                    Choose File
                                    <input
                                        type="file"
                                        hidden
                                        name="avatar"
                                        onChange={handleFileChange}
                                    />
                                </Button>
                            </Box>
                        </Box>
                    </div>
                    {/* Submit Button */}
                    <div className="flex w-full mt-5">
                        <Button
                            type="submit"
                            sx={{ width: "100%" }}
                            variant="contained"
                            color="primary"
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </div>
                </form>
            </main>
            {isModalOpen && (
                <OnboardClipboardModel
                    codeId={isModalOpen.codeId}
                    password={isModalOpen.password}
                    email={isModalOpen.email}
                    onClose={handleClose}
                />
            )}
        </div>
    );
}
const textFieldStyles = {
    marginBottom: "1rem", // Add some spacing between fields
    '& .MuiInputBase-root': {
        height: "100%", // Ensures the height matches the container
    },
};

export default OnboardStudent;
