import React, {useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Tooltip, Paper } from "@mui/material";

const AcademicCalendar = ({events,academicYear}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startYear, endYear] = academicYear?.split("-");

  const getEventsForDate = (date) => {
    return events.filter((event) => {
      const eventDate = new Date(event.date);
      return (
        eventDate.getDate() === date.getDate() &&
        eventDate.getMonth() === date.getMonth() &&
        eventDate.getFullYear() === date.getFullYear()
      );
    });
  };

  const renderTileContent = ({ date, view }) => {
    if (view === "month") {
      const dateEvents = getEventsForDate(date);
      return dateEvents.length > 0 ? (
        <div style={{ position: "relative", textAlign: "center" }}>
          {dateEvents.map((event, index) => (
            <Tooltip
              key={index}
              title={
                <div>
                  <strong style={{ fontSize: "16px" }}>{event.name}</strong>
                  <p style={{ margin: "0", fontSize: "14px" }}>
                    {event.description}
                  </p>
                </div>
              }
              placement="top"
              arrow
            >
              <Paper
                style={{
                  backgroundColor: "#4CAF50",
                  color: "white",
                  fontSize: "5px",
                  fontWeight: "bold",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  transition: "transform 0.2s, font-size 0.2s",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "scale(1.2)";
                  e.currentTarget.style.fontSize = "12px";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.fontSize = "10px";
                }}
              >
                {event.name}
              </Paper>
            </Tooltip>
          ))}
        </div>
      ) : null;
    }
    return null;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div>
      <h2>Academic Calendar</h2>
      <Calendar
        onChange={handleDateChange}
        value={selectedDate}
        tileContent={renderTileContent}
        minDate={new Date(startYear, 2, 1)}
        maxDate={new Date(endYear, 1, 31)}
      />
    </div>
  );
};

export default AcademicCalendar;
