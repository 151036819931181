import React, { useState, useEffect } from "react";
import { TextField, Button, Box } from "@mui/material";
import Headingtitle from '../../Common/Headingtitle';
import SchoolList from "./ManageSchool";
import { getAllSchoolList, OnboardNewSchool } from "../../../Services/superAdminApis";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import toast from "react-hot-toast";
const CreateSchool = () => {
    const [schoolData, setSchoolData] = useState([]);
    const [formData, setFormData] = useState({
        mobile: "",
        logo: null,
        email: "",
        password: "",
        alternateMobile: "",
        schoolName: "",
        address: "",
        desc: ""
    });


    const fetchAllSchoolList = async () => {
        try {
            const response = await getAllSchoolList();
            setSchoolData(response?.data?.adminSchoolList || []);

        } catch (error) {
            console.error("error", error);
        }
    }

    useEffect(() => {
        fetchAllSchoolList();
    }, [])
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: files ? files[0] : value, 
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append("mobile", formData.mobile);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("password", formData.password);
        formDataToSend.append("alternateMobile", formData.alternateMobile);
        formDataToSend.append("schoolName", formData.schoolName);
        formDataToSend.append("address", formData.address);
        formDataToSend.append("desc", formData.desc);

        if (formData.logo) {
            formDataToSend.append("logo", formData.logo);
        }

        try {
            const response = await OnboardNewSchool(formDataToSend);
            if (response?.status === 200 || 201) {
                console.log("Form submitted successfully", response);
                toast.success("successfully Created New School")
                setFormData({
                    mobile: "",
                    logo: null,
                    email: "",
                    password: "",
                    alternateMobile: "",
                    schoolName: "",
                    address: "",
                    desc: "",
                });
                fetchAllSchoolList();
            } else {
                console.log("error", response);
                toast.error(response?.data?.message);
            }
        }
        catch (error) {
            console.error("Failed to submit form", error.response?.data.message);
            toast.error(error.response?.data.message);

        }
    };
    return (
        <div className="font-poppins">
            <Headingtitle title="Manage" />
            <main className="bg-white min-h-[80vh] rounded-md p-5">
                <p className="text-[1rem] font-semibold pb-2">Class Section</p>
                <form onSubmit={handleSubmit}>
                    <div className="grid w-full grid-cols-12 gap-5  text-[0.77rem]">
                        {/* School Name */}
                        <TextField
                            label="School Name"
                            name="schoolName"
                            variant="outlined"
                            placeholder="Enter the School name"
                            fullWidth
                            className="col-span-12 sm:col-span-6"
                            value={formData.schoolName}
                            onChange={handleChange}
                            sx={{
                                height: "55px",
                                "& .MuiOutlinedInput-root": {
                                    height: "100%",
                                    borderRadius: "8px",
                                    "& fieldset": {
                                        borderColor: "#EBEDF2",
                                        borderWidth: "2px",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#B0B3B8",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#22577A",
                                        borderWidth: "2px",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#000000",
                                },
                            }}
                        />
                        <Box className="col-span-12 sm:col-span-6" sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', height: "55px" }}>
                                <TextField
                                    label="Logo"
                                    name="logo"
                                    variant="outlined"
                                    placeholder="Add School Logo"
                                    fullWidth
                                    value={formData.logo ? formData.logo.name : ""}
                                    InputProps={{ readOnly: true }}
                                    sx={{
                                        flex: 1,
                                        '& .MuiOutlinedInput-root': { border: 'none' },
                                        '& .MuiInputLabel-root': { fontSize: '12px', fontWeight: 'bold', color: '#000000' },
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{
                                        height: '100%',
                                        minWidth: 'fit-content',
                                        borderTopLeftRadius: '0',
                                        borderBottomLeftRadius: '0',
                                        backgroundColor: '#22577A',
                                        '&:hover': { backgroundColor: '#194F68' },
                                    }}
                                >
                                    Choose File
                                    <input
                                        type="file"
                                        hidden
                                        name="logo"
                                        onChange={handleChange}
                                    />
                                </Button>
                            </Box>
                        </Box>


                        {/* Mobile */}
                        <div className="col-span-12 sm:col-span-6">
                            <label
                                htmlFor="mobile"
                                className="block text-black font-bold text-[12px] mb-1"
                            >
                                Mobile
                            </label>
                            <div className=" h-[56px]">
                                <PhoneInput
                                    id="mobile" 
                                    country={'in'}
                                    value={formData.mobile}
                                    onChange={(value) => setFormData({ ...formData, mobile: value })}
                                    containerClass="w-full rounded-lg" 
                                    inputStyle={{
                                        width: '100%',
                                        borderRadius: "8px",
                                        height: "100%",
                                        border: "2px solid #EBEDF2"

                                    }}
                                    inputClass="w-full py-4 lg:py-[18px] pr-30 text-black h-full text-[20px] rounded-lg border-2 border-[#EBEDF2] focus:outline-none"
                                />
                            </div>
                        </div>

                        {/* alternateMobile */}
                        <div className="col-span-12 sm:col-span-6">
                            <label
                                htmlFor="mobile"
                                className="block text-black font-bold text-[12px] mb-1"
                            >
                                 Alternate  Mobile
                            </label>
                            <div className="  h-[56px] ">
                                <PhoneInput
                                    id="mobile" 
                                    country={'in'}
                                    value={formData.alternateMobile}
                                    onChange={(value) => setFormData({ ...formData, alternateMobile: value })}
                                    containerClass="w-full rounded-lg "
                                    inputStyle={{
                                        width: '100%',
                                        borderRadius: "8px", 
                                        height: "100%",
                                        border: "2px solid #EBEDF2"

                                    }}
                                    inputClass="w-full py-4 lg:py-[18px] pr-30 text-black h-full text-[20px] rounded-lg border-[#EBEDF2] rounded-r-[12px] border-2 focus:outline-none"
                                />
                            </div>
                        </div>


                        {/* Email */}
                        <TextField
                            label="Email"
                            name="email"
                            variant="outlined"
                            placeholder="Enter email"
                            fullWidth
                            className="col-span-12 sm:col-span-6"
                            value={formData.email}
                            onChange={handleChange}
                            sx={{
                                height: "55px",
                                "& .MuiOutlinedInput-root": {
                                    height: "100%",
                                    borderRadius: "8px",
                                    "& fieldset": {
                                        borderColor: "#EBEDF2",
                                        borderWidth: "2px",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#B0B3B8",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#22577A",
                                        borderWidth: "2px",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#000000",
                                },
                            }}
                        />

                        {/* Password */}
                        <TextField
                            label="Password"
                            name="password"
                            variant="outlined"
                            type="password"
                            placeholder="Enter password"
                            fullWidth
                            className="col-span-12 sm:col-span-6"
                            value={formData.password}
                            onChange={handleChange}
                            sx={{
                                height: "55px",
                                "& .MuiOutlinedInput-root": {
                                    height: "100%",
                                    borderRadius: "8px",
                                    "& fieldset": {
                                        borderColor: "#EBEDF2",
                                        borderWidth: "2px",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#B0B3B8",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#22577A",
                                        borderWidth: "2px",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#000000",
                                },
                            }}
                        />


                        {/* Address */}
                        <TextField
                            label="Address"
                            name="address"
                            variant="outlined"
                            placeholder="Enter school address"
                            fullWidth
                            className="col-span-12"
                            value={formData.address}
                            onChange={handleChange}
                            sx={{
                                height: "55px",
                                "& .MuiOutlinedInput-root": {
                                    height: "100%",
                                    borderRadius: "8px",
                                    "& fieldset": {
                                        borderColor: "#EBEDF2",
                                        borderWidth: "2px",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#B0B3B8",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#22577A",
                                        borderWidth: "2px",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#000000",
                                },
                            }}
                        />
                        <TextField
                            label="Description"
                            name="desc"
                            variant="outlined"
                            placeholder="Enter Description"
                            fullWidth
                            className="col-span-12"
                            value={formData.desc}
                            onChange={handleChange}
                            sx={{
                                height: "55px",
                                "& .MuiOutlinedInput-root": {
                                    height: "100%",
                                    borderRadius: "8px",
                                    "& fieldset": {
                                        borderColor: "#EBEDF2",
                                        borderWidth: "2px",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#B0B3B8",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#22577A",
                                        borderWidth: "2px",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#000000",
                                },
                            }}
                        />

                    </div>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: "#22577A",
                            color: "white",
                            marginTop: "1rem",
                            "&:hover": {
                                backgroundColor: "#1A435A",
                            },
                        }}
                    >
                        Submit
                    </Button>
                </form>
                <SchoolList schoolData={schoolData} fetchAllSchoolList={fetchAllSchoolList} />
            </main>
        </div>
    );
};

export default CreateSchool;
