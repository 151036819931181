import React, { useEffect, useState } from "react";
import { getAllSubjectList } from "../../../Services/StudentApis";
import { useTransition } from "react";
import Headingtitle from "../../Common/Headingtitle";

const AllSubjects = () => {
    const [subjects, setSubjects] = useState([]); // State to store the subjects
    const [isPending, startTransition] = useTransition(); // useTransition hook

    useEffect(() => {
        // Fetch the subjects list on component mount
        startTransition(() => {
            getAllSubjectList()
                .then((response) => {
                    setSubjects(response?.data?.subjects || []); // Set the subjects list
                })
                .catch((error) => {
                    console.error("Error fetching subjects:", error);
                });
        });
    }, []);

    return (
        <div className="">
            <Headingtitle title="Subject List" />
            <section className="min-w-full bg-white rounded-lg min-h-[80vh] font-poppins">
               
                {isPending ? (
                    <p>Loading...</p> // Show a loading state while data is being fetched
                ) : (
                    <table className="min-w-full bg-white rounded-lg border-collapse">
                        <thead className="rounded-lg">
                            <tr className="bg-bg_blue-25 text-[1.1rem] text-left text-text_blue-500 rounded-t-lg">
                                <th className="border border-gray-200 p-4  font-semibold">
                                    Index
                                </th>
                                <th className="border border-gray-200 p-4  font-semibold">
                                    Subject Name
                                </th>

                            </tr>
                        </thead>
                        <tbody className="border-b-[1px] border-border-50 text-text_Black-75 font-poppins">
                            {subjects.map((subject, index) => (
                                <tr
                                    key={subject._id}
                                    className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                        } hover:bg-gray-100`}
                                >
                                    <td className="border border-gray-200 p-4 ">
                                        {index + 1}
                                    </td>
                                    <td className="border border-gray-200 p-4 ">
                                        {subject.subjectName || "N/A"}
                                    </td>


                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </section>
        </div>
    );
};

export default AllSubjects;
