import React, { useEffect, useState } from 'react';
import Headingtitle from '../../Common/Headingtitle';
import { getAllClassIdList, getAllSubjectNameList, createNewTimetable, GetClassSectionList, OnBaoardNewClass, GetOnboardedTeacherList } from '../../../Services/AdminApis';
import toast from 'react-hot-toast';

const CreateTimeTable = () => {
    const [classId, setClassId] = useState('');
    const [classCategoryData, setClassCategoryData] = useState([]);
    const [classSections, setClassSections] = useState([]);
    const [teacherList, setTeacherList] = useState([]);

    const [subject, setSubject] = useState('');
    const [teacherId, setTeacherId] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [days, setDays] = useState([]); // State to store selected days

    const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const handleDayChange = (day) => {
        setDays((prevDays) =>
            prevDays.includes(day)
                ? prevDays.filter((d) => d !== day) // Remove day if already selected
                : [...prevDays, day] // Add day if not selected
        );
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare the API payload
        const payload = {
            classId,
            subject,
            teacherId,
            timeSlot: {
                start: startTime,
                end: endTime,
            },
            day: days,
        };

        try {
            // Replace `CreateTimeTable` with your actual API call
            const response = await createNewTimetable(payload);


            if (response?.status === 200 || 201) {
                toast.success("Successfully created Class Time Table");
                resetForm();
            } else {
                toast.error("Failed to create Class Time Table");
            }
        } catch (error) {
            console.error("Error submitting timetable:", error);
            toast.error("An error occurred while creating the timetable");
        }

    };

    const resetForm = () => {
        setClassId('');
        setSubject('');
        setTeacherId('');
        setStartTime('');
        setEndTime('');
        setDays([]);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const categoryResponse = await getAllClassIdList();
                const sectionResponse = await getAllSubjectNameList();
                const teacherList = await GetOnboardedTeacherList();

                setClassCategoryData(categoryResponse?.data?.classes || []);
                setClassSections(sectionResponse?.data?.subjects || []);
                setTeacherList(teacherList?.data?.teachers || [])
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [])

    const isFormValid = classId && subject && teacherId && startTime && endTime && days.length > 0;


    return (
        <div className="font-poppins">
            <Headingtitle title="Time Table" />
            <main className="bg-white min-h-[80vh] rounded-md p-5">
                <p className="text-[1rem] font-semibold pb-2">Create a new time table</p>
                <form onSubmit={handleSubmit}>
                    <div className="grid w-full grid-cols-2 gap-5 text-[0.77rem]">
                        <div>

                            <select
                                id="ClassId"
                                value={classId}
                                onChange={(e) => setClassId(e.target.value)}
                                className="w-full h-[55px] border-[2px] border-[#EBEDF2] rounded-lg shadow-sm focus:ring-[#22577A] focus:border-[#22577A] hover:border-[#B0B3B8] text-[12px] font-bold text-black px-3 outline-none"
                            >
                                <option value="">Select ClassId</option>
                                {classCategoryData.length !== 0 && (
                                    classCategoryData.map((data) => (
                                        <option key={data._id}
                                            value={data._id}>{data.className} {"-"}     {data.classSection}</option>

                                    ))
                                )

                                }

                            </select>
                        </div>

                        {/* Subject Selection */}
                        <div>

                            <select
                                id="Subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                className="w-full h-[55px] border-[2px] border-[#EBEDF2] rounded-lg shadow-sm focus:ring-[#22577A] focus:border-[#22577A] hover:border-[#B0B3B8] text-[12px] font-bold text-black px-3 outline-none"
                            >
                                <option value="">Select Subject</option>
                                {classSections.length !== 0 && (
                                    classSections.map((data) => (
                                        <option key={data._id}
                                            value={data.subjectName}>{data.subjectName}</option>

                                    ))
                                )
                                }
                            </select>
                        </div>

                        <div className="col-span-2">

                            <select
                                id="TeacherId"
                                value={teacherId}
                                onChange={(e) => setTeacherId(e.target.value)}
                                className="w-full h-[55px] border-[2px] border-[#EBEDF2] rounded-lg shadow-sm focus:ring-[#22577A] focus:border-[#22577A] hover:border-[#B0B3B8] text-[12px] font-bold text-black px-3 outline-none"
                            >
                                <option value="">Select Teacher</option>
                                {teacherList.length !== 0 && (
                                    teacherList.map((data) => (
                                        <option key={data._id} value={data._id}>{data?.name?.firstName}</option>
                                    ))
                                )
                                }
                            </select>
                        </div>
                        <div>
                            <label
                                htmlFor="startTime"
                                className="block text-sm font-semibold text-gray-700 mb-1"
                            >
                                Start Time
                            </label>
                            <input
                                id="startTime"
                                type="time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                placeholder="Start Time"
                                className="h-[55px] w-full border-[2px] border-[#EBEDF2] rounded-lg shadow-sm focus:ring-[#22577A] focus:border-[#22577A] hover:border-[#B0B3B8] text-[12px] font-bold text-black px-3 outline-none"
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="endTime"
                                className="block text-sm font-semibold text-gray-700 mb-1"
                            >
                                End Time
                            </label>
                            <input
                                id="endTime"
                                type="time"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                placeholder="End Time"
                                className="h-[55px] w-full border-[2px] border-[#EBEDF2] rounded-lg shadow-sm focus:ring-[#22577A] focus:border-[#22577A] hover:border-[#B0B3B8] text-[12px] font-bold text-black px-3 outline-none"
                            />
                        </div>
                    </div>

                    <div className="p-5">
                        <h2 className="text-lg font-bold mb-3">Select Days</h2>
                        <div className="grid grid-cols-3 gap-3">
                            {allDays.map((day) => (
                                <label key={day} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        value={day}
                                        checked={days.includes(day)}
                                        onChange={() => handleDayChange(day)}
                                        className="w-4 h-4"
                                    />
                                    <span className="text-sm">{day}</span>
                                </label>
                            ))}
                        </div>
                        <div className="mt-5">
                            <h3 className="font-semibold">Selected Days:</h3>
                            <p>{days.length > 0 ? days.join(", ") : "No days selected"}</p>
                        </div>
                    </div>

                    <div className="mt-5 w-[100%]">
                        <button
                            type="submit"
                            className={`bg-[#22577A] w-full text-white font-bold py-2 px-5 rounded-md hover:bg-[#1b4e69] ${isFormValid ? '' : 'opacity-50 cursor-not-allowed'
                                }`}
                            disabled={!isFormValid}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </main>
        </div>
    );
};

export default CreateTimeTable;
