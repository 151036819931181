import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoginLogo from "../Assests/Logos/world.png";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utils/baseUrl";
import { login } from '../Redux/slices/authSlice';
import { getAllClassList } from "../Services/TeachersApis";
import loginArt from "../Assests/AcademicPic/LoginArt.png"
import { getAcademicsYears } from "../Services/TeachersApis";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { FaUser, FaLock, FaEnvelope, FaKey } from "react-icons/fa";




const Login = () => {
    const dispatch = useDispatch();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [userRole, setUserRole] = useState("Student");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { loading, isAuthenticated, user } = useSelector((state) => state.auth);

    React.useEffect(() => {
        if (isAuthenticated && user) {
            if (user.role === 'Student') navigate('/student/dashboard');
            else if (user.role === 'Teacher') navigate('/teacher/dashboard');
            else if (user.role === 'Parent') navigate('/parent/dashboard');
        }
    }, [isAuthenticated, user, navigate]);

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .email("Invalid email format")
            .required("Email is required"),
        password: yup
            .string()
            .min(6, "Password must be at least 6 characters")
            .required("Password is required"),
        studentId: yup
            .string()
            .when("userRole", {
                is: "Student",
                then: (schema) => schema.required("Student ID is required"),
                otherwise: (schema) => schema.notRequired()
            }),
        teacherId: yup
            .string()
            .when("userRole", {
                is: "Teacher",
                then: (schema) => schema.required("Teacher ID is required"),
                otherwise: (schema) => schema.notRequired()
            }),
        parentId: yup
            .string()
            .when("userRole", {
                is: "Parent",
                then: (schema) => schema.required("Parent ID is required"),
                otherwise: (schema) => schema.notRequired()
            }),
    });


    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const fetchAcademicYear = async () => {
        try {
            const response = await getAcademicsYears();
            localStorage.setItem("academicYear", JSON.stringify(response?.data) || "");
        } catch (error) {
            throw error
        }
    }
    useEffect(() => {
        const savedRole = localStorage.getItem("userRole");
        if (savedRole) {
            setUserRole(savedRole);
        }
    }, []);

    const fetchClassId = async (userRole) => {
        try {
            if (userRole === "Teacher") {
                const response = await getAllClassList();
                if (response?.data?.classesDetails) {
                    localStorage.setItem(
                        "classId",
                        JSON.stringify(response?.data?.classesDetails._id)
                    );
                    localStorage.setItem("schoolId", JSON.stringify(response?.data?.classesDetails?.school));
                    console.log("Class details saved to localStorage.");
                } else {
                    console.warn("No class details found in the response.");
                }
            }
        } catch (error) {
            console.error("Error fetching class list:", error);
        }
    };

    const onSubmit = async (data) => {

        try {
           const response= await dispatch(login({ userRole, data })); 
           console.log("login data",response.status)
           if (response?.payload?.status === 200) {
            console.log("Login successful:", response.payload);
      
            const classId = await fetchClassId(userRole); 
            const academicYear = await fetchAcademicYear();
      
            console.log("Fetched classId:", classId);
            console.log("Fetched academicYear:", academicYear);
          } else {
            console.error("Login failed:", response?.error?.message || "Unknown error");
          }
            // await fetchClassId(userRole);
            // await fetchAcademicYear();
        } catch (error) {
            console.error("Error during login or fetching class ID:", error);
        }
    };




    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevState) => !prevState);
    };

    const handleRoleSelection = (role) => {
        setUserRole(role);
        localStorage.setItem("userRole", role);
    };
    const handleForgotPasswordNavigate = () => {
        navigate("/user/forgot-password")
    }

    return (
        <>
            <section className="min-h-screen font-poppins flex items-center justify-center md:bg-gray-50 ">
                <div
                    style={{ height: "auto" }}
                    className="bg-white md:w-[50vw] w-[90vw] max-w-md md:border md:border-gray-200 md:shadow-lg rounded-lg p-6 flex flex-col justify-evenly"
                >
                    <div className="w-[100%]">
                        <img src={LoginLogo} alt="Logo" className="h-12 mx-auto mb-4" />

                        <h2
                            className="text-2xl font-bold text-center mb-2"
                            style={{
                                fontFamily: "Poppins, Arial, sans-serif",
                                color: "#000",
                            }}
                        >
                            Welcome Back
                        </h2>
                        <p
                            className="text-center "
                            style={{
                                fontFamily: "Poppins, Arial, sans-serif",
                                color: "#4B5563",
                                fontSize: "14px",
                            }}
                        >
                            Today is a new day. It's your day. You shape it. Sign in to start managing your projects.
                        </p>
                    </div>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col w-full md:max-w-[100%] mx-auto p-2 mt-5"
                    >
                        {/* Email Field */}
                        <div className=" h-[80px] relative">
                            <label
                                className="block text-sm font-medium mb-1"
                                style={{
                                    fontFamily: "Poppins, Arial, sans-serif",
                                    color: "#000",
                                }}
                                htmlFor="email"
                            >
                                Email
                            </label>
                            <div className="relative">
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="Email Or Mobile No."
                                    className={`w-full text-sm p-2 pl-10 placeholder:text-sm placeholder:font-semibold border ${errors.email ? "border-red-500" : "border-border-50"
                                        } rounded-md focus:outline-none`}
                                    {...register("email")}
                                />
                                <FaEnvelope className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                            </div>
                            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
                        </div>

                        {/* Password and Code Fields Side by Side */}
                        {/* <div className="flex justify-between gap-4 mb-4"> */}
                        {/* Password Field */}
                        <div className="h-[80px] relative ">
                            <label
                                className="block text-sm font-medium mb-1"
                                style={{
                                    fontFamily: "Poppins, Arial, sans-serif",
                                    color: "#000",
                                }}
                                htmlFor="password"
                            >
                                Password
                            </label>
                            <div className="relative">
                                <input
                                    id="password"
                                    type={isPasswordVisible ? "text" : "password"}
                                    placeholder="Password"
                                    autoComplete="current-password"
                                    className={` text-sm  w-full p-2 pl-10 placeholder:text-sm placeholder:font-semibold border ${errors.password ? "border-red-500" : "border-border-50"
                                        } rounded-md  focus:outline-none`}
                                    {...register("password")}
                                />
                                <FaLock className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                                <span
                                    onClick={togglePasswordVisibility}
                                    className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                                >
                                    {isPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                                </span>
                            </div>
                            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>}
                        </div>

                        {userRole === "Student" &&
                            <div className="mb-4">
                                <label
                                    className="block text-sm font-medium mb-1"
                                    style={{
                                        fontFamily: "Poppins, Arial, sans-serif",
                                        color: "#000",
                                    }} htmlFor="studentId">
                                    Student ID
                                </label>
                                <input
                                    id="studentId"
                                    type="text"
                                    placeholder="Student ID"
                                    className={`w-full p-2 placeholder:text-[0.8rem]  focus:outline-none    placeholder:font-semibold rounded-3xl border ${errors.studentId ? "border-red-500" : "border-border-50"} rounded-md focus:outline-none`}
                                    {...register("studentId")}
                                />
                                {errors.studentId && <p className="text-red-500 text-sm mt-1">{errors.studentId.message}</p>}
                            </div>
                        }


                        {
                            userRole === "Teacher" &&
                            <div className="mb-4">
                                <label
                                    className="block text-sm font-medium mb-1"
                                    style={{
                                        fontFamily: "Poppins, Arial, sans-serif",
                                        color: "#000",
                                    }}
                                    htmlFor="teacherId">
                                    Teacher ID
                                </label>
                                <input
                                    id="teacherId"
                                    type="text"
                                    placeholder="Teacher ID"
                                    style={{ borderRadius: "" }}
                                    className={`w-full p-2 placeholder:text-[0.8rem] rounded-full focus:outline-none placeholder:font-semibold border ${errors.teacherId ? "border-red-500" : "border-border-50"} rounded-md focus:outline-none`}
                                    {...register("teacherId")}
                                />
                                {errors.teacherId && <p className="text-red-500 text-sm mt-1">{errors.teacherId.message}</p>}
                            </div>
                        }

                        {
                            userRole === "Parent" &&
                            <div className="mb-4">
                                <label
                                    className="block text-sm font-medium mb-1"
                                    style={{
                                        fontFamily: "Poppins, Arial, sans-serif",
                                        color: "#000",
                                    }}
                                    htmlFor="parentId">
                                    Parent ID
                                </label>
                                <input
                                    id="parentId"
                                    type="text"
                                    placeholder="Parent ID"
                                    className={`w-full p-2   placeholder:text-[0.8rem] rounded-full focus:outline-none placeholder:font-semibold border ${errors.parentId ? "border-red-500" : "border-border-50"} rounded-md focus:outline-none`}
                                    {...register("parentId")}
                                />
                                {errors.parentId && <p className="text-red-500 text-sm mt-1">{errors.parentId.message}</p>}
                            </div>
                        }   {/* </div> */}

                        <div className="w-full text-end">
                            <p
                                className="text-text_blue-50 text-sm italic font-semibold cursor-pointer"
                                onClick={handleForgotPasswordNavigate}
                            >
                                Forgot password
                            </p>
                        </div>

                        <button
                            type="submit"
                            className="bg-[#22577A] font-semibold text-[0.9rem] text-[#FFFFFF] p-2 rounded-full mt-2 hover:bg-[#22577A] transition"
                            disabled={isLoading}
                        >
                            {isLoading ? "Logging in..." : `Login as ${userRole}`}
                        </button>

                        {error && <p className="text-red-500 mt-2">{error}</p>}

                        <hr className="h-[3px] bg-border-50 border-0 my-3" />
                        <p className="text-center text-[#00000080] font-semibold my-3">Login as</p>
                        <div className="grid grid-cols-2 gap-5 mt-5">
                            {["Teacher", "Parent", "Student"].map(
                                (role) =>
                                    userRole !== role && (
                                        <div
                                            key={role}
                                            className={`bg-[${role === "Teacher"
                                                ? "#1BCFB4"
                                                : role === "Parent"
                                                    ? "#198AE2"
                                                    : "#8089C7"
                                                }] bg-[#1BCFB4] text-sm py-2 rounded-full`}
                                        >
                                            <button
                                                type="button"
                                                onClick={() => handleRoleSelection(role)}
                                                className="text-center text-[#FFFFFF] text-sm  font-semibold rounded-full w-full"
                                            >
                                                {role}
                                            </button>
                                        </div>
                                    )
                            )}
                        </div>
                    </form>
                </div>

            </section>
        </>
    );
};

export default Login;
